import { useEffect, useState } from "react";
import TopMenu from "../Components/TopMenu";
import "../assets/scss/StaticPagesWrapper.scss";
import Footer from "../Components/Footer";
import Button from "../Components/Button";
import MyActionButton from "../Components/MyActionButton";
import { Link } from "react-router-dom";
import AboutUsImage from "../assets/images/privacy_lmage.jpeg";
import Loader from "../Components/Loader";
import { SlUser } from "react-icons/sl";
import EditAction from "../Components/EditAction";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import FormRowInput from "../Components/FormRowInput";
import usePostMutation from "../Utils/usePostMutation";
import toast, { Toaster } from "react-hot-toast";
import { useFormik, Formik } from "formik";
const Profile = () => {
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isEditOpen, setIsEditOpen] = useState(false);
  const len = localStorage.getItem("language");
  const [language, setLanguage] = useState(len);
  const [mainHeading, setMainHeading] = useState({
    en: {
      userName: "Username:",
      fullName: "Fullname:",
      institution: "Institution:",
      dreamSpeciality: "Dream Speciality:",
      currentPlan: "Current Plan:",
      editProfile: "Edit Profile",
      profileImage: "Profile Image:",
      update: "Update"
    },
    thai: {
      userName: "ชื่อผู้ใช้:",
      fullName: "ชื่อเต็ม:",
      institution: "สถาบัน:",
      dreamSpeciality: "สาขาเฉพาะทางที่สนใจ:",
      currentPlan: "แผนที่สมัครปัจจุบัน:",
      editProfile: "แก้ไขโปรไฟล์",
      profileImage: "รูปโปรไฟล์:",
      update: "อัปเดตโปรไฟล์"
    }
  });
  const getUserWithId = async () => {
    const { data } = await apiClient.get(`/users/${userStored?._id}`);
    return data;
  };
  let userValues = {};
  const { mutate } = usePostMutation();
  const languageHandler = (val) => {
    console.log("Language is ", val);
    setLanguage(val);
  };
  const getUser = useQuery(["user"], getUserWithId);
  const sendRestLinkToMyEmail = () => {
    let values = {
      email: userStored.email
    };
    try {
      mutate(
        {
          url: "users/forgotPassword",
          values: values
        },
        {
          onSuccess: (res) => {
            console.log("reset password response", res);

            toast.success(res.data.message);
          },
          onError: (error) => {
            // alert("An error occured during submitting system");
            console.log("our response", error.response);
            toast.error("Invalid email or Password");

            //toast.error(response.response.data.message);
          }
        }
      );
    } catch (error) {
      console.error(error.response);
    }
  };
  const getAllStripePlans = async () => {
    const { data } = await apiClient.get("/exams/");
    return data;
  };
  const getAllUserSubscriptions = async () => {
    const { data } = await apiClient.get("/stripe/user-subscription");
    return data;
  };
  let userAlreadySubscribedPlans = [];

  const allUserSubscriptions = useQuery(
    ["userSubscriptionsforGoToExam"],
    getAllUserSubscriptions,
    {
      retry: 1,
      onSuccess: (res) => {
        console.log("response of exam on success in home page", res);
      },
      onError: (res) => {
        console.log("response of exam on error", res);
      }
    }
  );
  if (allUserSubscriptions.isSuccess) {
    allUserSubscriptions.data.data?.subscriptions.map((sub) => {
      return userAlreadySubscribedPlans.push(sub.plan.id);
    });
  }
  const allExams = useQuery(["examsinoursystem"], getAllStripePlans, {
    retry: 2,
    onSuccess: (res) => {
      //console.log("response of exam on success in home page", res);
    },
    onError: (res) => {
      //console.log("response of exam on error", res);
    }
  });
  useEffect(() => {
    allExams.refetch();
    getUser.refetch();
    allUserSubscriptions.refetch();
  }, []);
  if (
    getUser.isLoading &&
    allExams.isLoading &&
    allUserSubscriptions.isLoading
  ) {
    return <Loader />;
  }
  if (getUser.isError) {
    return <h1>Not found any data</h1>;
  }

  if (
    getUser.isSuccess &&
    allUserSubscriptions.isSuccess &&
    allExams.isSuccess
  ) {
    let ourData = new Set();
    allExams.data.data?.exams.map((exam, index) => {
      exam.plan_ids?.map((plan, i) => {
        console.log("Plan", userAlreadySubscribedPlans.includes(plan.id));
        let isExisted = userAlreadySubscribedPlans.includes(plan.id);
        if (isExisted) {
          ourData.add(exam);
        }
      });
    });
    console.log("Our data", ourData);
    ourData = [...ourData];
    console.log("Get User", getUser.data.data.user);
    userValues = { ...getUser.data.data.user };
    console.log("user values", userValues);
    return (
      <div>
        <TopMenu languageHandler={languageHandler} />
        <div className="generic-list">
          <div className="list-item-card">
            <div className="profileWrapper">
              <div className="leftWrapper">
                {getUser.data.data?.profile_image &&
                getUser.data.data?.google_image ? (
                  <img
                    // src={`http://localhost:4000/userImages/${getUser.data.data.user.profile_image}`}
                    src={`https://www.medgrizz.com/userImages/${getUser.data.data.user.profile_image}`}
                    alt="user"
                  />
                ) : getUser.data.data.user?.profile_image ? (
                  <img
                    // src={`http://localhost:4000/userImages/${getUser.data.data.user.profile_image}`}
                    src={`https://www.medgrizz.com/userImages/${getUser.data.data.user.profile_image}`}
                    alt="user"
                  />
                ) : getUser.data.data.user?.google_image ? (
                  <img
                    src={`${getUser.data.data.user.google_image}`}
                    //src={`${getUser.data.data.user.google_image}`}
                    alt="This is user logo"
                  />
                ) : (
                  <SlUser size="1.5em" />
                )}
                {!isEditOpen ? (
                  <Button
                    text={
                      language === "thai"
                        ? mainHeading.thai.editProfile
                        : mainHeading.en.editProfile
                    }
                    handleSubmit={() => {
                      setIsEditOpen(!isEditOpen);
                    }}
                  />
                ) : (
                  <MyActionButton
                    text="Change Password"
                    icon={<EditAction />}
                    handleSubmit={sendRestLinkToMyEmail}
                  />
                )}

                <Toaster />
              </div>
              {isEditOpen ? (
                <div className="form-wrapper">
                  <Formik
                    enableReinitialize
                    initialValues={userValues}
                    onSubmit={(values) => {
                      console.log(values);
                      const {
                        user_name,
                        full_name,
                        email,
                        password,
                        profile_image,
                        institution,
                        dream_speciality
                      } = values;

                      const formData = new FormData();
                      formData.append("user_name", user_name);
                      formData.append("full_name", full_name);
                      formData.append("email", email);
                      formData.append("institution", institution);
                      formData.append("dream_speciality", dream_speciality);
                      formData.append("profile_image", profile_image);

                      mutate(
                        {
                          method: "patch",
                          url: `/users/${getUser.data.data.user._id}`,
                          values: formData,
                          head: {
                            headers: {
                              "Content-Type": "multipart/form-data"
                            }
                          }
                        },
                        {
                          onSuccess: (res) => {
                            console.log("profile updated", res);
                            toast.success("Profile updated successfully");
                            const user = res.data.data.newUser;
                            console.log("user", user);
                            localStorage.setItem("user", JSON.stringify(user));
                            //getUser.refetch();

                            setIsEditOpen(false);
                            window.location.reload(false);
                          },
                          onError: (err) => {
                            alert(err.response.data.message);
                            toast.error("Profile updated successfully");
                            console.log(err);
                          }
                        }
                      );
                    }}
                  >
                    {(formik) => (
                      <form
                        encType="multipart/form-data"
                        onSubmit={formik.handleSubmit}
                      >
                        <Toaster />
                        <FormRowInput
                          label={
                            language === "thai"
                              ? mainHeading.thai.userName
                              : mainHeading.en.userName
                          }
                          placeholder="Please enter user name"
                          id="user_name"
                          name="user_name"
                          onChange={formik.handleChange}
                          value={formik.values.user_name}
                        />
                        <FormRowInput
                          label={
                            language === "thai"
                              ? mainHeading.thai.fullName
                              : mainHeading.en.fullName
                          }
                          placeholder="Please enter full name"
                          id="full_name"
                          name="full_name"
                          onChange={formik.handleChange}
                          value={formik.values.full_name}
                        />
                        <FormRowInput
                          label={
                            language === "thai"
                              ? mainHeading.thai.institution
                              : mainHeading.en.institution
                          }
                          placeholder="Please enter institution"
                          id="institution"
                          name="institution"
                          onChange={formik.handleChange}
                          value={formik.values.institution}
                        />
                        <FormRowInput
                          label={
                            language === "thai"
                              ? mainHeading.thai.dreamSpeciality
                              : mainHeading.en.dreamSpeciality
                          }
                          placeholder="Please enter dream speciality"
                          id="dream_speciality"
                          name="dream_speciality"
                          onChange={formik.handleChange}
                          value={formik.values.dream_speciality}
                        />

                        <div className="image-previewer">
                          <FormRowInput
                            type="file"
                            label={
                              language === "thai"
                                ? mainHeading.thai.profileImage
                                : mainHeading.en.profileImage
                            }
                            id="profile_image"
                            name="profile_image"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "profile_image",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        </div>
                        <div>
                          <Button
                            type="submit"
                            text={
                              language === "thai"
                                ? mainHeading.thai.update
                                : mainHeading.en.update
                            }
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              ) : (
                <div className="rightWrapper">
                  <div className="profileRow">
                    <h3>
                      {language === "thai"
                        ? mainHeading.thai.userName
                        : mainHeading.en.userName}
                    </h3>
                    <h3 className="heading-text-main">
                      {getUser.data.data.user.user_name}{" "}
                    </h3>
                  </div>
                  <div className="profileRow">
                    <h3>
                      {language === "thai"
                        ? mainHeading.thai.fullName
                        : mainHeading.en.fullName}
                    </h3>
                    <h3 className="heading-text-main">
                      {getUser.data.data.user.full_name}
                    </h3>
                  </div>
                  <div className="profileRow">
                    <h3>
                      {language === "thai"
                        ? mainHeading.thai.institution
                        : mainHeading.en.institution}
                    </h3>
                    <h3 className="heading-text-main">
                      {getUser.data.data.user?.institution}{" "}
                    </h3>
                  </div>
                  <div className="profileRow">
                    <h3>
                      {language === "thai"
                        ? mainHeading.thai.dreamSpeciality
                        : mainHeading.en.dreamSpeciality}
                    </h3>
                    <h3 className="heading-text-main">
                      {getUser.data.data.user?.dream_speciality}{" "}
                    </h3>
                  </div>
                  <div className="profileRow">
                    <h3>
                      {language === "thai"
                        ? mainHeading.thai.currentPlan
                        : mainHeading.en.currentPlan}
                    </h3>
                    <div className="plans">
                      {ourData.map((exam, index) => {
                        return <h3 key={index}> {exam.name}</h3>;
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
};

export default Profile;
