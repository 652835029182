import React from "react";
import "../assets/scss/RadioBtnTabs.scss";
const RadioBtnTabs = ({ handleChange, handleCheck, list }) => {
  return (
    <div className="container">
      <div className="tabs">
        {list.map((btn, index) => {
          console.log("Handle Check value", handleCheck);
          console.log("btn value ", btn.value);
          return (
            <React.Fragment key={index}>
              <input
                type="radio"
                id={`radio-` + index + `1`}
                name="tabs"
                value={btn.value}
                checked={btn.value == handleCheck}
                onChange={(e) => handleChange(e.target.value)}
              />
              <label className="tab" htmlFor={`radio-` + index + `1`}>
                {btn.label}
              </label>

              {handleCheck == btn.value && <span className="glider"></span>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default RadioBtnTabs;
