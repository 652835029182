import AdminHeader from "../Components/Admin/AdminHeader";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import FormRowSelect from "../Components/FormRowSelect";
import { Formik } from "formik";
import Button from "../Components/Button";
import FormRowTextarea from "../Components/FormRowTextarea";
import FormRowInput from "../Components/FormRowInput";
import PreviewImage from "../Components/PreviewImage";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import usePostMutation from "../Utils/usePostMutation";
import toast, { Toaster } from "react-hot-toast";
const HelpAndSupport = () => {
  const navigate = useNavigate();
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const { mutate } = usePostMutation();
  const reportList = [
    "Account Issues",
    "Subscription and Payment",
    "Technical Problems",
    "Feedback and Suggestions",
    "Privacy Concerns",
    "Study Resources",
    "General/Other Inquiries"
  ];
  return (
    <>
      <div className="dashboard-wrapper">
        <Sidebar examName={exam.name} />
        <div className="admin-nested">
          {/* <AdminHeader /> */}
          <div className="help-and-support">
            <h1>Help and Support</h1>
            <Formik
              initialValues={{
                help_title: "",
                help_issue: "",
                user_id: userStored._id
              }}
              onSubmit={(values, props) => {
                console.log("values", values);
                const formData = new FormData();
                const { help_title, help_issue, user_id, attach_file } = values;
                formData.append("title", help_title);
                formData.append("issue", help_issue);
                formData.append("user_id", user_id);
                formData.append("report_image", attach_file);

                mutate(
                  {
                    url: "/help",
                    values: formData
                  },
                  {
                    onSuccess: (res) => {
                      console.log("our response of help", res);
                      //alert("Form submitted successfully");
                      toast.success("Issue added successfully");
                      props.setSubmitting(false);
                    },
                    onError: (response) => {
                      // alert("An error occured while submitting exam");
                      toast.error("An error occured while submitting issue");
                      props.setSubmitting(false);
                    }
                  }
                );
              }}
            >
              {(formik) => (
                <>
                  <Toaster />
                  <div className="selectReportTopic">
                    <FormRowSelect
                      name="help_title"
                      id="help_title"
                      value={formik.values.help_title}
                      onChange={formik.handleChange}
                      list={reportList}
                      label="Report Topic"
                      classesName="width-fit-content"
                      wrapperWidthToFitContent="selectWrapper-width-fit-content"
                    />
                  </div>

                  <FormRowTextarea
                    totalRows={20}
                    label="Issue"
                    name="help_issue"
                    id="help_issue"
                    value={formik.values.help_issue}
                    onChange={formik.handleChange}
                  />
                  <div className="help-image-previewer">
                    <FormRowInput
                      type="file"
                      label="Attach File"
                      id="attach_file"
                      name="attach_file"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "attach_file",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    {formik.values.profile_image && (
                      <PreviewImage file={formik.values.profile_image} />
                    )}
                  </div>
                  <div className="help-btn">
                    <Button
                      text={formik.isSubmitting ? "Loading ..." : "Send"}
                      type="submit"
                      isDisable={formik.isSubmitting}
                      handleSubmit={formik.handleSubmit}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
