import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import AdminHeader from "../Components/Admin/AdminHeader";

import "../assets/scss/Wrapper.scss";
import FormRowInput from "../Components/FormRowInput";
import usePostMutation from "../Utils/usePostMutation";
import { useFormik, Formik, FieldArray, Form, Field } from "formik";
import Button from "../Components/Button";
import { SlBookOpen } from "react-icons/sl";
import { HiOutlineLightBulb } from "react-icons/hi";
import { BsFlag } from "react-icons/bs";
import RowInputRadio from "../Components/RowInputRadio";
import { useEffect, useState } from "react";
import FormRowSelect from "../Components/FormRowSelect";
import FormRowTextarea from "../Components/FormRowTextarea";
import { BsBoxArrowInRight, BsSkipEndBtn } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../Components/Loader";
import CountDownTimer from "../Components/CountDownTimer";
import DeletionModal from "../Components/DeletionModal";
import { useNavigate } from "react-router-dom";
import { FaFlag } from "react-icons/fa";
import { FiFlag } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import NormalValue1 from "../assets/images/Normal_value_1.jpeg";
import NormalValue2 from "../assets/images/Normal_value_2.jpeg";
import NormalValue3 from "../assets/images/Normal_Value_3.jpeg";
import NormalValue4 from "../assets/images/Normal_Value_4.jpeg";
import IconFlagVariantOutline from "../Components/IconFlagVariantOutline";
import IconFlagVariant from "../Components/IconFlagVariant";
import { CiLight } from "react-icons/ci";
const StartMockTest = () => {
  const { mutate } = usePostMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const values = location.state;
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const getAllMockTestQuestions = async () => {
    const { data } = await apiClient.get("questions/mock_test_q", {
      params: {
        ...values
      }
    });
    return data;
  };
  const [reviewStateAfterSubmittingExam, setReviewStateAfterSubmittingExam] =
    useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizQuestions, setQuizQuestions] = useState();
  const [isQuestionFlagged, setIsQuestionFlagged] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isAnswerShowing, setIsAnswerShowing] = useState(false);
  const [totalScores, setTotalScores] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [isArrayEnded, setIsArrayEnded] = useState(false);
  const [isExamSubmitted, setIsExamSubmitted] = useState(false);
  const [examDataAfterSubmitting, setExamDataAfterSubmitting] = useState({});
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(undefined);
  const [ourTime, setOurTime] = useState(undefined);
  const [isDeletedModalOpen, setISDeletedModalOpen] = useState(false);
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isNormalValueShown, setIsNormalValue] = useState(false);
  const [choiceSelectedValue, setChoiceSelectedValue] = useState("");
  const [isFinishClicked, setIsFinishClicked] = useState(false);
  const [examName, setExamName] = useState("");
  const [examType, setExamType] = useState("");
  const quitText =
    "Are you sure you want to quit this exam? If you proceed, your progress will not be saved, and you will not be able to return to or review your answers.";
  const finishText =
    "Are you sure you want to submit this exam? Once submitted, you cannot make further changes, but you will be able to review your answers later.";
  const allMockTestQuestions = useQuery(
    ["MockTestQuestions"],
    getAllMockTestQuestions,
    {
      retry: false,
      staleTime: Infinity,

      onSuccess: (res) => {
        console.log("useQuery on success", res);
        if (res.data.questions.length == 0) {
          console.log("Question length 0", res.data.questions.length);
          setQuizQuestions([]);
        } else {
          let ourModifiedQuestionsArray = [];
          res.data.questions.map((q, index) => {
            let modifiedQuestion = {
              ...q,
              userAnswer: "",
              isFlagged: false,
              crossedIndexes: []
            };
            ourModifiedQuestionsArray.push(modifiedQuestion);
          });
          console.log("Modified Array", ourModifiedQuestionsArray);
          // const shuffledArray = ourModifiedQuestionsArray.toSorted(
          //   () => Math.random() - 0.5
          // );
          // console.log("Shuffled Array", shuffledArray);
          setQuizQuestions(ourModifiedQuestionsArray);
          setExamName(res.data.exam_name);
          setExamType(res.data.exam_type);

          if (res.time !== "") {
            setOurTime(res.time);
          } else {
            setOurTime(90);
          }
        }
      }
    }
  );
  useEffect(() => {
    allMockTestQuestions.refetch();
  }, []);
  // useEffect(() => {}, [isFinishClicked]);
  const reportList = [
    "Question Clarity",
    "Answer Inaccuracy",
    "Explanation Mistakes",
    "Bug",
    "Other"
  ];
  const QuitExam = () => {
    let ourData = {
      examName: exam.name,
      exam_id: exam._id,
      examType: "Mock",
      examData: quizQuestions,
      user_id: userStored._id,
      total_correct_answers: totalScores,
      total_questions: quizQuestions.length
    };
    mutate(
      {
        url: "/review",
        values: ourData
      },
      {
        onSuccess: (res) => {
          setIsExamSubmitted(true);
          console.log("Exam submitted response", res);
          const { total_correct_answers, total_questions } =
            res.data.data.review;
          setExamDataAfterSubmitting({
            total_correct_answers,
            total_questions
          });
          toast.success("Exam taken successfully");
        },
        onError: (response) => {
          toast.error("An error occured while submitting exam");
        }
      }
    );
  };
  const radioBtnOnChange = (e) => {
    // setRadioValue(e.target.value);
    console.log("our event", e);
    setChoiceSelectedValue(e.target.value);
    console.log("selected value", e.target.value);
    quizQuestions[currentQuestion].userAnswer = e.target.value;
    setIsCorrectAnswer(quizQuestions[currentQuestion].correct_answer);
    if (e.target.value === quizQuestions[currentQuestion].correct_answer) {
      setTotalScores((prevCounter) => {
        // console.log("Previous value", prevCounter);
        prevCounter = prevCounter + 1;
        //console.log("Updated counter", prevCounter);
        return prevCounter;
      });
    }
  };
  const timerExpired = () => {
    console.log("Timer Expired");
    QuitExam();
  };
  const RightClickHandle = (e) => {
    if (e.button === 2) {
      console.log("right");
    }
  };
  function shuffle(array) {
    // let currentIndex = array.length,
    //   randomIndex;

    // // While there remain elements to shuffle.
    // while (currentIndex > 0) {
    //   // Pick a remaining element.
    //   randomIndex = Math.floor(Math.random() * currentIndex);
    //   currentIndex--;

    //   // And swap it with the current element.
    //   [array[currentIndex], array[randomIndex]] = [
    //     array[randomIndex],
    //     array[currentIndex]
    //   ];
    // }

    // return array;
    // for (let i = array.length - 1; i > 0; i--) {
    //   const j = Math.floor(Math.random() * (i + 1));
    //   [array[i], array[j]] = [array[j], array[i]];
    // }
    // return array;
    array.sort(() => Math.random() - 0.5);
  }
  const handleExit = () => {
    QuitExam();
    setISDeletedModalOpen(false);
  };
  console.log("Our total score", totalScores);

  if (allMockTestQuestions.isLoading || quizQuestions === undefined) {
    return <Loader />;
  }
  if (allMockTestQuestions.isError) {
    return <h1>No Data found</h1>;
  }
  if (allMockTestQuestions.isSuccess) {
    //
    console.log("All questions in state", quizQuestions);
    return (
      <>
        <Toaster />
        {quizQuestions.length === 0 ? (
          <div className="dashboard-wrapper">
            <Sidebar examName={exam.name} />
            <div className="admin-nested">
              <div className="start__question">
                {/* <div className="start__question_left">
                  <div className="start__question_left-header">
                    <h1 className="start__question_left-header-left"> */}
                <h2>No Data Found</h2>
                {/* </h1>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="dashboard-wrapper">
            <Sidebar examName={exam.name} />
            <div className="admin-nested">
              {isDeletedModalOpen ? (
                <DeletionModal
                  title={isFinishClicked ? finishText : quitText}
                  isExam={true}
                  del={() => (isFinishClicked ? handleExit() : navigate(-1))}
                  hide={() => setISDeletedModalOpen(false)}
                />
              ) : null}
              <div className="start__question">
                <div className="start__question_left">
                  <div className="start__question_left-header">
                    <h1 className="start__question_left-header-left">
                      Question {currentQuestion + 1}
                    </h1>
                    <div className="start__question_left-header-right">
                      <div className="btns">
                        {isFinishClicked ? (
                          <>
                            <div
                              className={
                                isAnswerShowing
                                  ? "single-btn back"
                                  : "single-btn"
                              }
                              onClick={() => {
                                setIsAnswerShowing(!isAnswerShowing);
                              }}
                            >
                              <SlBookOpen size="1.5rem" />
                              <h6 className="single-btn-heading">Answer</h6>
                            </div>
                            <div className="single-btn">
                              <HiOutlineLightBulb size="1.5rem" />
                              <h6 className="single-btn-heading">
                                Normal Value
                              </h6>
                            </div>
                            <div
                              className={
                                isReportOpen ? "single-btn back" : "single-btn"
                              }
                              onClick={() => setIsReportOpen(!isReportOpen)}
                            >
                              <BsFlag size="1.5rem" />
                              <h6 className="single-btn-heading">Report</h6>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={
                                isNormalValueShown
                                  ? "single-btn back"
                                  : "single-btn"
                              }
                              onClick={() => {
                                setIsNormalValue(!isNormalValueShown);
                              }}
                            >
                              <HiOutlineLightBulb size="1.5rem" />
                              <h6 className="single-btn-heading">
                                Normal Value
                              </h6>
                            </div>
                            <div
                              className={
                                isReportOpen ? "single-btn back" : "single-btn"
                              }
                              onClick={() => setIsReportOpen(!isReportOpen)}
                            >
                              <BsFlag size="1.5rem" />
                              <h6 className="single-btn-heading">Report</h6>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="start__question_left-questionWrapper">
                    {isAnswerShowing ? (
                      <>
                        <p className="question-text">
                          <strong> Answer: </strong>

                          {
                            allMockTestQuestions.data.data.questions[
                              currentQuestion
                            ].correct_answer
                          }
                        </p>
                        <p className="question-text">
                          <strong>Answer Explanation: </strong>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {
                              allMockTestQuestions.data.data.questions[
                                currentQuestion
                              ].answer_explanation
                            }
                          </p>
                        </p>

                        {quizQuestions[currentQuestion]?.answer_image ? (
                          <div className="questionImagewrapper">
                            <img
                              className="imageOfQuestion"
                              //src={`http://localhost:4000/questionAnswerImages/${quizQuestions[currentQuestion]?.answer_image}`}
                              src={`https://www.medgrizz.com/questionAnswerImages/${quizQuestions[currentQuestion].answer_image}`}
                              alt=""
                            />
                          </div>
                        ) : null}
                      </>
                    ) : isNormalValueShown ? (
                      <p className="question-text">
                        <img src={NormalValue1} alt="" />
                        <img src={NormalValue2} alt="" />
                        <img src={NormalValue3} alt="" />
                        <img src={NormalValue4} alt="" />
                      </p>
                    ) : (
                      <>
                        <p className="question-text">
                          {
                            allMockTestQuestions.data.data.questions[
                              currentQuestion
                            ].title
                          }
                        </p>
                        {quizQuestions[currentQuestion]?.question_image ? (
                          <div className="questionImagewrapper">
                            <img
                              className="imageOfQuestion"
                              //src={`http://localhost:4000/questionAnswerImages/${quizQuestions[currentQuestion]?.question_image}`}
                              src={`https://www.medgrizz.com/questionAnswerImages/${quizQuestions[currentQuestion].question_image}`}
                              alt=""
                            />
                          </div>
                        ) : null}

                        <div
                          role="group"
                          aria-labelledby="my-radio-group"
                          className="radio-buttons-container"
                        >
                          {quizQuestions[currentQuestion].choices.map(
                            (choice, index) => {
                              console.log(
                                "my answer",
                                quizQuestions[currentQuestion].correct_answer
                              );
                              console.log("is finished true", isFinishClicked);
                              return (
                                <div key={index}>
                                  <RowInputRadio
                                    name="radio-group"
                                    id={choice}
                                    key={index}
                                    label={choice}
                                    handleChange={radioBtnOnChange}
                                    checkboxValue={choice}
                                    handleMouseClick={RightClickHandle}
                                    isCorrectAnswer={
                                      isFinishClicked &&
                                      quizQuestions[currentQuestion]
                                        .correct_answer
                                    }
                                    radioValue={
                                      quizQuestions[currentQuestion]
                                        .userAnswer !== ""
                                        ? quizQuestions[currentQuestion]
                                            .userAnswer
                                        : radioValue
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}

                    <div className="question-pagination-wrapper">
                      <div
                        className="pagination-item"
                        onClick={() => {
                          if (currentQuestion > 0) {
                            setCurrentQuestion(currentQuestion - 1);
                          } else {
                          }
                        }}
                      >
                        <MdKeyboardArrowLeft size="3rem" />
                        Previous Question
                      </div>
                      <div
                        className="pagination-item"
                        onClick={() => {
                          setIsQuestionFlagged(!isQuestionFlagged);
                          quizQuestions[currentQuestion].isFlagged =
                            !quizQuestions[currentQuestion].isFlagged;
                        }}
                      >
                        {isQuestionFlagged ? (
                          <IconFlagVariant />
                        ) : (
                          <IconFlagVariantOutline />
                        )}
                        Flag Question
                      </div>

                      <div
                        className="pagination-item"
                        onClick={() => {
                          //setChecked(undefined);
                          setRadioValue(undefined);
                          setIsCorrectAnswer(undefined);
                          setIsQuestionFlagged(false);
                          if (currentQuestion < quizQuestions.length - 1) {
                            setCurrentQuestion(currentQuestion + 1);
                          } else {
                            setIsArrayEnded(true);
                          }
                        }}
                      >
                        <MdKeyboardArrowRight size="3rem" />
                        Next Question
                      </div>
                    </div>
                    {isArrayEnded && !isExamSubmitted ? (
                      <div>
                        <Formik
                          initialValues={{
                            examName: exam.name,
                            exam_id: exam._id,
                            examType: "Mock",
                            examData: quizQuestions,
                            user_id: userStored._id,
                            total_correct_answers: totalScores,
                            total_questions: quizQuestions.length
                          }}
                          onSubmit={(values, props) => {
                            console.log("values", values);
                            mutate(
                              {
                                url: "/review",
                                values: values
                              },
                              {
                                onSuccess: (res) => {
                                  setIsExamSubmitted(true);
                                  console.log("Exam submitted response", res);
                                  const {
                                    total_correct_answers,
                                    total_questions
                                  } = res.data.data.review;
                                  setExamDataAfterSubmitting({
                                    total_correct_answers,
                                    total_questions
                                  });
                                  toast.success("Exam taken successfully");
                                  props.setSubmitting(false);
                                  setReviewStateAfterSubmittingExam(true);
                                  // setTimeout(() => {
                                  //   navigate("/review_center");
                                  // }, 3000);
                                  //
                                },
                                onError: (response) => {
                                  toast.error(
                                    "An error occured while submitting exam"
                                  );
                                  props.setSubmitting(false);
                                }
                              }
                            );
                          }}
                        >
                          {(formik) => (
                            <>
                              <Button
                                text={
                                  formik.isSubmitting
                                    ? "Loading ..."
                                    : "Submit Test"
                                }
                                type="submit"
                                isDisable={formik.isSubmitting}
                                handleSubmit={formik.handleSubmit}
                              />
                            </>
                          )}
                        </Formik>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {isReportOpen ? (
                    <div className="start__question_left-reportWrapper">
                      <div className="report-topic">
                        <Formik
                          initialValues={{
                            report_title: "",
                            report_body: "",
                            question_id: quizQuestions[currentQuestion]._id,
                            user_id: userStored._id
                          }}
                          onSubmit={(values, props) => {
                            // console.log("values", values);
                            mutate(
                              {
                                url: "/reports",
                                values: values
                              },
                              {
                                onSuccess: () => {
                                  //alert("Form submitted successfully");
                                  toast.success(
                                    "Question reported successfully"
                                  );
                                  props.setSubmitting(false);
                                },
                                onError: (response) => {
                                  // alert("An error occured while submitting exam");
                                  toast.error(
                                    "An error occured while submitting exam"
                                  );
                                  props.setSubmitting(false);
                                }
                              }
                            );
                          }}
                        >
                          {(formik) => (
                            <>
                              <div className="selectReportTopic">
                                <FormRowSelect
                                  name="report_title"
                                  id="report_title"
                                  value={formik.values.report_title}
                                  onChange={formik.handleChange}
                                  list={reportList}
                                  label="Report Topic"
                                  classesName="width-fit-content"
                                  wrapperWidthToFitContent="selectWrapper-width-fit-content"
                                />
                              </div>

                              <FormRowTextarea
                                rows={10}
                                label="Report Explanation"
                                id="report_body"
                                name="report_body"
                                onChange={formik.handleChange}
                                value={formik.values.report_body}
                              />
                              <Button
                                text={
                                  formik.isSubmitting
                                    ? "Loading ..."
                                    : "Report Question"
                                }
                                type="submit"
                                isDisable={formik.isSubmitting}
                                handleSubmit={formik.handleSubmit}
                              />
                            </>
                          )}
                        </Formik>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="start__question_right">
                  <div className="start__question_right-header">
                    {isExamSubmitted ? (
                      <div className="scores_section">
                        <div className="left">
                          <h4>Scores</h4>
                          <div className="bottom">
                            <h3>
                              {examDataAfterSubmitting.total_correct_answers}
                            </h3>
                            /<h3>{examDataAfterSubmitting.total_questions}</h3>
                          </div>
                        </div>
                        <div className="right">
                          <h4>Percentage</h4>
                          <h3>
                            {Math.round(
                              (examDataAfterSubmitting.total_correct_answers /
                                examDataAfterSubmitting.total_questions) *
                                100
                            )}
                            %
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <CountDownTimer
                        time={ourTime * 60000}
                        timerExpired={timerExpired}
                      />
                    )}
                  </div>
                  <div className="start__question_right-body">
                    <div className="question_nos_wrapper">
                      {quizQuestions.map((q, index) => {
                        return (
                          <div
                            className={
                              q.userAnswer !== "" && q.isFlagged !== false
                                ? "question_no question_no_answered question_no_flagged"
                                : q.userAnswer !== ""
                                ? "question_no question_no_answered"
                                : q.isFlagged !== false
                                ? "question_no question_no_flagged "
                                : "question_no"
                            }
                            key={index}
                            onClick={() => {
                              setRadioValue(undefined);
                              setIsCorrectAnswer(undefined);
                              setIsQuestionFlagged(false);
                              setCurrentQuestion(index);
                            }}
                          >
                            {index + 1}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="start__question_right-footer">
                    <div className="footer-items">
                      {isExamSubmitted ? (
                        <div className="footer-item">
                          <BsSkipEndBtn size="3rem" />
                          <h3>Finished</h3>
                        </div>
                      ) : (
                        <>
                          <div
                            className="footer-item"
                            onClick={() => {
                              setIsFinishClicked(true);
                              setISDeletedModalOpen(true);
                            }}
                          >
                            <BsSkipEndBtn size="3rem" />
                            <h3>Finish</h3>
                          </div>
                          <div
                            className="footer-item"
                            onClick={() => {
                              setIsFinishClicked(false);
                              setISDeletedModalOpen(true);
                            }}
                          >
                            <BsBoxArrowInRight size="3rem" />
                            <h3>Quit</h3>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default StartMockTest;
