import "../assets/scss/Wrapper.scss";
import AdminHeader from "../Components/Admin/AdminHeader";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import RadioBtnTabs from "../Components/RadioBtnTabs";
import { PieChart } from "react-minimal-pie-chart";
import { BarChart, Bar, Cell, Legend, ResponsiveContainer } from "recharts";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import apiClient from "../Utils/apiClient";
import Loader from "../Components/Loader";
const PerformanceAndAnalytics = () => {
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [dataLast, setDataLast] = useState(7);
  const [overAllScore, setOverallScore] = useState();
  const [scoreTrend, setScoreTrend] = useState();
  const [singleMockTest, setSingleMockTest] = useState();
  const [subjectScore, setSubjectScore] = useState();
  const [systemScore, setSystemScore] = useState();
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const btnTabs = [
    { label: "Last week", value: 7 },
    { label: "Last Month", value: 30 },
    { label: "All Time", value: 1000 }
  ];
  const getAnalyticsApi = async () => {
    const { data } = await apiClient.get(
      `/review/analytics/${userStored._id}/${dataLast}/${exam._id}`
    );
    return data;
  };
  const getAnalytics = useQuery(["Analytics", dataLast], getAnalyticsApi, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    onSuccess: (res) => {
      console.log("performance response", res);
      let correctValue =
        (res.data?.overallScores[0]?.correct /
          res.data?.overallScores[0]?.total) *
        100;
      correctValue = Math.round(correctValue * 100) / 100;
      let incorrectValue =
        (res.data?.overallScores[0]?.in_correct /
          res.data?.overallScores[0]?.total) *
        100;
      incorrectValue = Math.round(incorrectValue * 100) / 100;
      // console.log("Type of correct value", incorrectValue.toFixed(2));
      let overAllScoresDataModified = [
        {
          title: "Correct",
          value: correctValue,
          color: "#E38627"
        },
        {
          title: "Incorrect",
          value: incorrectValue,
          color: "#C13C37"
        }
      ];

      // let dataOfOverallScoreFromRes = res.data.overallScores;
      // let overAllData = [];
      // for (let i = 0; i < dataOfOverallScoreFromRes.length; i++) {
      //   let pieDataObj = dataOfOverallScoreFromRes[i];
      //   let colorDataObj = colorsOfPie[i];
      //   let finalPieObject = { ...pieDataObj, ...colorDataObj };
      //   console.log("Final pie Object", finalPieObject);
      //   overAllData.push(finalPieObject);
      // }
      console.log("useQuery on success", res);
      // console.log(overAllData);
      setOverallScore(overAllScoresDataModified);
      setScoreTrend(res.data.scoreByDate);
      setSingleMockTest(res.data.singleMockTest);
      setSubjectScore(res.data.OurSubjects);
      setSystemScore(res.data.OurSystems);
    }
  });

  const radioBtnValueForPreviousHistory = (value) => {
    console.log("Our history value", value);
    //console.log("value of radio", dataLast);
    setDataLast(value);
    console.log("New value of radio", dataLast);
  };
  useEffect(() => {
    getAnalytics.refetch();
  }, []);
  if (getAnalytics.isError) {
    return <h1>No Data Found</h1>;
  }
  if (getAnalytics.isLoading) {
    return <Loader />;
  }
  if (getAnalytics.isSuccess) {
    return (
      <>
        <div className="dashboard-wrapper">
          <Sidebar examName={exam.name} />
          <div className="admin-nested">
            {/* <AdminHeader /> */}
            <div className="performance-and-analytics">
              <div className="fetching">
                <RadioBtnTabs
                  handleChange={radioBtnValueForPreviousHistory}
                  handleCheck={dataLast}
                  list={btnTabs}
                />
              </div>
              <div className="graphs">
                <div className="graph-left">
                  <div className="heading">
                    <h2>Overall Score</h2>
                  </div>
                  <div className="graphData">
                    <PieChart
                      paddingAngle={0}
                      labelStyle={{
                        fontWeight: 700,
                        fontSize: "0.40rem",
                        fill: "#ffffff"
                      }}
                      label={(props) => {
                        return (
                          props.dataEntry.title +
                          " " +
                          props.dataEntry.value +
                          "%"
                        );
                      }}
                      data={overAllScore}
                    />
                  </div>
                </div>
                <div className="graph-right">
                  <div className="heading">
                    <h2>Score Trend</h2>
                  </div>
                  <div className="graphData">
                    <LineChart
                      width={500}
                      height={300}
                      data={scoreTrend}
                      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                      <Line
                        type="monotone"
                        dataKey="total_correct_answers"
                        stroke="#8884d8"
                      />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <XAxis dataKey="date" />
                      <YAxis dataKey="total_correct_answers" />
                      <Tooltip />
                    </LineChart>
                  </div>
                </div>
              </div>
              <div className="graphs">
                <div className="graph-left">
                  <div className="heading">
                    <h2>Score By Subject</h2>
                  </div>
                  <div className="graphDatalarge">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={300}
                        data={subjectScore}
                        angle={-45}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="subjectName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="correct"
                          stackId="correct"
                          fill="#32CD32"
                        />
                        <Bar
                          dataKey="incorrect"
                          stackId="Incorrect"
                          fill="#C70039"
                        />
                        <Bar
                          dataKey="notanswered"
                          stackId="Not Answered"
                          fill="#FFC300"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="graph-right">
                  <div className="heading">
                    <h2>Score By System</h2>
                  </div>
                  <div className="graphDatalarge">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width={500} height={300} data={systemScore}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="systemName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="correct"
                          stackId="correct"
                          fill="#32CD32"
                        />
                        <Bar
                          dataKey="incorrect"
                          stackId="Incorrect"
                          fill="#C70039"
                        />
                        <Bar
                          dataKey="notanswered"
                          stackId="Not Answered"
                          fill="#FFC300"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PerformanceAndAnalytics;
