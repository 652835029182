import "./App.scss";
import { useState, useEffect } from "react";
import HelpCenter from "./Pages/HelpCenter/HelpCenter";
import HelpCenterDetails from "./Pages/HelpCenter/HelpCenterDetails";
import Report from "./Pages/Report/Report";
import ReportDetail from "./Pages/Report/ReportDetail";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import AddUser from "./Pages/User/AddUser";
import AllUsers from "./Pages/User/AllUsers";
import AddSubject from "./Pages/Subject/AddSubject";
import AllSubjects from "./Pages/Subject/AllSubjects";
import AddSystem from "./Pages/System/AddSystem";
import AllSystem from "./Pages/System/AllSystems";
import AllQuestions from "./Pages/Questions/AllQuestions";
import AddQuestion from "./Pages/Questions/AddQuestion";
import AllExams from "./Pages/Exam/AllExams";
import AddExam from "./Pages/Exam/AddExam";
import AddPlan from "./Pages/Plans/AddPlan";
import AllPlans from "./Pages/Plans/AllPlan";
import Login from "./Pages/Login";
import EditBulk from "./Pages/User/EditBulk";
import AddBulk from "./Pages/User/AddBulk";
import EditSubject from "./Pages/Subject/EditSubject";
import EditExam from "./Pages/Exam/EditExam";
import EditSystem from "./Pages/System/EditSystem";
import CustomModel from "./Components/CustomModel";
import EditQuestion from "./Pages/Questions/EditQuestion";
import EditUser from "./Pages/User/EditUser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Home from "./Pages/Home";
import PricingAndPlans from "./Pages/PricingAndPlans";
import Billing from "./Pages/Billing";
import PracticeQuestions from "./Pages/PracticeQuestions";
import MockTests from "./Pages/MockTests";
import ReviewCenter from "./Pages/ReviewCenter";
import PerformanceAndAnalytics from "./Pages/PerformanceAndAnalytics";
import Profile from "./Pages/Profile";
import HelpAndSupport from "./Pages/HelpAndSupport";
import Privacy from "./Pages/Privacy";
import TermsAndServices from "./Pages/TermsAndServices";
import AboutUs from "./Pages/AboutUs";
import Faqs from "./Pages/Faqs";
import StartPracticeQuestions from "./Pages/StartPracticeQuestions";
import StartMockTest from "./Pages/StartMockTest";
import ReviewCenterDetail from "./Pages/ReviewCenterDetail";
import HelpAndSupportDetails from "./Pages/HelpAndSupportDetails";
import CheckoutSuccess from "./Components/CheckoutSuccess";
import CheckOutCancelled from "./Components/CheckOutCancelled";
import ResetPassword from "./Pages/ResetPassword";
import UpdatePassword from "./Pages/UpdatePassword";
import CustomCheckout from "./Pages/CustomCheckout";
import GoToExam from "./Pages/GoToExam";
import MyHomeLog from "./Pages/MyHomeLog";
import TopMenu from "./Components/TopMenu";
import SubcribedPlans from "./Pages/SubcribedPlans";
import AuthRoute from "./Components/routes/AuthRoute";
import ReviewResults from "./Pages/ReviewResults";

function App() {
  // useEffect(() => {
  //   localStorage.setItem("language", "eng");
  // }, []);
  return (
    <div>
      <GoogleOAuthProvider clientId="748234956506-lgcq4mkbbs9fhn0dbik69bivhqdbnk79.apps.googleusercontent.com">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route
              path="/update_password/:email/:token"
              element={<UpdatePassword />}
            />
            <Route path="/pricing_and_plans" element={<PricingAndPlans />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms_and_services" element={<TermsAndServices />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/faqs" element={<Faqs />} />

            <Route path="/cancel_plans" element={<SubcribedPlans />} />
            <Route path="/purchased_plans" element={<GoToExam />} />
            <Route path="/billing" element={<Billing />} />

            <Route path="/checkout" element={<CustomCheckout />} />
            <Route path="/checkout-success" element={<CheckoutSuccess />} />
            <Route path="/checkout-cancelled" element={<CheckOutCancelled />} />
            <Route path="/practice_questions" element={<PracticeQuestions />} />

            <Route
              path="/start_practice_questions"
              element={<StartPracticeQuestions />}
            />
            <Route path="/start_mock_test" element={<StartMockTest />} />
            <Route path="/mock_tests" element={<MockTests />} />
            <Route path="/review_center" element={<ReviewCenter />} />
            <Route path="/review_center/:id" element={<ReviewResults />} />
            <Route
              path="/review_center_detail"
              element={<ReviewCenterDetail />}
            />
            <Route path="/performance" element={<PerformanceAndAnalytics />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/help_and_support" element={<HelpAndSupport />} />
            <Route
              path="/help_and_support_details"
              element={<HelpAndSupportDetails />}
            />
            <Route path="/dashboard/">
              <Route path="user">
                <Route index path="add" element={<AddUser />} />
                <Route path="all" element={<AllUsers />} />
                <Route
                  path="edit/:id"
                  element={
                    <CustomModel
                      component={<EditUser />}
                      className="show"
                      isEdit={true}
                    />
                  }
                />
                <Route path="editBulk" element={<EditBulk />} />
                <Route path="addBulk" element={<AddBulk />} />
              </Route>
              <Route path="subject">
                <Route index path="add" element={<AddSubject />} />
                <Route
                  index
                  path="edit/:id"
                  element={
                    <CustomModel
                      component={<EditSubject />}
                      className="show"
                      isEdit={true}
                    />
                  }
                />
                <Route path="all" element={<AllSubjects />} />
              </Route>
              <Route path="system">
                <Route index path="add" element={<AddSystem />} />
                <Route path="all" element={<AllSystem />} />
                <Route
                  index
                  path="edit/:id"
                  element={
                    <CustomModel
                      component={<EditSystem />}
                      className="show"
                      isEdit={true}
                    />
                  }
                />
              </Route>
              <Route path="question">
                <Route index path="add" element={<AddQuestion />} />
                <Route path=":exam_id/:exam_name" element={<AllQuestions />} />
                <Route
                  path="edit/:exam_id/:question_id"
                  element={
                    <CustomModel
                      component={<EditQuestion />}
                      className="show"
                      isEdit={true}
                    />
                  }
                />
                <Route
                  index
                  path="edit/:id"
                  element={
                    <CustomModel component={<EditSubject />} className="show" />
                  }
                />
              </Route>
              <Route path="exam/">
                <Route index path="add" element={<AddExam />} />
                <Route path="all" element={<AllExams />} />
                <Route
                  index
                  path="edit/:exam_name/:id"
                  element={
                    <CustomModel
                      component={<EditExam />}
                      className="show"
                      isEdit={true}
                    />
                  }
                />
              </Route>

              <Route path="plans/">
                <Route index path="add" element={<AddPlan />} />
                <Route path="all" element={<AllPlans />} />
              </Route>
              <Route path="reports/">
                <Route index path="report" element={<Report />} />
                <Route path="report_details/:id" element={<ReportDetail />} />
              </Route>
              <Route path="help/">
                <Route index path="help_center" element={<HelpCenter />} />
                <Route
                  path="help_center_details/:id"
                  element={<HelpCenterDetails />}
                />
              </Route>
            </Route>
            <Route path="/report" element={<div>Report</div>} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
