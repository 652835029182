import { useState } from "react";
import TopMenu from "../Components/TopMenu";
import FaqsImage from "../assets/images/bg2.jpeg";
import "../assets/scss/StaticPagesWrapper.scss";
import Footer from "../Components/Footer";
const Faqs = () => {
  const len = localStorage.getItem("language");
  const [language, setLanguage] = useState(len);
  const faqHeading = {
    en: "Frequently Asked Questions (FAQs)",
    thai: "คำถามที่พบบ่อย (FAQ)"
  };
  const languageHandler = (val) => {
    console.log("Language is ", val);
    setLanguage(val);
  };
  const faqsList = {
    en: [
      {
        question: "Q: What is MedGrizz?",
        answer:
          "A: MedGrizz is an online platform designed to help Thai medical students prepare for the National License exam. We provide a comprehensive question bank, customizable mock tests, and performance tracking tools to help students study more efficiently and become better physicians."
      },
      {
        question: "Q: How do I sign up for MedGrizz?",
        answer:
          'A: To sign up for MedGrizz, simply click on the "Sign Up" button in the top menu or visit our Pricing & Plans page to choose your preferred subscription plan. You can create a new account by providing your name, email address, and creating a password.'
      },
      {
        question: "Q: What subscription plans do you offer?",
        answer: `A: We offer a range of subscription plans tailored to meet your needs. For detailed information on duration and pricing, please visit our "Pricing and Plans" page.`
      },
      {
        question: "Q: Can I tailor my practice session to my specific needs?",
        answer: `A: Absolutely! While our mock tests come with a fixed number of questions and time to simulate real exam conditions, our "Practice Questions" page offers you full customization. You can select number of questions, specific categories, question types, and even set your own time limit. This ensures you get the most personalized practice experience based on your preferences and needs.`
      },

      {
        question: "Q: How do I report an incorrect question or answer?",
        answer:
          'A: If you come across an incorrect question or answer, you can report it using the "Report" button found on each question page. Our team will review the report and make any necessary corrections as soon as possible.'
      },
      {
        question: "Q: Can I access MedGrizz on my smartphone or tablet?",
        answer:
          "A: Yes, MedGrizz is designed to be responsive and accessible on a variety of devices, including smartphones and tablets. You can easily study on-the-go using your preferred device."
      },
      {
        question: "Q: How do I contact customer support?",
        answer:
          "A: If you need assistance or have any questions, you can reach out to our customer support team through the Help & Support page, which is accessible to registered members. Simply fill out the contact form, and we will respond as soon as possible."
      },
      {
        question: "Q: Is MedGrizz only for Thai medical students?",
        answer:
          "A: MedGrizz is primarily designed for Thai medical students preparing for the National License exam. However, our question bank and mock tests may also be useful to medical students from other countries who want to practice and strengthen their knowledge in various subject areas."
      },
      {
        question: "Q: Can I share my MedGrizz account with others?",
        answer:
          "A: No, sharing your MedGrizz account is against our Terms of Service. Each subscription plan is intended for individual use only. Unauthorized sharing of accounts may lead to account suspension or termination."
      },
      {
        question: "Q: How do I upgrade, downgrade, or cancel my subscription?",
        answer:
          "A: You can manage your subscription by visiting your User Profile. From there, you can upgrade or downgrade your plan, or cancel your subscription altogether. If you need assistance, feel free to contact our customer support team through the Help & Support page."
      },
      {
        question: "Q: Do you offer group discounts or institutional plans?",
        answer:
          "A: We understand that medical schools or study groups may be interested in using MedGrizz as a study resource for multiple students. Please contact our customer support team to discuss possible group discounts or customized institutional plans."
      },
      {
        question: "Q: Are there any system requirements to use MedGrizz?",
        answer:
          "A: MedGrizz is a web-based platform that works on most modern web browsers, such as Google Chrome, Mozilla Firefox, Safari, and Microsoft Edge. Ensure that your browser is up-to-date and that JavaScript is enabled for the best user experience."
      },
      {
        question: "Q: Can I practice offline using MedGrizz?",
        answer:
          "A: Currently, MedGrizz is an online platform and requires an active internet connection to access the question bank and other features. However, we are always looking for ways to improve and expand our offerings, so stay tuned for any updates regarding offline capabilities.\nIf you have any more questions or need assistance, please don't hesitate to contact our customer support team through the Help & Support page. We're here to help you on your journey to success in the NL exam!"
      }
    ],
    thai: [
      {
        question: "Q: MedGrizz คืออะไร",
        answer:
          "A: MedGrizz เป็นแพลตฟอร์มออนไลน์ที่ออกแบบมาเพื่อช่วยนักศึกษาแพทย์ไทยเตรียมความพร้อมสำหรับการสอบ NL (National License) เรามีคลังคำถามที่ครอบคลุม การทดสอบจำลองที่ปรับแต่งได้ และเครื่องมือติดตามผลการปฏิบัติงานเพื่อช่วยให้นักเรียนเรียนได้อย่างมีประสิทธิภาพมากขึ้นและกลายเป็นแพทย์ที่ดีขึ้น"
      },
      {
        question: "Q: ฉันจะสมัครใช้งาน MedGrizz ได้อย่างไร",
        answer:
          'A: หากต้องการสมัครใช้งาน MedGrizz เพียงคลิกปุ่ม "สมัคร" ในเมนูด้านบนหรือไปที่หน้าราคาและแผนของเราเพื่อเลือกแผนการสมัครสมาชิกที่คุณต้องการ คุณสามารถสร้างบัญชีใหม่ได้โดยระบุชื่อ ที่อยู่อีเมล และสร้างรหัสผ่าน'
      },
      {
        question: "Q: คุณเสนอแผนสมัครสมาชิกอะไรบ้าง?",
        answer: `A: เราเสนอแผนการสมัครสมาชิกที่หลากหลายซึ่งปรับแต่งให้ตรงกับความต้องการของคุณ สำหรับข้อมูลโดยละเอียดเกี่ยวกับระยะเวลาและราคา โปรดไปที่หน้า "แผนราคาของ MedGrizz" ของเรา`
      },
      {
        question:
          "Q: ฉันสามารถปรับแต่งเซสชั่นการฝึกซ้อมให้ตรงกับความต้องการเฉพาะของฉันได้หรือไม่?",
        answer: `A: แน่นอน! แม้ว่าการทดสอบจำลองของเราจะมีคำถามและเวลาในการจำลองเงื่อนไขการสอบจริงในจำนวนที่แน่นอน แต่หน้า "คำถามฝึกหัด" ของเราจะเสนอการปรับแต่งเต็มรูปแบบให้กับคุณ คุณสามารถเลือกจำนวนคำถาม หมวดหมู่เฉพาะ ประเภทคำถาม และแม้แต่กำหนดเวลาของคุณเองได้ สิ่งนี้ทำให้มั่นใจได้ว่าคุณจะได้รับประสบการณ์การฝึกฝนที่เป็นส่วนตัวที่สุดตามความต้องการและความต้องการของคุณ`
      },

      {
        question: "Q: ฉันจะรายงานคำถามหรือคำตอบที่ไม่ถูกต้องได้อย่างไร",
        answer:
          'A: หากคุณพบคำถามหรือคำตอบที่ไม่ถูกต้อง คุณสามารถรายงานได้โดยใช้ปุ่ม "รายงาน" ที่พบในหน้าคำถามแต่ละหน้า ทีมงานของเราจะตรวจสอบรายงานและทำการแก้ไขที่จำเป็นโดยเร็วที่สุด'
      },
      {
        question:
          "Q: ฉันสามารถเข้าถึง MedGrizz บนสมาร์ทโฟนหรือแท็บเล็ตของฉันได้หรือไม่",
        answer:
          "A: ได้! MedGrizz ได้รับการออกแบบมาให้ตอบสนองและเข้าถึงได้บนอุปกรณ์หลากหลายประเภท รวมถึงสมาร์ทโฟนและแท็บเล็ต คุณสามารถเรียนได้ทุกที่ทุกเวลาโดยใช้อุปกรณ์ที่คุณต้องการ"
      },
      {
        question: "Q: ฉันจะติดต่อฝ่ายสนับสนุนลูกค้าได้อย่างไร",
        answer:
          "A: หากคุณต้องการความช่วยเหลือหรือมีคำถามใดๆ คุณสามารถติดต่อทีมสนับสนุนลูกค้าของเราได้ผ่านทางหน้าช่วยเหลือและสนับสนุน ซึ่งสมาชิกที่ลงทะเบียนสามารถเข้าถึงได้ เพียงกรอกแบบฟอร์มติดต่อ แล้วเราจะตอบกลับโดยเร็วที่สุด"
      },
      {
        question:
          "Q: MedGrizz ให้บริการเฉพาะนักศึกษาแพทย์ไทยเท่านั้นใช่หรือไม่",
        answer:
          "A: MedGrizz ได้รับการออกแบบมาเพื่อนักศึกษาแพทย์ไทยที่กำลังเตรียมตัวสอบ NL (National License) เป็นหลัก อย่างไรก็ตาม ธนาคารคำถามและแบบทดสอบจำลองของเราอาจเป็นประโยชน์สำหรับนักศึกษาแพทย์จากประเทศอื่นๆ ที่ต้องการฝึกฝนและเสริมสร้างความรู้ในสาขาวิชาต่างๆ"
      },
      {
        question:
          "Q: ฉันสามารถแบ่งปันบัญชี MedGrizz ของฉันกับผู้อื่นได้หรือไม่",
        answer:
          "A: ไม่ การแชร์บัญชี MedGrizz ของคุณถือเป็นการละเมิดข้อกำหนดในการให้บริการของเรา แผนการสมัครสมาชิกแต่ละแผนการมีไว้สำหรับการใช้งานส่วนบุคคลเท่านั้น การแบ่งปันบัญชีโดยไม่ได้รับอนุญาตอาจนำไปสู่การระงับหรือยุติบัญชี"
      },
      {
        question:
          "Q: ฉันจะอัปเกรด ดาวน์เกรด หรือยกเลิกการสมัครสมาชิกได้อย่างไร",
        answer:
          "A: คุณสามารถจัดการการสมัครสมาชิกของคุณได้โดยไปที่โปรไฟล์ผู้ใช้ของคุณ จากตรงนั้น คุณสามารถอัปเกรดหรือดาวน์เกรดแผนของคุณ หรือยกเลิกการสมัครรับข้อมูลทั้งหมดได้ หากคุณต้องการความช่วยเหลือ โปรดติดต่อทีมสนับสนุนลูกค้าของเราผ่านทางหน้าช่วยเหลือและสนับสนุน"
      },
      {
        question: "Q: คุณเสนอส่วนลดแบบกลุ่มหรือแผนแบบสถาบันหรือไม่?",
        answer:
          "A: เราเข้าใจดีว่าโรงเรียนแพทย์หรือกลุ่มการศึกษาอาจสนใจใช้ MedGrizz เป็นแหล่งข้อมูลการศึกษาสำหรับนักเรียนหลายคน โปรดติดต่อทีมสนับสนุนลูกค้าของเราผ่านอีเมล medgrizz@gmail.com เพื่อหารือเกี่ยวกับส่วนลดกลุ่มที่เป็นไปได้หรือแผนสถาบันที่กำหนดเอง"
      },
      {
        question: "Q: มีข้อกำหนดของระบบในการใช้ MedGrizz หรือไม่",
        answer:
          "A: MedGrizz เป็นแพลตฟอร์มบนเว็บที่ทำงานบนเว็บเบราว์เซอร์สมัยใหม่ส่วนใหญ่ เช่น Google Chrome, Mozilla Firefox, Safari และ Microsoft Edge ตรวจสอบให้แน่ใจว่าเบราว์เซอร์ของคุณทันสมัยและเปิดใช้งาน JavaScript เพื่อประสบการณ์ผู้ใช้ที่ดีที่สุด"
      },
      {
        question: "Q: ฉันสามารถฝึกแบบออฟไลน์โดยใช้ MedGrizz ได้หรือไม่",
        answer:
          "A: ปัจจุบัน MedGrizz เป็นแพลตฟอร์มออนไลน์และต้องมีการเชื่อมต่ออินเทอร์เน็ตที่ใช้งานได้เพื่อเข้าถึงคลังคำถามและคุณสมบัติอื่นๆ อย่างไรก็ตาม เรามองหาวิธีปรับปรุงและขยายข้อเสนอของเราอยู่เสมอ ดังนั้นโปรดติดตามการอัปเดตเกี่ยวกับความสามารถแบบออฟไลน์ หากคุณมีคำถามเพิ่มเติมหรือต้องการความช่วยเหลือ โปรดอย่าลังเลที่จะติดต่อทีมสนับสนุนลูกค้าของเราผ่านทางหน้าช่วยเหลือและสนับสนุน เราพร้อมช่วยเหลือคุณในการเดินทางสู่ความสำเร็จในการสอบ NL!"
      }
    ]
  };
  return (
    <div>
      <TopMenu languageHandler={languageHandler} />
      <div className="imageWrapper">
        <div className="terms-image"></div>
        <div className="main-heading">
          <h1>{language === "thai" ? faqHeading.thai : faqHeading.en}</h1>
        </div>
      </div>
      <div className="generic-list">
        {language === "thai"
          ? faqsList.thai.map((faq, index) => {
              return (
                <div className="list-item-card">
                  <h1 className="card-heading">{faq.question} </h1>
                  <p className="card-text">{faq.answer}</p>
                </div>
              );
            })
          : faqsList.en.map((faq, index) => {
              return (
                <div className="list-item-card">
                  <h1 className="card-heading">{faq.question} </h1>
                  <p className="card-text">{faq.answer}</p>
                </div>
              );
            })}
      </div>
      <Footer />
    </div>
  );
};

export default Faqs;
