import "../assets/scss/Button.scss";
const Button = ({
  text = "button",
  className,
  handleSubmit,
  type,
  isDisable,
  btnState,
}) => {
  return (
    <button
      type={type}
      className={`custom-btn ${className}`}
      onClick={handleSubmit}
      disabled={isDisable}
    >
      {text}
    </button>
  );
};

export default Button;
