import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopMenu from "../Components/TopMenu";
import "../assets/scss/Wrapper.scss";
import { SlUser } from "react-icons/sl";
import CheckoutForm from "../Components/CheckoutForm";
import React from "react";
const CustomCheckout = () => {
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [planObj, setPlanObj] = useState({});

  const { state } = useLocation();

  //console.log("rerender", state);
  return (
    <div>
      <TopMenu />

      <div className="checkout-wrapper">
        <div className="checkout-wrapper-left">
          <div className="userDetails">
            <h2 className="account-heading">Account</h2>
            <div className="user-wrapper">
              {userStored ? (
                userStored?.profile_image ? (
                  <img
                    className="user_image"
                    // src={`http://localhost:4000/userImages/${userStored.profile_image}`}
                    src={`https://www.medgrizz.com/userImages/${userStored.profile_image}`}
                    alt="user"
                  />
                ) : userStored?.google_image ? (
                  <img
                    className="user_image"
                    src={`${userStored.google_image}`}
                    // src={`https://www.medgrizz.com/userImages/${userStored.profile_image}`}
                    alt="user"
                  />
                ) : (
                  <SlUser size="1.5em" className="user_image" />
                )
              ) : null}

              <div className="user_data">
                <h3>{userStored.full_name}</h3>
                <h3>{userStored.email}</h3>
              </div>
            </div>
          </div>
          <div className="line"></div>
          <h4>Payment Details</h4>

          <CheckoutForm
            email={userStored?.email}
            planObj={state}
            user_id={userStored?._id}
            userPlans={userStored.plans}
          />
        </div>
        <div className="checkout-wrapper-middle"></div>
        <div className="checkout-wrapper-right">
          <div className="planDesign">
            <h3>{state?.examName}</h3>
            <h3>{state?.planName}</h3>

            <h6>{state?.description}</h6>
            <div className="total-wrapper">
              <h4>Total : </h4>
              <h4>{`$` + state?.planPrice}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomCheckout);
