import ImageSlider from "../Components/ImageSlider";
import Image1 from "../assets/images/Madgrizz_logo_black.jpeg";
import Image2 from "../assets/images/Madgrizz_logo_blue.jpeg";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../assets/scss/Home.scss";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Button from "../Components/Button";
import TopMenu from "../Components/TopMenu";
import Idea from "../Components/Idea";
import Setting from "../Components/Setting";
import Book from "../Components/Book";
import Exam from "../Components/Exam";
import Flag from "../Components/Flag";
import Performance from "../Components/Performance";
import PricingPlanCard from "../Components/PricingPlanCard";
import Footer from "../Components/Footer";
import PayButton from "../Components/PayButton";
const Billing = () => {
  return (
    <div>
      <TopMenu />

      <div className="pricingPlans__wrapper">
        <h1>Pricing and Plans</h1>
        <div className="pricing_plans-left">
          <h3>Exam Name</h3>
          <div className="plans">
            <Swiper
              breakpoints={{
                // when window width is <= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                // when window width is <= 480px
                480: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },

                // when window width is <= 640px
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                920: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              slidesPerView={4}
              spaceBetween={10}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <PricingPlanCard />
              </SwiperSlide>
              <SwiperSlide>
                <PricingPlanCard />
              </SwiperSlide>
              <SwiperSlide>
                <PricingPlanCard />
              </SwiperSlide>
              <SwiperSlide>
                <PricingPlanCard />
              </SwiperSlide>
              <SwiperSlide>
                <PricingPlanCard />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Billing;
