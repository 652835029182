import { useEffect, useState, useMemo, useRef } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { SlUser } from "react-icons/sl";
import "../../src/assets/scss/TopMenu.scss";
import User from "../assets/images/user.jpg";
import MedLogo from "../assets/Svgs/MedLogo.svg";
import usflag from "../assets/images/Flag_of_the_United_States.jpg";
import thaiflag from "../assets/images/Flag_of_Thailand.png";
import { RiArrowUpSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import apiClient from "../Utils/apiClient";
import { CiLight } from "react-icons/ci";
const TopMenu = ({ languageHandler }) => {
  useEffect(() => {
    //Top menu rendered
    console.log("Top menu rerenders");
  }, []);
  const [isMenuExpanded, setisMenuExpanded] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [language, setLanguage] = useState("eng");
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const isNestedNavigationOpen = useRef(false);

  const navigate = useNavigate();
  console.log("is nested navigaion open", isNestedNavigationOpen.current);
  return (
    <div className="TopNavigation">
      <Link className="brand-name" to="/">
        <img src={MedLogo} alt="" />
      </Link>
      <button
        className="hamburger"
        onClick={() => setisMenuExpanded(!isMenuExpanded)}
      >
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isMenuExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <Link>
            <li
              onClick={() => {
                localStorage.setItem("language", "eng");
                languageHandler("eng");
              }}
            >
              <img className="flag" src={usflag} alt="" />
            </li>
          </Link>
          <li> | </li>
          <Link>
            <li
              onClick={() => {
                localStorage.setItem("language", "thai");
                languageHandler("thai");
              }}
            >
              <img className="flag" src={thaiflag} alt="" />
            </li>
          </Link>
          <Link to="/about_us">
            <li>About Us</li>
          </Link>
          <Link to="/faqs">
            <li>Faqs</li>
          </Link>
          <Link to="/pricing_and_plans">
            <li>Pricing</li>
          </Link>
          {!userStored ? (
            <Link to="/login" state={{ isFromCheckout: "abc" }}>
              <li>Log in / Sign up</li>
            </Link>
          ) : (
            ""
          )}
          <div
            className="profileShown"
            onClick={() => {
              console.log(
                "Value of nested nav",
                isNestedNavigationOpen.current
              );
              setIsProfileOpen(!isProfileOpen);
              //isNestedNavigationOpen.current = !isNestedNavigationOpen.current;
              console.log(
                "Value of nested nav",
                isNestedNavigationOpen.current
              );
            }}
          >
            <li>
              {userStored ? (
                userStored?.profile_image ? (
                  <img
                    // src={`http://localhost:4000/userImages/${userStored.profile_image}`}
                    src={`https://www.medgrizz.com/userImages/${userStored.profile_image}`}
                    alt="user"
                  />
                ) : userStored?.google_image ? (
                  <img src={userStored.google_image} alt="user" />
                ) : (
                  <SlUser size="1.5em" />
                )
              ) : (
                <RiArrowUpSLine size="1.5em" />
              )}

              <div
                className={
                  //isNestedNavigationOpen.current == true
                  isProfileOpen ? "profile_wrapper-shown" : "profile_wrapper"
                }
              >
                <ul>
                  {userStored ? (
                    <>
                      <Link to="/profile">
                        <li>Profile</li>
                      </Link>
                      <Link to="/purchased_plans">
                        <li>Purchased Exams</li>
                      </Link>
                      {/* <Link to="/cancel_plans">
                        <li>Cancel Plans {"  "}</li>
                      </Link> */}
                      {userStored.role === "admin" ? (
                        <Link to="/dashboard/exam/all">
                          <li>Admin Dashboard</li>
                        </Link>
                      ) : (
                        ""
                      )}

                      <Link
                        onClick={() => {
                          localStorage.removeItem("user");
                          localStorage.removeItem("token");

                          navigate("/");
                          window.location.reload(false);
                        }}
                      >
                        <li>Logout</li>
                      </Link>
                    </>
                  ) : null}
                </ul>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default TopMenu;
