import { useEffect, useState } from "react";
import AdminHeader from "../Components/Admin/AdminHeader";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import FormRowSelect from "../Components/FormRowSelect";
import { Formik, Form } from "formik";
import Button from "../Components/Button";
import { Link } from "react-router-dom";
import apiClient from "../Utils/apiClient";
import Loader from "../Components/Loader";
import { useQuery } from "react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const ReviewCenter = () => {
  const sorting_list = ["Old-to-new", "New-to-old"];
  const navigate = useNavigate();
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const [noReviewFound, setNoReviewFound] = useState(false);
  const getAllReviewsApi = async () => {
    const { data } = await apiClient.get(`/review/${userStored._id}`);
    return data;
  };
  const allReviews = useQuery(["AllReviews"], getAllReviewsApi, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    onSuccess: (res) => {
      console.log("useQuery on success", res);
      if (res.status === "failed") {
        setNoReviewFound(true);
      }
    }
  });
  useEffect(() => {
    allReviews.refetch();
  }, []);
  const goToHelpCenterDetail = (review_id) => {
    console.log("Our Review Id", review_id);
    let values = {
      review_id: review_id
    };
    navigate("/review_center_detail", {
      state: values
    });
  };
  if (allReviews.isLoading) {
    return <Loader />;
  }
  if (allReviews.isError) {
    return (
      <div className="dashboard-wrapper">
        <Sidebar />
        <div className="admin-nested">
          <AdminHeader />
          <div className="review-center">
            <div className="fetching">
              <Formik
                initialValues={{
                  title: "",
                  system_id: "",
                  subject_id: "",
                  date: ""
                }}
                onSubmit={(values) => {
                  console.log("we are selecting the system", values);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormRowSelect
                      id="date"
                      label="Date"
                      list={sorting_list}
                      name="date"
                    />
                    <Button type="submit" text="Search" />
                  </Form>
                )}
              </Formik>
            </div>
            <Link to="/review_center_detail">
              <div className="single-review">
                <h1>No data found</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  if (allReviews.isSuccess) {
    console.log("our reviews", noReviewFound);
    return (
      <>
        <div className="dashboard-wrapper">
          <Sidebar examName={exam.name} />
          <div className="admin-nested">
            {/* <AdminHeader /> */}
            <div className="review-center">
              <h1 className="main_review_heading">Review Your Past Exam</h1>

              {allReviews.data.data?.reviews?.map((review, index) => {
                return (
                  <div
                    className="single-review"
                    key={index}
                    onClick={() => goToHelpCenterDetail(review._id)}
                  >
                    <h3>{moment(review.createdAt).format("Do MMMM YYYY")}</h3>
                    <h3>
                      {review.examType} | {review.examName}
                    </h3>
                    <h3>
                      {review.total_correct_answers}/{review.total_questions} |{" "}
                      {Math.round(
                        (review.total_correct_answers /
                          review.total_questions) *
                          100
                      )}
                      %
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ReviewCenter;
