import "../../assets/scss/Wrapper.scss";
import FormHeader from "../../Components/FormHeader";
import FormRowInput from "../../Components/FormRowInput";
import Button from "../../Components/Button";
import FormRowSelect from "../../Components/FormRowSelect";
import { FieldArray, Formik, Form } from "formik";
import usePostMutation from "../../Utils/usePostMutation";
import { useQuery } from "react-query";
import apiClient from "../../Utils/apiClient";
import Loader from "../../Components/Loader";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FormRowTextarea from "../../Components/FormRowTextarea";
import PreviewImage from "../../Components/PreviewImage";
import { useEffect, useState } from "react";
const EditQuestion = () => {
  const { exam_id, question_id } = useParams();
  const navigate = useNavigate();

  let questionValues = {};
  console.log("ids", exam_id, question_id);
  const difficuilty_list = ["Easy", "Medium", "Hard"];

  const { mutate } = usePostMutation();
  const getSystems = async () => {
    const { data } = await apiClient.get("/systems");
    return data;
  };
  const getSubjects = async () => {
    const { data } = await apiClient.get("/subjects");
    return data;
  };
  const getQuestion = async () => {
    const { data } = await apiClient.get(
      `/questions/${exam_id}/${question_id}`
    );
    return data;
  };

  const getAllSystems = useQuery(["allSystems"], getSystems);
  const getAllSubjects = useQuery(["allSubjects"], getSubjects);
  const question = useQuery(["question"], getQuestion);
  useEffect(() => {
    question.refetch();
  }, [question_id]);
  if (getAllSystems.isLoading && getAllSubjects.isLoading) {
    return <Loader />;
  }
  if (getAllSystems.isError && getAllSubjects.isError) {
    return <h1>Error while fetching data</h1>;
  }
  if (
    getAllSystems.isSuccess &&
    getAllSubjects.isSuccess &&
    question.isSuccess
  ) {
    questionValues = { ...question.data.data.question };
    return (
      <div className="form-wrapper">
        <Formik
          enableReinitialize
          initialValues={questionValues}
          onSubmit={(values, props) => {
            console.log(values);
            const {
              title,
              choices,
              correct_answer,
              difficulty,
              question_image,
              answer_image,
              answer_explanation,
              system_id,
              subject_id,
              exam_id
            } = values;

            const formData = new FormData();
            formData.append("title", title);
            formData.append("answer_choices", choices);
            formData.append("correct_answer", correct_answer);
            formData.append("difficulty", difficulty);
            formData.append("question_image", question_image);
            formData.append("answer_images", answer_image);
            formData.append("answer_explanation", answer_explanation);
            formData.append("subject_id", subject_id);
            formData.append("system_id", system_id);
            formData.append("exam_id", exam_id);

            mutate(
              {
                method: "patch",
                url: `/questions/${exam_id}/${question_id}`,
                values: formData,
                head: {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                }
              },
              {
                onSuccess: (res) => {
                  toast.success("Question updated successfully");
                  props.setSubmitting();
                  navigate(-1);
                },
                onError: (err) => {
                  //alert("Error");
                  props.setSubmitting(false);
                  console.log(err);
                }
              }
            );
          }}
        >
          {(formik) => (
            <Form
              encType="multipart/form-data"
              className="dashboard-form"
              onSubmit={formik.handleSubmit}
            >
              {console.log("formik values", formik.values)}
              <FormHeader text="Edit Question" />
              <FormRowTextarea
                label="Question Context"
                placeholder="Please enter title of question"
                id="title"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
                totalRows={10}
                totalCols={10}
              />
              <h5 className="mt-20">Create Choices</h5>
              <FieldArray
                name="choices"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      {formik.values.choices.map((choice, index) => {
                        return (
                          <FormRowInput
                            key={`choices.${index}`}
                            name={`choices.${index}`}
                            label={`choice ${index + 1}`}
                            placeholder="Please enter choices"
                            id="choices"
                            onChange={formik.handleChange}
                            value={formik.values.choices[`${index}`]}
                          />
                        );
                      })}
                      <Button
                        text="Add"
                        type="button"
                        handleSubmit={(value) => {
                          arrayHelpers.insert(
                            formik.values.choices.length + 1,
                            ""
                          );
                        }}
                      />
                    </div>
                  );
                }}
              />
              <div className="selection_area">
                <FormRowSelect
                  id="correct_answer"
                  name="correct_answer"
                  label="Choice #"
                  list={formik.values.choices}
                />
              </div>
              <div className="image-previewer">
                <FormRowInput
                  label="Question Image"
                  placeholder="Please enter title of question"
                  id="question_image"
                  type="file"
                  name="question_image"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "question_image",
                      event.currentTarget.files[0]
                    );
                  }}
                />
                {formik.values.question_image !== typeof file ? (
                  <img
                    src={`https://www.medgrizz.com/questionAnswerImages/${formik.values.question_image}`}
                    // src={`http://localhost:4000/questionAnswerImages/${formik.values.question_image}`}
                    alt="question"
                  />
                ) : null}
              </div>

              <FormRowTextarea
                label="Answer's Explanation"
                placeholder="Please enter explanation of answer"
                id="answer_explanation"
                name="answer_explanation"
                onChange={formik.handleChange}
                value={formik.values.answer_explanation}
                totalCols={10}
                totalRows={10}
              />
              <div className="image-previewer">
                <FormRowInput
                  label="Answer Image"
                  placeholder="Select answer image"
                  id="answer_image"
                  type="file"
                  name="answer_image"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "answer_image",
                      event.currentTarget.files[0]
                    );
                  }}
                />
                {formik.values.answer_image !== typeof file ? (
                  <img
                    src={`https://www.medgrizz.com/questionAnswerImages/${formik.values.answer_image}`}
                    //src={`http://localhost:4000/questionAnswerImages/${formik.values.answer_image}`}
                    alt="answer"
                  />
                ) : null}
              </div>
              <div className="selection_area">
                <FormRowSelect
                  id="difficulty"
                  label="Select Difficulty"
                  list={difficuilty_list}
                  name="difficulty"
                />

                <FormRowSelect
                  id="subject_id"
                  label="Subject"
                  list={getAllSubjects.data.data.subjects}
                  name="subject_id"
                  isApiData="true"
                />
                <FormRowSelect
                  id="system_id"
                  label="System"
                  list={getAllSystems.data.data.systems}
                  name="system_id"
                  isApiData="true"
                />
              </div>
              <Button
                text={formik.isSubmitting ? "Loading ..." : "Update "}
                type="submit"
                isDisable={formik.isSubmitting}
                handleSubmit={formik.handleSubmit}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
};

export default EditQuestion;
