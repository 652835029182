import usePostMutation from "../Utils/usePostMutation";
import "../assets/scss/PricingPlansCard.scss";
import YellowBtn from "./YellowBtn";
import { useNavigate, useLocation } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import moment from "moment";

const PricingPlanCard = ({
  isOurPlan = false,
  examId,
  examName,
  examType,
  planId,
  planName,
  planPrice,
  description,
  duration,
  plan_length,
  isLoggedIn,
  stripePriceId,
  bgColorclass = "",
  status = false,
  subscribedPlans = false,
  endDate
}) => {
  const navigate = useNavigate();
  const { mutate } = usePostMutation();
  console.log("plan Price Type", typeof planPrice);
  const buySubscriptionPlan = async (customer_id) => {
    let values = {
      priceId: stripePriceId
    };
    mutate(
      {
        url: "/stripe/create-subscription",
        values: values
      },
      {
        onSuccess: (res) => {
          //alert("Form submitted successfully");
          console.log("Buy Subscription Plan api successfull", res);
          //Here we make a checkout url called
        },
        onError: (response) => {
          // alert("An error occured while submitting exam");
          console.log("Buy Subscription Plan api failed");
        }
      }
    );
  };
  const getCheckoutUrl = () => {
    navigate("/checkout", {
      state: {
        planId,
        planName,
        planPrice,
        description,
        examId,
        examName,
        examType,
        duration,
        plan_length,
        priceId: stripePriceId
      }
    });
  };
  const goToPracticeQuestion = () => {
    console.log("sending examId", examId);
    localStorage.setItem(
      "exam",
      JSON.stringify({
        _id: examId,
        name: examName
      })
    );
    navigate("/practice_questions", {
      state: {
        examId,
        examName,
        examType
      }
    });
  };
  const goToAccessPlan = () => {
    // history.push(`/$planName.toLowerCase())
    const name = planName.toLowerCase();
    console.log("Plan Route name", name);
    navigate(`/${name}`);
  };
  console.log("End date", endDate);
  return (
    <div
      className={
        subscribedPlans
          ? `pricing-card pricing-card-extended ${bgColorclass}`
          : `pricing-card ${bgColorclass}`
      }
    >
      <div className={subscribedPlans ? "content content-extended" : "content"}>
        <div className="examTitle">{isOurPlan ? examName : null}</div>
        <div className="title">{planName ? planName : "Plan Name"}</div>
        <div className="description">
          {description ? description : "Plan Description"}
        </div>
        {status ? <div className="description">Status: Active</div> : null}
        {subscribedPlans ? <div className="description">End Date: </div> : null}
      </div>
      <YellowBtn
        text={typeof planPrice !== "string" ? `${"$" + planPrice} ` : planPrice}
        classes="full-width"
        handleSubmit={() => {
          return !isLoggedIn
            ? navigate("/login", {
                state: {
                  isFromCheckout: true
                }
              })
            : typeof planPrice === "string"
            ? goToPracticeQuestion()
            : getCheckoutUrl();
        }}
      />
    </div>
  );
};

export default PricingPlanCard;
