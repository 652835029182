import MyCustomSidebar from "../../Components/Admin/MySidebar/MyCustomSidebar";
import AdminHeader from "../../Components/Admin/AdminHeader";
import "../../assets/scss/Wrapper.scss";
import FormHeader from "../../Components/FormHeader";
import FormRowInput from "../../Components/FormRowInput";
import Button from "../../Components/Button";
import usePostMutation from "../../Utils/usePostMutation";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const AddSystem = () => {
  const { mutate } = usePostMutation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, props) => {
      console.log(values);
      mutate(
        {
          url: "/systems",
          values: values,
        },
        {
          onSuccess: () => {
            //alert("System added successfully");
            toast.success("System added successfully");
            props.setSubmitting(false);
            //handleOpen();
            // navigate("/dashboard/system/all");
          },
          onError: (response) => {
            // alert("An error occured during submitting system");
            toast.error("An error occured adding system");
            props.setSubmitting(false);
            console.log(response);
          },
        }
      );
    },
  });

  return (
    <div className="form-wrapper">
      <div className="dashboard-form">
        <FormHeader text="System" />
        <Toaster />
        <form onSubmit={formik.handleSubmit}>
          <FormRowInput
            label="Name"
            placeholder="Please enter name of system"
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <Button
            text={formik.isSubmitting ? "Please wait ..." : "Add System"}
            type="submit"
            isDisable={formik.isSubmitting}
            handleSubmit={formik.handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};

export default AddSystem;
