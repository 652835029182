import React, { useEffect, useState } from "react";
import AdminHeader from "../Components/Admin/AdminHeader";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import apiClient from "../Utils/apiClient";
import Loader from "../Components/Loader";
import { useQuery } from "react-query";
import moment from "moment";
import { Link } from "react-router-dom";
import "../assets/scss/Wrapper.scss";
const ReviewCenterDetail = () => {
  const location = useLocation();
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const values = location.state;
  console.log("Our Review Center Detail Parameters", values);
  const [reviewQuestions, setReviewQuestions] = useState([{}]);
  const getReviewCenterDetailApi = async () => {
    const { data } = await apiClient.get(`/review/single/${values.review_id}`);

    return data;
  };

  const getReview = useQuery(["Review"], getReviewCenterDetailApi, {
    //refetchInterval: Infinity,
    // refetchOnMount: true,
    // refetchOnWindowFocus: true,
    // refetchIntervalInBackground: true,
    onSuccess: (res) => {
      console.log("useQuery on success", res.data.review);
      setReviewQuestions(res.data.review);
    }
  });
  useEffect(() => {
    getReview.refetch();
  }, [values]);
  if (getReview.isLoading) {
    return <Loader />;
  }
  if (getReview.isError) {
    <div className="dashboard-wrapper">
      <Sidebar examName={exam.name} />
      <div className="admin-nested">
        <AdminHeader />
        <div className="review-center-detail">
          <div className="review-center-detail-top">
            <h1>No Data Found</h1>
          </div>
        </div>
      </div>
    </div>;
  }
  if (getReview.isSuccess) {
    return (
      <>
        <div className="dashboard-wrapper">
          <Sidebar examName={exam.name} />
          <div className="admin-nested">
            <AdminHeader />
            <div className="review-center-detail">
              <div className="review-center-detail-top">
                <h1>Details</h1>
                <h4>Exam : {getReview.data.data.review.examName}</h4>
                <h4>
                  Date Taken :{" "}
                  {moment(getReview.data.data.review.createdAt).format(
                    "Do MMMM YYYY"
                  )}
                </h4>
                <h4>Type : {getReview.data.data.review.examType}</h4>
                <h4>
                  Raw Score : {getReview.data.data.review.total_correct_answers}
                  /{getReview.data.data.review.total_questions}
                </h4>
                <h4>
                  Percentage Score :{" "}
                  {Math.round(
                    (getReview.data.data.review.total_correct_answers /
                      getReview.data.data.review.total_questions) *
                      100
                  )}
                  %
                </h4>
                <h4>
                  Review :{" "}
                  <Link to={`/review_center/${getReview.data.data.review._id}`}>
                    Review Your Exam
                  </Link>
                </h4>
              </div>
              <div className="review-center-detail-questions-list">
                <h1>Question List</h1>
                <div className="questionsList">
                  {getReview.data.data.review?.examData.map((q, index) => {
                    return (
                      <div
                        className={
                          q.userAnswer !== "" && q.isFlagged !== false
                            ? "question_no question_no_answered question_no_flagged"
                            : q.userAnswer !== ""
                            ? "question_no question_no_answered"
                            : q.isFlagged !== false
                            ? "question_no question_no_flagged "
                            : q.userAnswer === ""
                            ? "question_no question_no_not_answered"
                            : "question_no"
                        }
                        key={index}
                      >
                        {index + 1}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ReviewCenterDetail;
