import "../../assets/scss/Wrapper.scss";
import FormHeader from "../../Components/FormHeader";
import FormRowInput from "../../Components/FormRowInput";
import Button from "../../Components/Button";
import { useFormik } from "formik";
import usePostMutation from "../../Utils/usePostMutation";
import toast, { Toaster } from "react-hot-toast";

const AddSubject = () => {
  const { mutate } = usePostMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, props) => {
      console.log(values);

      console.log("Button clicked");
      console.log("values", values);
      console.log("props ", props);
      mutate(
        { url: "/subjects", values: values },
        {
          onSuccess: (res) => {
            console.log(res);

            toast.success("Subject added successfully");
            props.setSubmitting(false);
          },
          onError: (response) => {
            toast.error("An error occured while adding new Subject");
            props.setSubmitting(false);
            console.log(response);
          },
        }
      );
    },
  });

  return (
    <>
      <div className="form-wrapper">
        <div className="dashboard-form">
          <FormHeader text="Add Subject" />
          <form onSubmit={formik.handleSubmit}>
            <FormRowInput
              label="Name"
              placeholder="Please enter name of subject"
              id="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <Button
              text={formik.isSubmitting ? "Loading ..." : "Add Subject"}
              type="submit"
              isDisable={formik.isSubmitting}
              handleSubmit={formik.handleSubmit}
            />
          </form>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default AddSubject;
