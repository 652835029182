import MyCustomSidebar from "../../Components/Admin/MySidebar/MyCustomSidebar";
import AdminHeader from "../../Components/Admin/AdminHeader";
import "../../assets/scss/Wrapper.scss";
import DeleteAction from "../../Components/DeleteAction";
import EditAction from "../../Components/EditAction";
import MyActionButton from "../../Components/MyActionButton";
import { useEffect, useState } from "react";
import CustomModel from "../../Components/CustomModel";
import AddUser from "./AddUser";
import ButtonAction from "../../Components/ButtonAction";
import FormRowSelect from "../../Components/FormRowSelect";
import MyActionLink from "../../Components/MyActionLink";
import TableActionLink from "../../Components/TableActionLink";
import { Formik, Form } from "formik";
import apiClient from "../../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";
import usePostMutation from "../../Utils/usePostMutation";
import toast, { Toaster } from "react-hot-toast";
import Button from "../../Components/Button";
import DeletionModal from "../../Components/DeletionModal";
import useUserData from "../../Helpers/useUserData";
const AllUsers = () => {
  const { mutate } = usePostMutation();
  const plan_list = ["plan 1", "plan 2"];
  const date_list = ["sort"];
  const exam_list = ["Exam 1", "Exam 2"];
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isDeletedModalOpen, setISDeletedModalOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1000);

  const handleOpen = () => {
    console.log(`click on Add user`);
    setIsOpen(!isOpen);
  };
  const getAllExams = async () => {
    const { data } = await apiClient.get("/exams");
    return data;
  };
  const allExams = useQuery(["adminexamsonUser"], getAllExams, {
    retry: 1,
    onSuccess: (res) => {
      console.log("response of exam on success", res);
    },
    onError: (res) => {
      console.log("response of exam on error", res);
    }
  });
  // const getUsers = async () => {
  //   const { data } = await apiClient.get("/users");
  //   return data;
  // };

  // const getAllUsers = useQuery(["users"], getUsers, {
  //   refetchOnWindowFocus: false
  // });
  const getAllUsers = useUserData(filters);
  useEffect(() => {
    getAllUsers.refetch();
  }, [isOpen, filters]);
  const handleDelete = (id) => {
    mutate(
      {
        method: "delete",
        url: `users/${id}`,
        values: null
      },
      {
        onSuccess: (res) => {
          toast.success("User deleted successfully");
          getAllUsers.refetch();
        },
        onError: (response) => {
          toast.success("An error occurred while deleting user");
          console.log(response);
        }
      }
    );
  };
  if (getAllUsers.isFetching && allExams.isFetching) {
    return <Loader />;
  }
  if (getAllUsers.isError) {
    return (
      <div className="dashboard-wrapper">
        <MyCustomSidebar />
        <div className="admin-nested">
          {/* <AdminHeader /> */}
          {/** This is inner page */}
          <div className="form-wrapper">
            <div className="dashboard-table">
              <div className="dashboard-table__header">
                <h1>All Users</h1>
                <div className="action_btns">
                  {isOpen ? (
                    <CustomModel
                      component={<AddUser />}
                      className="show"
                      handleSubmit={handleOpen}
                    />
                  ) : (
                    <ButtonAction text="Add New +" handleSubmit={handleOpen} />
                  )}
                  <MyActionLink
                    text="Edit Bulk"
                    redirect="/dashboard/user/editBulk"
                  />
                  <MyActionLink
                    text="Add Bulk"
                    redirect="/dashboard/user/addBulk"
                  />
                </div>
              </div>

              <div className="sorting_wrapper">
                <div className="sorted_actions_wrapper">
                  <h3>Sort By:</h3>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      exam_id: "",
                      plan_id: "",
                      date: ""
                    }}
                    onSubmit={(values) => {
                      console.log("values", values);
                    }}
                  >
                    {(formik) => (
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="sorted_actions">
                          <FormRowSelect
                            name="exam_id"
                            id="exam_id"
                            isApiData={true}
                            label="Exam"
                            list={exam_list}
                          />
                          <FormRowSelect
                            name="plan_id"
                            id="plan_id"
                            isApiData={true}
                            label="Plan"
                            list={plan_list}
                          />
                          <FormRowSelect
                            label="Sort By"
                            name="date"
                            id="date"
                            list={date_list}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <h1>We could not found any user</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (getAllUsers.isSuccess && allExams.isSuccess) {
    console.log("All users", getAllUsers);
    console.log("All Exams", allExams);
    return (
      <div className="dashboard-wrapper">
        <MyCustomSidebar />
        <div className="admin-nested">
          {/* <AdminHeader /> */}
          <Toaster />
          {/** This is inner page */}
          <div className="form-wrapper">
            <div className="dashboard-table">
              <div className="dashboard-table__header">
                <h1>All Users</h1>
                <div className="action_btns">
                  {isOpen ? (
                    <CustomModel
                      component={<AddUser />}
                      className="show"
                      handleSubmit={handleOpen}
                    />
                  ) : (
                    <ButtonAction text="Add New +" handleSubmit={handleOpen} />
                  )}
                  <MyActionLink
                    text="Edit Bulk"
                    redirect="/dashboard/user/editBulk"
                  />
                  <MyActionLink
                    text="Add Bulk"
                    redirect="/dashboard/user/addBulk"
                  />
                </div>
              </div>
              <div className="sorting_wrapper">
                <div className="sorted_actions_wrapper">
                  <h3>Sort By:</h3>

                  <Formik
                    enableReinitialize
                    initialValues={{
                      // exam_id: "",
                      // plan_id: "",
                      date: ""
                    }}
                    onSubmit={(values) => {
                      console.log("Our filters on all users", values);
                      setFilters({ ...values });
                    }}
                  >
                    {(formik) => (
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="sorted_actions">
                          {/* <FormRowSelect
                            name="exam_id"
                            id="exam_id"
                            isApiData={true}
                            label="Exam"
                            list={allExams.data.data.exams}
                          /> */}
                          {/* <FormRowSelect
                            name="plan_id"
                            id="plan_id"
                            isApiData={true}
                            label="Plan"
                            list={plan_list}
                          /> */}
                          <FormRowSelect
                            label="Sort By"
                            name="date"
                            id="date"
                            list={date_list}
                          />
                          <div className="">
                            <Button type="submit" text="Search" />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <table id="customers">
                <thead>
                  <tr>
                    <th>User Name</th>

                    <th>Email</th>
                    <th>Register Date</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllUsers.data.data.users.map((user, index) => {
                    return (
                      <tr key={user._id}>
                        <td>{user.user_name}</td>

                        <td>{user.email}</td>
                        <td>{user.createdAt}</td>

                        <td className="actions">
                          <Link to={`/dashboard/user/edit/${user._id}`}>
                            <MyActionButton icon={<EditAction />} text="Edit" />
                          </Link>
                          {openIndex === index ? (
                            <DeletionModal
                              title={user.full_name}
                              del={() => handleDelete(user._id)}
                              hide={() => setOpenIndex(-99999)}
                            />
                          ) : (
                            <MyActionButton
                              icon={<DeleteAction />}
                              text="Delete"
                              handleSubmit={() => {
                                setOpenIndex(index);
                                setISDeletedModalOpen(!isDeletedModalOpen);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AllUsers;
