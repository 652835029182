import React from "react";

const CheckOutCancelled = () => {
  return (
    <div>
      <h1>Checkout Cancelled</h1>
    </div>
  );
};

export default CheckOutCancelled;
