import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/Footeer.scss";

import facebook from "../assets/images/facebook.jpg";
import insta from "../assets/images/instagram.jpg";
import twitter from "../assets/images/twitter.jpg";
const Footer = ({ isSmall = false }) => {
  return (
    <div
      className="footer-wrapper"
      style={isSmall === true ? { width: "100%" } : null}
    >
      <div className="footer-card">
        <a
          href="https://www.facebook.com/profile.php?id=61550276706560"
          className="socialContainer"
        >
          <img src={facebook} alt="" />
        </a>

        <a
          href="https://www.instagram.com/medgrizz/"
          className="socialContainer "
        >
          <img src={insta} alt="" />
        </a>

        <a href="https://twitter.com/medgrizz" className="socialContainer ">
          <img src={twitter} alt="" />
        </a>
      </div>
      <div className="reserved">
        <p>&copy; 2023 MedGrizz. All rights reserved.</p>
      </div>
      <div className="footer-link">
        <div className="left">
          <Link to="/terms_and_services">Terms of Service</Link>
        </div>
        <div className="right">
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
