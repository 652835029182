import "../assets/scss/YellowBtn.scss";

const YellowBtn = ({
  text = "button",
  className,
  handleSubmit,
  type,
  isDisable,
  btnState,
  classes,
}) => {
  return (
    <button
      type={type}
      className={`Yellow-btn ${classes}`}
      onClick={handleSubmit}
      disabled={isDisable}
    >
      {text}
    </button>
  );
};

export default YellowBtn;
