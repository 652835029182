import { useQuery } from "react-query";
import apiClient from "../Utils/apiClient";
import { useCallback } from "react";

const useUserData = (filters) => {
  let myUsers = useCallback(
    (users) => {
      console.log("users", users);
      console.log("filters", filters);
      //   console.log("filter system_id", filters.system_id);
      //   console.log("filters obj", filters);
      if (Object.keys(filters).length === 0) return users;
      let data;
      //   if (filters.date === "" || filters.resolved !== "") {
      //     data = users.data.users.filter((r) => {
      //       // console.log("single report", r.question_id.system_id._id);
      //       if (
      //         filters.title &&
      //         filters.system_id === "" &&
      //         filters.subject_id === "" &&
      //         filters.date === ""
      //       ) {
      //         return r.title === filters.title;
      //       }
      //       if (
      //         filters.title === "" &&
      //         filters.system_id &&
      //         filters.subject_id === ""
      //       ) {
      //         return r.question_id.system_id._id === filters.system_id;
      //       }
      //       if (
      //         filters.title === "" &&
      //         filters.system_id === "" &&
      //         filters.subject_id
      //       ) {
      //         return r.question_id.subject_id._id === filters.subject_id;
      //       }

      //       if (filters.title && filters.system_id && filters.subject_id === "") {
      //         return (
      //           r.question_id.system_id._id === filters.system_id &&
      //           r.title === filters.title
      //         );
      //       }
      //       if (filters.title && filters.system_id === "" && filters.subject_id) {
      //         return (
      //           r.question_id.subject_id._id === filters.subject_id &&
      //           r.title === filters.title
      //         );
      //       }
      //       if (filters.title === "" && filters.system_id && filters.subject_id) {
      //         return (
      //           r.question_id.subject_id._id === filters.subject_id &&
      //           r.question_id.system_id._id === filters.system_id
      //         );
      //       }
      //       if (filters.title && filters.system_id && filters.subject_id) {
      //         return (
      //           r.title === filters.title &&
      //           r.question_id.subject_id._id === filters.subject_id &&
      //           r.question_id.system_id._id === filters.system_id
      //         );
      //       }
      //       if (filters.resolved !== "") {
      //         if (filters.resolved === "resolved") {
      //           return r.resolved === true;
      //         }
      //         if (filters.resolved !== "resolved") {
      //           return r.resolved === false;
      //         }
      //       }
      //     });
      //   } else {
      if (filters.date !== "") {
        data = users.data.users.reverse();
      }
      // else {
      //   data = reports.data.reports;
      // }
      //   }

      return {
        status: "success",
        data: {
          users: [...data]
        }
      };
    },
    [filters]
  );
  return useQuery(
    ["users", filters],
    async () => {
      const { data } = await apiClient.get("/users");
      console.log("use User Data ", data);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      select: myUsers
    }
  );
};

export default useUserData;
