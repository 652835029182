import "../../../assets/scss/MyCustomSidebar.scss";
import { useState } from "react";
import { CiMemoPad } from "react-icons/ci";
import { BiUser } from "react-icons/bi";
import { GiPriceTag } from "react-icons/gi";
import { FaHandsHelping } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GiTransparentTubes } from "react-icons/gi";
import { FaRegEdit } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BiUpArrow } from "react-icons/bi";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import logo from "../../../assets/images/Madgrizz_logo_blue.jpeg";
import { SlUser } from "react-icons/sl";
const Sidebar = ({ examName = "Exam Name" }) => {
  const navigate = useNavigate();
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isOpenLogoutMenu, setisOpenLogoutMenu] = useState(false);
  //   console.log("stored user is", userStored.profile_image);
  return (
    <div className="admin-main-sidebar">
      <nav className="admin-main-sidebar__nav">
        <div className="nav_logo">
          <img
            src={logo}
            alt="our medgrizz logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="main-heading-of-sidebar">
          <h4>{examName}</h4>
        </div>
        <ul className="menu">
          <li>
            <Link to="/practice_questions">
              <GiTransparentTubes size="2rem" className="icon" />
              <span>Practice Question</span>
            </Link>
          </li>

          <li>
            <Link to="/mock_tests">
              <FaRegEdit size="2rem" className="icon" />
              <span>Mock Test</span>
            </Link>
          </li>
          <li>
            <Link to="/performance">
              <BsGraphUpArrow size="2rem" className="icon" />
              <span>Performance Analysis</span>
            </Link>
          </li>
          <li>
            <Link to="/review_center">
              <BiTimeFive size="2rem" className="icon" />
              <span>Review Center</span>
            </Link>
          </li>
          <li>
            <Link to="/help_and_support">
              <AiOutlineQuestionCircle size="2rem" className="icon" />
              <span>Help and Support</span>
            </Link>
          </li>

          <Outlet />
        </ul>
      </nav>
      <div
        className="user-section"
        onMouseLeave={() => setisOpenLogoutMenu(!isOpenLogoutMenu)}
      >
        {userStored ? (
          userStored?.profile_image ? (
            <img
              // src={`http://localhost:4000/userImages/${userStored.profile_image}`}
              src={`https://www.medgrizz.com/userImages/${userStored.profile_image}`}
              alt="user"
            />
          ) : userStored?.google_image ? (
            <img
              src={`${userStored.google_image}`}
              // src={`https://www.medgrizz.com/userImages/${userStored.profile_image}`}
              alt="user"
            />
          ) : (
            <SlUser size="1.5em" />
          )
        ) : null}
        <h6>{userStored ? userStored?.user_name : "user"}</h6>
        <MdOutlineKeyboardArrowUp
          size="2em"
          className="myIcon"
          onClick={() => setisOpenLogoutMenu(!isOpenLogoutMenu)}
        />
        <div
          className={isOpenLogoutMenu ? "arrow-section show" : "arrow-section"}
        >
          <ul>
            <li
              onClick={() => {
                navigate("/profile");
              }}
            >
              Profile
            </li>
            <li onClick={() => navigate("/purchased_plans")}>
              Subscribed Exams
            </li>
            <li
              onClick={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("token");

                navigate("/");
              }}
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
