import React from "react";
import "../assets/scss/Search.scss";
const Search = ({ placeholder = "search", handleChange }) => {
  return (
    <div>
      <input
        class="search-input"
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
      ></input>
    </div>
  );
};

export default Search;
