import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import AdminHeader from "../Components/Admin/AdminHeader";
import TopMenu from "../Components/TopMenu";
import "../assets/scss/Wrapper.scss";
import FormRowInput from "../Components/FormRowInput";
import usePostMutation from "../Utils/usePostMutation";
import { useFormik, Formik, FieldArray, Form, useFormikContext } from "formik";
import CheckBox from "../Components/CheckBox";
import Button from "../Components/Button";
import { Link } from "react-router-dom";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../Components/Loader";
import FormRowSelect from "../Components/FormRowSelect";
import { useNavigate, useLocation } from "react-router-dom";
import { CiGlass, CiLight } from "react-icons/ci";
import Search from "../Components/Search";

const PracticeQuestions = () => {
  const { mutate } = usePostMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));

  const [isExamError, setIsExamError] = useState(false);
  const [isSubjectSelectAll, setIsSubjectSelectAll] = useState(false);
  const [isSubjectStateChange, setisSubjectStateChange] = useState(0);
  const [isPartiallySelectedSubjects, setIsPartiallySelectedSubjects] =
    useState(false);
  const [isPartiallySelectedSystems, setIsPartiallySelectedSystems] =
    useState(false);
  const [isSystemSelectAll, setIsSystemSelectAll] = useState(false);
  const [searchSubject, setSearchSubject] = useState("");
  const [searchSystem, setSearchSystem] = useState("");
  // const exam = location.state;

  const question_types = ["Unused", "Incorrect", "Correct"];

  const question_difficulty = ["Easy", "Medium", "Hard"];

  const getAllSubjects = async () => {
    const { data } = await apiClient("/subjects");
    return data.data;
  };

  const allSubjects = useQuery(["allSubjects"], getAllSubjects, {
    refetchInterval: Infinity
  });
  const getAllExams = async () => {
    const { data } = await apiClient("/exams");
    return data.data;
  };

  const allExams = useQuery(["allExams"], getAllExams, {
    refetchInterval: Infinity
  });
  const getAllSystems = async () => {
    const { data } = await apiClient.get("/systems");
    return data;
  };

  const allSystems = useQuery(["allSystems"], getAllSystems);
  useEffect(() => {}, [isSubjectStateChange]);

  const searchSubjectHandler = (val) => {
    console.log("Search Subject", val);
    setSearchSubject(val);
  };
  const searchSystemHandler = (val) => {
    console.log("Search System", val);
    setSearchSystem(val);
  };

  if (allSystems.isLoading && allSubjects.isLoading & allExams.isSuccess) {
    return <Loader />;
  }

  if (allSubjects.isSuccess && allSystems.isSuccess && allExams.isSuccess) {
    //console.log("Exams", allSubjects.data.subjects);
    console.log("Exams", allExams);

    return (
      <>
        <Toaster />

        <div className="dashboard-wrapper">
          <Sidebar examName={exam.name} />
          <div className="admin-nested">
            {/* <AdminHeader /> */}
            <h1 className="admin-nested-heading">Practice Questions</h1>

            <Formik
              //enableReinitialize
              initialValues={{
                exam: exam ? exam : "",

                time: "",
                no_of_questions: "",
                type: [],
                difficulty: [],
                system_id: [],
                subject_id: []
              }}
              onSubmit={(values, props) => {
                console.log("values of Practice Question", values);
                //console.log("Exam value", JSON.parse(values.exam));
                if (values.exam !== "") {
                  let newSubjectIds = [];
                  values.subject_id.filter((sub) => {
                    if (sub.isCheck) {
                      newSubjectIds.push(sub.id);
                    }
                  });
                  console.log("Subject ids", newSubjectIds);
                  values.subject_id = newSubjectIds;
                  console.log("Updated values", values.subject_id);
                  let newSystemIds = [];
                  values.system_id.filter((sys) => {
                    if (sys.isCheck) {
                      newSystemIds.push(sys.id);
                    }
                  });
                  console.log("System ids", newSystemIds);
                  values.system_id = newSystemIds;
                  console.log("Updated values", values.subject_id);
                  console.log("Values final", values);
                  navigate("/start_practice_questions", {
                    state: values
                  });
                  props.setSubmitting(false);
                } else {
                  console.log("Error occures");

                  setIsExamError(true);
                  props.setSubmitting(false);
                }
              }}
            >
              {(formik) => (
                <div className="dashboard-cards-wrapper">
                  {/**  <div className="dashboard-cards-wrapper__header">
                  <h2 className="heading">Session Customization</h2>
                </div>*/}

                  <div className="cardDesigns">
                    <div className="dashboard-table__header">
                      <h1>Session Customization</h1>
                    </div>
                    <div className="session-customization-wrapper">
                      {/*!exam ? (
                        <div className="session-customization-wrapper__inputboxes">
                          <FormRowSelect
                            id="exam"
                            label="Exam"
                            list={userStored ? allExams.data?.exams : [""]}
                            name="exam"
                            isApiData={true}
                            isRequired={true}
                            isError={isExamError}
                          />
                        </div>
                      ) : null*/}

                      <div className="session-customization-wrapper__inputboxes">
                        <div className="left">
                          <FormRowInput
                            label="Number of Questions"
                            name="no_of_questions"
                            id="no_of_questions"
                            placeholder="Enter in numbers e.g. 1 or 2 etc"
                            value={formik.values.no_of_questions}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="right">
                          <FormRowInput
                            name="time"
                            id="time"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                            placeholder="Enter time in minutes"
                            label="time"
                            containerClasses="full"
                            classes="full"
                          />
                        </div>
                      </div>
                      <div className="session-customization-wrapper__checkBoxes">
                        <div className="left">
                          <h3>Question Type</h3>
                        </div>
                        <div className="right">
                          {question_types.map((type, index) => {
                            return (
                              <CheckBox
                                text={type}
                                value={type}
                                key={index}
                                handleChange={(e) => {
                                  console.log("Event", e);
                                  console.log(
                                    "Question type checkbox value",
                                    e.target.value
                                  );
                                  if (e.target.checked) {
                                    console.log(
                                      "Checked value is",
                                      e.target.value
                                    );
                                    formik.values.type.push(e.target.value);
                                  } else {
                                    console.log(
                                      "UnChecked value is",
                                      e.target.value
                                    );
                                    const index = formik.values.type.indexOf(
                                      e.target.value
                                    );

                                    formik.values.type.splice(index, 1);
                                  }
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="session-customization-wrapper__checkBoxes">
                        <div className="left">
                          <h3>Question Difficulty</h3>
                        </div>
                        <div className="right">
                          {question_difficulty.map((difficulty, index) => {
                            return (
                              <CheckBox
                                text={difficulty}
                                value={difficulty}
                                key={index}
                                handleChange={(e) => {
                                  console.log(
                                    "difficulty checkbox value",
                                    e.target.value
                                  );

                                  if (e.target.checked) {
                                    console.log(
                                      "Checked value is",
                                      e.target.value
                                    );
                                    formik.values.difficulty.push(
                                      e.target.value
                                    );
                                  } else {
                                    console.log(
                                      "UnChecked value is",
                                      e.target.value
                                    );
                                    const index =
                                      formik.values.difficulty.indexOf(
                                        e.target.value
                                      );

                                    formik.values.difficulty.splice(index, 1);
                                  }
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cardDesigns">
                    <div className="dashboard-table__header">
                      <h1>Subject Area</h1>
                    </div>
                    <div className="customize-search">
                      <CheckBox
                        //text={type}
                        text="Select All"
                        //value={type}
                        value="Select All"
                        isDefaultChecked={isSubjectSelectAll}
                        //key={index}
                        handleChange={(e) => {
                          console.log("Event", e);
                          console.log(
                            "Question type checkbox value",
                            e.target.value
                          );
                          if (e.target.checked) {
                            console.log(
                              "Checked value of subject selectall",
                              e.target.value
                            );
                            setIsSubjectSelectAll(true);
                            let myAllTempSubjectsArray = [];
                            allSubjects.data.subjects.map((s, i) => {
                              let myObj = {
                                id: s._id,
                                name: s.name,
                                isCheck: true
                              };
                              myAllTempSubjectsArray.push(myObj);
                            });

                            if (formik.values.subject_id.length === 0) {
                              formik.values.subject_id = myAllTempSubjectsArray;
                            } else {
                              console.log("Array has some values");
                              formik.values.subject_id = [];
                              formik.values.subject_id = myAllTempSubjectsArray;
                              setisSubjectStateChange((prev) => prev + 1);
                            }
                          } else {
                            console.log(
                              "UnChecked value subjects selected all",
                              e.target.value
                            );
                            setIsSubjectSelectAll(false);
                            formik.values.subject_id = [];

                            //setIsPartiallySelectedSubjects(true);
                          }
                        }}
                      />
                      <Search
                        placeholder="search subject"
                        handleChange={searchSubjectHandler}
                      />
                    </div>
                    <div className="session-customization-wrapper">
                      <div className="session-customization-wrapper__subject">
                        {isSubjectSelectAll
                          ? allSubjects.data.subjects
                              .filter((sub) => {
                                return searchSubject.toLowerCase() === ""
                                  ? sub
                                  : sub.name
                                      // .toLowerCase()
                                      .includes(searchSubject);
                              })
                              .map((sub) => (
                                <CheckBox
                                  text={sub.name}
                                  key={sub._id}
                                  value={`${sub.name}-${sub._id}`}
                                  isDefaultChecked={isSubjectSelectAll}
                                  handleChange={(e) => {
                                    //setIsSubjectSelectAll(false);

                                    if (e.target.checked) {
                                      console.log(
                                        "Checked value is",
                                        e.target.value
                                      );
                                      // allSubjects.data.subjects.map((s) => {
                                      //   formik.values.subject_id.push({
                                      //     id: s._id,
                                      //     name: s.name
                                      //   });
                                      // });
                                    } else {
                                      setisSubjectStateChange(
                                        (prev) => prev + 1
                                      );
                                      console.log(
                                        "UnChecked value is",
                                        e.target.value
                                      );
                                      if (
                                        isSubjectSelectAll &&
                                        !isPartiallySelectedSubjects
                                      ) {
                                        let valueOfCheckBox =
                                          e.target.value.split("-");
                                        console.log(
                                          "Value of box",
                                          valueOfCheckBox
                                        );
                                        const index =
                                          formik.values.subject_id.findIndex(
                                            (sub) =>
                                              sub.id === valueOfCheckBox[1]
                                          );
                                        console.log("Index find in", index);
                                        let ourObj =
                                          formik.values.subject_id[index];
                                        ourObj.isCheck = false;
                                        // formik.values.subject_id.splice(i, 1);

                                        // console.log(
                                        //   "Is selected all true and partially subject is false"
                                        // );
                                        // console.log(
                                        //   "All subjects values in formik",
                                        //   formik.values.subject_id
                                        // );
                                        setIsSubjectSelectAll(false);
                                        setIsPartiallySelectedSubjects(true);
                                      } else {
                                        console.log(
                                          "Is selected all true is false and partially subject is false"
                                        );
                                        let valueOfCheckBox =
                                          e.target.value.split("-");
                                        console.log(
                                          "Value of box",
                                          valueOfCheckBox
                                        );
                                        const index =
                                          formik.values.subject_id.findIndex(
                                            (sub) =>
                                              sub.id === valueOfCheckBox[1]
                                          );
                                        console.log("Index find in", index);
                                        let ourObj =
                                          formik.values.subject_id[index];
                                        ourObj.isCheck = false;
                                        setisSubjectStateChange(
                                          (prev) => prev + 1
                                        );
                                      }
                                    }
                                  }}
                                />
                              ))
                          : isPartiallySelectedSubjects
                          ? formik.values.subject_id
                              .filter((sub) => {
                                return searchSubject.toLowerCase() === ""
                                  ? sub
                                  : sub.name
                                      // .toLowerCase()
                                      .includes(searchSubject);
                              })
                              .map((sub) => (
                                <CheckBox
                                  text={sub.name}
                                  key={sub.id}
                                  value={`${sub.name}-${sub.id}`}
                                  isDefaultChecked={sub.isCheck}
                                  handleChange={(e) => {
                                    //setIsSubjectSelectAll(false);
                                    // console.log(
                                    //   "subject checkbox value of isPartiall true",
                                    //   e.target.value
                                    // );
                                    console.log("Is partially true");
                                    if (e.target.checked) {
                                      console.log("Is partially true checked");
                                      // console.log(
                                      //   "Checked value is",
                                      //   e.target.value
                                      // );
                                      let valueOfCheckBox =
                                        e.target.value.split("-");
                                      //Sab sy pehly ham check krain gy k jo ham ny select kiya hai wo pehly array mai mojud to nhi agr hai to ham isky index ki check ko change kr dain gy wrna add kr dain gy
                                      const index =
                                        formik.values.subject_id.findIndex(
                                          (sub) => sub.id === valueOfCheckBox[1]
                                        );
                                      if (index > -1) {
                                        let ourObj =
                                          formik.values.subject_id[index];
                                        ourObj.isCheck = true;
                                        setisSubjectStateChange(
                                          (prev) => prev + 1
                                        );
                                      } else {
                                        let myObj = {
                                          id: valueOfCheckBox[1],
                                          name: valueOfCheckBox[0],
                                          isCheck: true
                                        };
                                        formik.values.subject_id.push(myObj);
                                        setisSubjectStateChange(
                                          (prev) => prev + 1
                                        );
                                      }
                                    } else {
                                      // console.log(
                                      //   "UnChecked value is",
                                      //   e.target.value
                                      // );
                                      console.log(
                                        "Is partially true unchecked"
                                      );
                                      setisSubjectStateChange(
                                        (prev) => prev + 1
                                      );

                                      if (
                                        isSubjectSelectAll &&
                                        isPartiallySelectedSubjects
                                      ) {
                                        console.log(
                                          "Is partially true and isSubjectSelectAll=false,isPartialSelectSubject=false"
                                        );
                                        let index =
                                          formik.values.subject_id.indexOf(
                                            e.target.value
                                          );
                                        console.log("Index value is", index);
                                        //formik.values.subject_id =
                                        formik.values.subject_id.splice(
                                          index,
                                          1
                                        );
                                        console.log(
                                          "Values after partially selected",
                                          formik.values.subject_id
                                        );
                                        //setIsSubjectSelectAll(false);
                                        //setIsPartiallySelectedSubjects(true);
                                      } else {
                                        console.log(
                                          "Is partially true and isSubjectSelectAll=true,isPartialSelectSubject=true"
                                        );
                                        let valueOfCheckBox =
                                          e.target.value.split("-");
                                        console.log(
                                          "Value of box",
                                          valueOfCheckBox
                                        );
                                        const index =
                                          formik.values.subject_id.findIndex(
                                            (sub) =>
                                              sub.id === valueOfCheckBox[1]
                                          );
                                        console.log("Index find in", index);
                                        let ourObj =
                                          formik.values.subject_id[index];
                                        ourObj.isCheck = false;
                                      }
                                    }
                                  }}
                                />
                              ))
                          : allSubjects.data.subjects
                              .filter((sub) => {
                                return searchSubject.toLowerCase() === ""
                                  ? sub
                                  : sub.name
                                      // .toLowerCase()
                                      .includes(searchSubject);
                              })
                              .map((sub) => (
                                <CheckBox
                                  text={sub.name}
                                  key={sub._id}
                                  value={`${sub.name}-${sub._id}`}
                                  // isDefaultChecked={isSubjectSelectAll}
                                  handleChange={(e) => {
                                    //setIsSubjectSelectAll(false);
                                    console.log(
                                      "subject checkbox value",
                                      e.target.value
                                    );

                                    if (e.target.checked) {
                                      console.log(
                                        "Checked value is",
                                        e.target.checked
                                      );
                                      let valueOfCheckBox =
                                        e.target.value.split("-");
                                      console.log(
                                        "Value of box",
                                        valueOfCheckBox
                                      );
                                      let myObj = {
                                        id: valueOfCheckBox[1],
                                        name: valueOfCheckBox[0],
                                        isCheck: true
                                      };
                                      formik.values.subject_id.push(myObj);
                                    } else {
                                      console.log(
                                        "UnChecked value is",
                                        e.target.value
                                      );

                                      let valueOfCheckBox =
                                        e.target.value.split("-");
                                      console.log(
                                        "Value of box",
                                        valueOfCheckBox
                                      );
                                      const i =
                                        formik.values.subject_id.findIndex(
                                          (sub) => sub.id === valueOfCheckBox[1]
                                        );
                                      console.log("Index find in", i);
                                      formik.values.subject_id.splice(i, 1);
                                    }
                                  }}
                                />
                              ))}
                      </div>
                    </div>
                  </div>
                  <div className="cardDesigns">
                    <div className="dashboard-table__header">
                      <h1>Systems</h1>
                    </div>
                    <div className="customize-search">
                      <CheckBox
                        //text={type}
                        text="Select All"
                        //value={type}
                        value="Select All"
                        isDefaultChecked={isSystemSelectAll}
                        //key={index}
                        handleChange={(e) => {
                          console.log("Event", e);
                          console.log(
                            "Question type checkbox value",
                            e.target.value
                          );
                          if (e.target.checked) {
                            console.log(
                              "Checked value of subject selectall",
                              e.target.value
                            );
                            setIsSystemSelectAll(true);
                            let myAllTempSubjectsArray = [];
                            allSystems.data.data.systems.map((s, i) => {
                              let myObj = {
                                id: s._id,
                                name: s.name,
                                isCheck: true
                              };
                              myAllTempSubjectsArray.push(myObj);
                            });

                            if (formik.values.system_id.length === 0) {
                              formik.values.system_id = myAllTempSubjectsArray;
                            } else {
                              console.log("Array has some values");
                              formik.values.system_id = [];
                              formik.values.system_id = myAllTempSubjectsArray;
                              setisSubjectStateChange((prev) => prev + 1);
                            }
                          } else {
                            console.log(
                              "UnChecked value subjects selected all",
                              e.target.value
                            );
                            setIsSystemSelectAll(false);
                            formik.values.system_id = [];

                            //setIsPartiallySelectedSubjects(true);
                          }
                        }}
                      />
                      <Search
                        placeholder="search system"
                        handleChange={searchSystemHandler}
                      />
                    </div>
                    <div className="session-customization-wrapper">
                      <div className="session-customization-wrapper__subject">
                        {isSystemSelectAll
                          ? allSystems.data.data.systems
                              .filter((sub) => {
                                return searchSystem.toLowerCase() === ""
                                  ? sub
                                  : sub.name
                                      // .toLowerCase()
                                      .includes(searchSystem);
                              })
                              .map((sub) => (
                                <CheckBox
                                  text={sub.name}
                                  key={sub._id}
                                  value={`${sub.name}-${sub._id}`}
                                  isDefaultChecked={isSystemSelectAll}
                                  handleChange={(e) => {
                                    //setIsSubjectSelectAll(false);

                                    if (e.target.checked) {
                                      console.log(
                                        "Checked value is",
                                        e.target.value
                                      );
                                      // allSubjects.data.subjects.map((s) => {
                                      //   formik.values.subject_id.push({
                                      //     id: s._id,
                                      //     name: s.name
                                      //   });
                                      // });
                                    } else {
                                      setisSubjectStateChange(
                                        (prev) => prev + 1
                                      );
                                      console.log(
                                        "UnChecked value is",
                                        e.target.value
                                      );
                                      if (
                                        isSystemSelectAll &&
                                        !isPartiallySelectedSystems
                                      ) {
                                        let valueOfCheckBox =
                                          e.target.value.split("-");
                                        console.log(
                                          "Value of box",
                                          valueOfCheckBox
                                        );
                                        const index =
                                          formik.values.system_id.findIndex(
                                            (sub) =>
                                              sub.id === valueOfCheckBox[1]
                                          );
                                        console.log("Index find in", index);
                                        let ourObj =
                                          formik.values.system_id[index];
                                        ourObj.isCheck = false;
                                        // formik.values.subject_id.splice(i, 1);

                                        // console.log(
                                        //   "Is selected all true and partially subject is false"
                                        // );
                                        // console.log(
                                        //   "All subjects values in formik",
                                        //   formik.values.subject_id
                                        // );
                                        setIsSystemSelectAll(false);
                                        setIsPartiallySelectedSystems(true);
                                      } else {
                                        console.log(
                                          "Is selected all true is false and partially subject is false"
                                        );
                                        let valueOfCheckBox =
                                          e.target.value.split("-");
                                        console.log(
                                          "Value of box",
                                          valueOfCheckBox
                                        );
                                        const index =
                                          formik.values.system_id.findIndex(
                                            (sub) =>
                                              sub.id === valueOfCheckBox[1]
                                          );
                                        console.log("Index find in", index);
                                        let ourObj =
                                          formik.values.system_id[index];
                                        ourObj.isCheck = false;
                                        setisSubjectStateChange(
                                          (prev) => prev + 1
                                        );
                                      }
                                    }
                                  }}
                                />
                              ))
                          : isPartiallySelectedSystems
                          ? formik.values.system_id
                              .filter((sub) => {
                                return searchSystem.toLowerCase() === ""
                                  ? sub
                                  : sub.name
                                      // .toLowerCase()
                                      .includes(searchSystem);
                              })
                              .map((sub) => (
                                <CheckBox
                                  text={sub.name}
                                  key={sub.id}
                                  value={`${sub.name}-${sub.id}`}
                                  isDefaultChecked={sub.isCheck}
                                  handleChange={(e) => {
                                    //setIsSubjectSelectAll(false);
                                    // console.log(
                                    //   "subject checkbox value of isPartiall true",
                                    //   e.target.value
                                    // );
                                    console.log("Is partially true");
                                    if (e.target.checked) {
                                      console.log("Is partially true checked");
                                      // console.log(
                                      //   "Checked value is",
                                      //   e.target.value
                                      // );
                                      let valueOfCheckBox =
                                        e.target.value.split("-");
                                      //Sab sy pehly ham check krain gy k jo ham ny select kiya hai wo pehly array mai mojud to nhi agr hai to ham isky index ki check ko change kr dain gy wrna add kr dain gy
                                      const index =
                                        formik.values.system_id.findIndex(
                                          (sub) => sub.id === valueOfCheckBox[1]
                                        );
                                      if (index > -1) {
                                        let ourObj =
                                          formik.values.system_id[index];
                                        ourObj.isCheck = true;
                                        setisSubjectStateChange(
                                          (prev) => prev + 1
                                        );
                                      } else {
                                        let myObj = {
                                          id: valueOfCheckBox[1],
                                          name: valueOfCheckBox[0],
                                          isCheck: true
                                        };
                                        formik.values.system_id.push(myObj);
                                        setisSubjectStateChange(
                                          (prev) => prev + 1
                                        );
                                      }
                                    } else {
                                      // console.log(
                                      //   "UnChecked value is",
                                      //   e.target.value
                                      // );
                                      console.log(
                                        "Is partially true unchecked"
                                      );
                                      setisSubjectStateChange(
                                        (prev) => prev + 1
                                      );

                                      if (
                                        isSystemSelectAll &&
                                        isPartiallySelectedSystems
                                      ) {
                                        console.log(
                                          "Is partially true and isSubjectSelectAll=false,isPartialSelectSubject=false"
                                        );
                                        let index =
                                          formik.values.system_id.indexOf(
                                            e.target.value
                                          );
                                        console.log("Index value is", index);
                                        //formik.values.subject_id =
                                        formik.values.system_id.splice(
                                          index,
                                          1
                                        );
                                        console.log(
                                          "Values after partially selected",
                                          formik.values.system_id
                                        );
                                        //setIsSubjectSelectAll(false);
                                        //setIsPartiallySelectedSubjects(true);
                                      } else {
                                        console.log(
                                          "Is partially true and isSubjectSelectAll=true,isPartialSelectSubject=true"
                                        );
                                        let valueOfCheckBox =
                                          e.target.value.split("-");
                                        console.log(
                                          "Value of box",
                                          valueOfCheckBox
                                        );
                                        const index =
                                          formik.values.system_id.findIndex(
                                            (sub) =>
                                              sub.id === valueOfCheckBox[1]
                                          );
                                        console.log("Index find in", index);
                                        let ourObj =
                                          formik.values.system_id[index];
                                        ourObj.isCheck = false;
                                      }
                                    }
                                  }}
                                />
                              ))
                          : allSystems.data.data.systems
                              .filter((sub) => {
                                return searchSystem.toLowerCase() === ""
                                  ? sub
                                  : sub.name
                                      // .toLowerCase()
                                      .includes(searchSystem);
                              })
                              .map((sub) => (
                                <CheckBox
                                  text={sub.name}
                                  key={sub._id}
                                  value={`${sub.name}-${sub._id}`}
                                  // isDefaultChecked={isSubjectSelectAll}
                                  handleChange={(e) => {
                                    //setIsSubjectSelectAll(false);
                                    console.log(
                                      "subject checkbox value",
                                      e.target.value
                                    );

                                    if (e.target.checked) {
                                      console.log(
                                        "Checked value is",
                                        e.target.checked
                                      );
                                      let valueOfCheckBox =
                                        e.target.value.split("-");
                                      console.log(
                                        "Value of box",
                                        valueOfCheckBox
                                      );
                                      let myObj = {
                                        id: valueOfCheckBox[1],
                                        name: valueOfCheckBox[0],
                                        isCheck: true
                                      };
                                      formik.values.system_id.push(myObj);
                                    } else {
                                      console.log(
                                        "UnChecked value is",
                                        e.target.value
                                      );

                                      let valueOfCheckBox =
                                        e.target.value.split("-");
                                      console.log(
                                        "Value of box",
                                        valueOfCheckBox
                                      );
                                      const i =
                                        formik.values.system_id.findIndex(
                                          (sub) => sub.id === valueOfCheckBox[1]
                                        );
                                      console.log("Index find in", i);
                                      formik.values.system_id.splice(i, 1);
                                    }
                                  }}
                                />
                              ))}
                      </div>
                    </div>
                  </div>

                  <div className="pq-btn-wrapper">
                    {/*<Link to="/start_practice_questions">*/}
                    <Button
                      text={formik.isSubmitting ? "Loading ..." : "Submit"}
                      type="submit"
                      isDisable={formik.isSubmitting}
                      handleSubmit={formik.handleSubmit}
                    />
                    {/*</div>*</Link>*/}
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  }
};

export default PracticeQuestions;
