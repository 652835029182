import { useState } from "react";
import TopMenu from "../Components/TopMenu";
import AboutUsImage from "../assets/images/privacy_lmage.jpeg";
import "../assets/scss/StaticPagesWrapper.scss";
import Footer from "../Components/Footer";
const Privacy = () => {
  const [staticData, setStaticData] = useState({
    en: {
      introduction: "Introduction",
      introductionParagraph:
        "Welcome to MedGrizz.com. We are committed to protecting the privacy of our users and customers. This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with our website, services, and any related applications. By using our website, you agree to the collection and use of information in accordance with this policy.",
      informationCollect: "Information We Collect",
      informationCollectParagraph:
        "We collect personal information that you voluntarily provide to us when you register for an account, make a purchase, or otherwise use our services. This information may include your name, email address, phone number, and payment details.\nWe also collect non-personal information, such as your IP address, browser type, operating system, and browsing behavior on our website.",
      yourInformation: "How We Use Your Information",
      yourInformationParagraph: {
        heading: "We use your personal information for the following purposes:",
        list: [
          "To provide and maintain our services",
          "To process transactions and manage subscriptions",
          "To communicate with you about updates, promotions, and customer support",
          "To monitor and analyze usage trends and improve our website and services",
          "To protect our rights and comply with legal obligations"
        ]
      },
      sharingInformation: "Sharing Your Information",
      sharingInformationParagraph:
        "We may share your personal information with third parties, such as payment processors and service providers, as necessary to provide our services. We do not sell or rent your personal information to third parties for marketing purposes.",
      security: "Security",
      securityParagraph:
        "We take the security of your personal information seriously and implement appropriate measures to protect it from unauthorized access, disclosure, or destruction. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.",
      changePrivacyPolicy: "Changes to This Privacy Policy",
      changePrivacyPolicyParagraph:
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of our website and services after any changes constitutes your acceptance of the updated Privacy Policy.",
      contactUs: "Contact Us",
      contactUsParagraph:
        "If you have any questions about this Privacy Policy, please contact us at medgrizz@gmail.com"
    },
    thai: {
      introduction: "บทนำ",
      introductionParagraph:
        "ยินดีต้อนรับสู่ MedGrizz.com เรามุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของผู้ใช้และลูกค้าของเรา นโยบายความเป็นส่วนตัวนี้อธิบายถึงวิธีที่เรารวบรวม ใช้ และปกป้องข้อมูลส่วนบุคคลของคุณเมื่อคุณโต้ตอบกับเว็บไซต์ บริการ และแอปพลิเคชันที่เกี่ยวข้องของเรา การใช้เว็บไซต์ของเราแสดงว่าคุณยอมรับการรวบรวมและการใช้ข้อมูลตามนโยบายนี้",
      informationCollect: "ข้อมูลที่เรารวบรวม",
      informationCollectParagraph:
        "เราเก็บรวบรวมข้อมูลส่วนบุคคลที่คุณให้ไว้กับเราโดยสมัครใจเมื่อคุณลงทะเบียนบัญชี ซื้อสินค้า หรือใช้บริการของเรา ข้อมูลนี้อาจรวมถึงชื่อ ที่อยู่อีเมล หมายเลขโทรศัพท์ และรายละเอียดการชำระเงินของคุณ\nนอกจากนี้เรายังรวบรวมข้อมูลที่ไม่ใช่ข้อมูลส่วนบุคคล เช่น ที่อยู่ IP ประเภทเบราว์เซอร์ ระบบปฏิบัติการ และพฤติกรรมการท่องเว็บบนเว็บไซต์ของเรา",
      yourInformation: "เราใช้ข้อมูลของคุณอย่างไร",
      yourInformationParagraph: {
        heading: "เราใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้:",
        list: [
          "เพื่อให้บริการและรักษาบริการของเรา",
          "เพื่อประมวลผลธุรกรรมและจัดการการสมัครสมาชิก",
          "เพื่อสื่อสารกับคุณเกี่ยวกับการอัปเดต โปรโมชั่น และการสนับสนุนลูกค้า",
          "เพื่อติดตามและวิเคราะห์แนวโน้มการใช้งานและปรับปรุงเว็บไซต์และบริการของเรา",
          "เพื่อปกป้องสิทธิ์ของเราและปฏิบัติตามภาระผูกพันทางกฎหมาย"
        ]
      },
      sharingInformation: "การแบ่งปันข้อมูลของคุณ",
      sharingInformationParagraph:
        "เราอาจแบ่งปันข้อมูลส่วนบุคคลของคุณกับบุคคลที่สาม เช่น ผู้ประมวลผลการชำระเงินและผู้ให้บริการ ตามความจำเป็นในการให้บริการของเรา เราไม่ขายหรือให้เช่าข้อมูลส่วนบุคคลของคุณแก่บุคคลที่สามเพื่อวัตถุประสงค์ทางการตลาด",
      security: "ความปลอดภัย",
      securityParagraph:
        "เราให้ความสำคัญกับการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณอย่างจริงจัง และใช้มาตรการที่เหมาะสมเพื่อปกป้องข้อมูลดังกล่าวจากการเข้าถึง การเปิดเผย หรือการทำลายโดยไม่ได้รับอนุญาต อย่างไรก็ตาม ไม่มีวิธีการส่งข้อมูลหรือการจัดเก็บใดที่ปลอดภัย 100% และเราไม่สามารถรับประกันความปลอดภัยได้อย่างสมบูรณ์",
      changePrivacyPolicy: "การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้",
      changePrivacyPolicyParagraph:
        "เราอาจปรับปรุงนโยบายความเป็นส่วนตัวของเราเป็นครั้งคราว เราจะแจ้งให้คุณทราบถึงการเปลี่ยนแปลงใด ๆ โดยการโพสต์นโยบายความเป็นส่วนตัวใหม่ในหน้านี้ การที่คุณใช้เว็บไซต์และบริการของเราต่อไปหลังจากการเปลี่ยนแปลงใด ๆ ถือว่าคุณยอมรับนโยบายความเป็นส่วนตัวฉบับปรับปรุง",
      contactUs: "ติดต่อเรา",
      contactUsParagraph:
        "หากคุณมีคำถามใดๆ เกี่ยวกับนโยบายความเป็นส่วนตัวนี้ โปรดติดต่อเราที่ medgrizz@gmail.com"
    }
  });
  const len = localStorage.getItem("language");
  const [language, setLanguage] = useState(len);
  const languageHandler = (val) => {
    console.log("Language is ", val);
    setLanguage(val);
  };
  return (
    <div>
      <TopMenu languageHandler={languageHandler} />
      <div className="imageWrapper">
        <div className="terms-image privacy_image"></div>
        <div className="main-heading">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div className="generic-list">
        <div className="list-item-card">
          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.introduction
              : staticData.en.introduction}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.introductionParagraph
              : staticData.en.introductionParagraph}
          </p>
          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.informationCollect
              : staticData.en.informationCollect}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.informationCollectParagraph
              : staticData.en.informationCollectParagraph}
          </p>

          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.yourInformation
              : staticData.en.yourInformation}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.yourInformationParagraph.heading
              : staticData.en.yourInformationParagraph.heading}
          </p>
          <div className="card-list">
            <ul>
              {language === "thai"
                ? staticData.thai.yourInformationParagraph.list.map(
                    (item, index) => {
                      return <li key={index}>{item}</li>;
                    }
                  )
                : staticData.en.yourInformationParagraph.list.map(
                    (item, index) => {
                      return <li key={index}>{item}</li>;
                    }
                  )}
            </ul>
          </div>

          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.sharingInformation
              : staticData.en.sharingInformation}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.sharingInformationParagraph
              : staticData.en.sharingInformationParagraph}
          </p>
          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.security
              : staticData.en.security}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.securityParagraph
              : staticData.en.securityParagraph}
          </p>
          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.changePrivacyPolicy
              : staticData.en.changePrivacyPolicy}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.changePrivacyPolicyParagraph
              : staticData.en.changePrivacyPolicyParagraph}
          </p>
          <h1 className="card-heading">
            {language === "thai"
              ? staticData.thai.contactUs
              : staticData.en.contactUs}
          </h1>
          <p className="card-text">
            {language === "thai"
              ? staticData.thai.contactUsParagraph
              : staticData.en.contactUsParagraph}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
