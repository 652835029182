import Image1 from "../assets/images/SliderImage1.jpeg";
import Image2 from "../assets/images/SliderImage2.jpeg";
import Image3 from "../assets/images/SliderImage3.jpeg";
import Image4 from "../assets/images/SliderImage4.jpeg";
import Image5 from "../assets/images/SliderImage5.jpeg";
import FImage1 from "../assets/Svgs/Image1.svg";
import FImage2 from "../assets/Svgs/Image2.svg";
import FImage3 from "../assets/Svgs/Image3.svg";
import FImage4 from "../assets/Svgs/Image4.svg";
import FImage5 from "../assets/Svgs/Image5.svg";
import FImage6 from "../assets/Svgs/Image6.svg";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../assets/scss/Home.scss";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import TopMenu from "../Components/TopMenu";

import PricingPlanCard from "../Components/PricingPlanCard";
import Footer from "../Components/Footer";

import YellowBtn from "../Components/YellowBtn";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import toast, { Toaster } from "react-hot-toast";

const Home = () => {
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const len = localStorage.getItem("language");
  const [language, setLanguage] = useState(len);
  const languageHandler = (val) => {
    console.log("Language is ", val);
    setLanguage(val);
  };
  let userAlreadySubscribedPlans = [];
  if (userStored) {
    userStored?.subscriptions?.map((sub) => {
      return userAlreadySubscribedPlans.push(sub.plan.id);
    });
  }

  const getAllUserSubscriptions = async () => {
    const { data } = await apiClient.get("/stripe/user-subscription");
    return data;
  };
  const allUserSubscriptions = useQuery(
    ["userSubscriptions"],
    getAllUserSubscriptions,
    {
      retry: 1,
      onSuccess: (res) => {
        console.log("response of exam on success in home page", res);
      },
      onError: (res) => {
        console.log("response of exam on error", res);
      }
    }
  );
  if (allUserSubscriptions.isSuccess) {
    allUserSubscriptions.data.data?.subscriptions.map((sub) => {
      return userAlreadySubscribedPlans.push(sub.plan.id);
    });
  }
  console.log("User already purchased", userAlreadySubscribedPlans);
  const sliderData = {
    en: [
      {
        url: Image1,
        heading_main: "Unlock your potential with MedGrizz!",
        text: "Practice for the NL Exam with high-quality questions, in-depth explanations, and personalized analytics. Join now and start your journey towards success!",
        btnUrl: "/signup",
        buttonText: "Sign Up & Study Together"
      },
      {
        url: Image2,
        heading_main: "Study smarter, not harder!",
        text: "MedGrizz offers customized mock exams, powerful performance tracking, and a comprehensive question bank. Collaborate and succeed with your peers!",
        buttonText: "Sign Up & Study Together",
        btnUrl: "/signup"
      },
      {
        url: Image3,
        heading_main: "Become the doctor you've always dreamed of! ",
        text: "MedGrizz not only prepares you for the NL Exam but also helps you build a strong foundation for your future medical career. Let's grow together!",
        buttonText: "Learn more",
        btnUrl: "Scroll down to Our Key Feature"
      },
      {
        url: Image4,
        heading_main: "Achieve your goals with MedGrizz!",
        text: "Our platform is designed to help you excel in the NL Exam and become a better physician. Start your journey with us and celebrate your success!",
        buttonText: "Begin Your Success Story",
        btnUrl: "/signup"
      },
      {
        url: Image5,
        heading_main: "Comprehensive, user-friendly, and effective",
        text: "MedGrizz is your ultimate NL Exam study partner. Embrace the learning experience with our tailored features and plans. Get started now!",
        buttonText: "Explore MedGrizz Plans",
        btnUrl: "/pricingPlans"
      }
    ],
    thai: [
      {
        url: Image1,
        heading_main: "ปลดล็อคความสามารถของคุณด้วย MedGrizz!",
        text: "ฝึกฝนการสอบ NL ด้วยข้อสอบเสหมือนจริง คำอธิบายเชิงลึก และการวิเคราะห์ความรู้รายบุคคล เข้าร่วมตอนนี้เพื่อเริ่มต้นการเดินทางสู่ความสำเร็จ!",
        btnUrl: "/signup",
        buttonText: "เข้าร่วม MedGrizz ทันที!"
      },
      {
        url: Image2,
        heading_main: "เรียนอย่างชาญฉลาด ไม่ใช่หนักขึ้น!",
        text: "MedGrizz มีข้อสอบจำลองที่ปรับแต่งได้ อัลกอริทึมการติดตามผลการปฏิบัติงานอันทรงพลัง และคลังคำถามที่ครอบคลุม ทำงานร่วมกันและประสบความสำเร็จไปพร้อมๆกับเพื่อนของน้องกันเลย!",
        buttonText: "ลงทะเบียนและผ่านสมรภูมิข้อสอบไปด้วยกัน",
        btnUrl: "/signup"
      },
      {
        url: Image3,
        heading_main: "จบมาเป็นคุณหมอตามที่ได้ใฝ่ฝันมาตลอด!",
        text: "MedGrizz ไม่เพียงแต่เตรียมน้องสำหรับการสอบ NL แต่ยังช่วยให้น้องสร้างรากฐานที่แข็งแกร่งสำหรับอาชีพทางการแพทย์ในอนาคต มาเติบโตไปด้วยกัน!",
        buttonText: "เรียนรู้เพิ่มเติมเกี่ยวกับ MedGrizz",
        btnUrl: "Scroll down to Our Key Feature"
      },
      {
        url: Image4,
        heading_main: "บรรลุเป้าหมายของคุณด้วย MedGrizz!",
        text: "แพลตฟอร์มของเราออกแบบมาเพื่อช่วยให้น้องกลายเป็นผู้เชี่ยวชาญในการสอบ NL และเป็นแพทย์ที่มีความรู้มากขึ้น เริ่มต้นการเดินทางของน้องกับเราและเฉลิมฉลองความสำเร็จไปด้วยกัน!",
        buttonText: "เริ่มต้นเรื่องราวความสำเร็จของคุณ",
        btnUrl: "/signup"
      },
      {
        url: Image5,
        heading_main: "ครอบคลุม ใช้งานง่าย และมีประสิทธิภาพ",
        text: "MedGrizz คือพันธมิตรในการเตรียมสอบ NL ที่ดีที่สุดของน้อง โอบรับประสบการณ์การเรียนรู้ด้วยฟีเจอร์ฝีกทำข้อสอบของเรา เริ่มตอนนี้เลย!",
        buttonText: "สำรวจราคาของ MedGrizz",
        btnUrl: "/pricingPlans"
      }
    ]
  };

  const featuresData = {
    en: [
      {
        icon: FImage1,
        heading: "Authentic NL Exam Questions",
        paragraph_text:
          'Experience real-life NL exam scenarios with our meticulously crafted questions, complete with patient history cases and detailed English paragraphs. MedGrizz"s" question bank simulates the actual test environment to help you become familiar with the exam format and boost your confidence'
      },
      {
        icon: FImage2,
        heading: "Comprehensive Explanations",
        paragraph_text:
          "MedGrizz provides thorough explanations for each answer choice, including incorrect ones, to deepen your understanding of the subject matter. Our platform ensures you grasp the underlying concepts, enabling you to learn from your mistakes and improve your performance."
      },
      {
        icon: FImage3,
        heading: "Customizable Exams",
        paragraph_text:
          "Tailor your practice exams to suit your specific needs by setting the time limit, the number of questions, and choosing which systems to focus on. MedGrizz customizable exams allow you to concentrate on your weak areas and make the most out of your study time."
      },
      {
        icon: FImage4,
        heading: "Flag Questions for Review",
        paragraph_text:
          "Easily flag questions you are unsure about to review them later. MedGrizz flagging feature ensures you do not miss any critical concepts and helps you build a strong foundation by revisiting and mastering challenging questions."
      },
      {
        icon: FImage5,
        heading: "In-Depth Performance Analysis",
        paragraph_text:
          'Track your progress and identify your strengths and weaknesses with MedGrizz"s" comprehensive performance analysis. Our platform breaks down your performance by subject area and question type, providing valuable insights to help you focus your efforts and optimize your study plan.'
      },
      {
        icon: FImage6,
        heading: "Question and Answer Reporting",
        paragraph_text:
          "Help us maintain the highest quality of content by reporting any mistakes in questions or answers. MedGrizz is committed to ensuring accuracy and is ready to make corrections as soon as possible, ensuring you have the most reliable and up-to-date study materials."
      }
    ],
    thai: [
      {
        icon: FImage1,
        heading: "ข้อสอบ NL เสหมือนจริง",
        paragraph_text:
          "สัมผัสประสบการณ์สถานการณ์การสอบ NL เหมือนชีวิตจริงด้วยคำถามที่จัดทำขึ้นอย่างพิถีพิถัน พร้อมด้วยประวัติผู้ป่วยเป็นภาษาอังกฤษโดยละเอียด คลังคำถามของ MedGrizz จำลองสภาพแวดล้อมการทดสอบจริงเพื่อช่วยให้น้องคุ้นเคยกับรูปแบบการสอบและเพิ่มความมั่นใจ"
      },
      {
        icon: FImage2,
        heading: "คำอธิบายคำตอบที่ครอบคลุม",
        paragraph_text:
          "MedGrizz ให้คำอธิบายอย่างละเอียดสำหรับคำตอบแต่ละตัวเลือก รวมถึงคำตอบที่ไม่ถูกต้อง เพื่อให้น้องเข้าใจเนื้อหาได้อย่างลึกซึ้งยิ่งขึ้น แพลตฟอร์มของเราช่วยให้แน่ใจว่าน้องได้เข้าใจแนวคิดพื้นฐาน และยังช่วยให้น้องเรียนรู้จากข้อผิดพลาดและปรับปรุงได้อย่างมีประสิทธิภาพ"
      },
      {
        icon: FImage3,
        heading: "ข้อสอบที่ปรับแต่งได้ตามใจ",
        paragraph_text:
          "ปรับแต่งข้อสอบฝึกหัดให้เหมาะกับความต้องการเฉพาะของคุณโดยกำหนดเวลา จำนวนคำถาม และเลือกระบบที่จะเน้น การสอบที่ปรับแต่งได้ของ MedGrizz จะช่วยทำให้น้องสามารถโฟกัสกับจุดอ่อนและใช้เวลาเรียนให้เกิดประโยชน์สูงสุด"
      },
      {
        icon: FImage4,
        heading: "ตั้งค่าสถานะคำถามเพื่อทบทวน",
        paragraph_text:
          "ตั้งค่าสถานะคำถามที่น้องไม่แน่ใจได้อย่างง่ายดายเพื่อทบทวนในภายหลัง คุณลักษณะการติดธงของ MedGrizz ช่วยให้น้องไม่พลาดแนวคิดที่สำคัญใดๆ และช่วยสร้างรากฐานที่แข็งแกร่งโดยการทบทวนและฝึกฝนคำถามที่ท้าทาย"
      },
      {
        icon: FImage5,
        heading: "การวิเคราะห์ประสิทธิภาพเชิงลึก",
        paragraph_text:
          "ติดตามความคืบหน้าและระบุจุดแข็งและจุดอ่อนของน้องด้วยการวิเคราะห์ประสิทธิภาพที่ครอบคลุมของ MedGrizz แพลตฟอร์มของเราจะแจกแจงประสิทธิภาพของน้องตามสาขาวิชาและประเภทคำถาม โดยให้ข้อมูลเชิงลึกอันมีค่าเพื่อช่วยให้น้องสามารถมุ่งความสนใจไปที่ความพยายามและเพิ่มประสิทธิภาพแผนการศึกษา"
      },
      {
        icon: FImage6,
        heading: "การรายงานคำถามและคำตอบ",
        paragraph_text:
          "ช่วยเรารักษาคุณภาพเนื้อหาสูงสุดโดยการรายงานข้อผิดพลาดในคำถามหรือคำตอบ MedGrizz มุ่งมั่นที่จะรับรองความถูกต้องและพร้อมที่จะทำการแก้ไขโดยเร็วที่สุด เพื่อให้มั่นใจว่าน้องมีสื่อการศึกษาที่เชื่อถือได้และทันสมัยที่สุด"
      }
    ]
  };

  const masterYourNLP = {
    en: {
      heading: "Master Your NL Exam Preparation with MedGrizz",
      paragraph:
        "Welcome to MedGrizz, your efficient solution for National License Step 1 exam preparation. Our platform offers a comprehensive question bank, customizable mock tests, and performance tracking to save time and enhance your learning experience. At MedGrizz, our goal goes beyond improving your exam score; we're committed to supporting you in becoming a better physician for a successful and fulfilling medical career. Join MedGrizz today and take the first step towards a brighter future in medicine!"
    },
    thai: {
      heading: "พิชิตข้อสอบ NL ด้วยแพลตฟอร์ม MedGrizz",
      paragraph:
        "ยินดีต้อนรับน้องๆเข้าสู่ MedGrizz แพลตฟอร์มเพื่อการเตรียมความพร้อมในการสอบใบประกอบวิชาชีพแพทย์ หรือที่นิสิต นักศึกษาแพทย์ทั่วไปเรียกว่าการสอบ NL (National License) แพลตฟอร์มของเรามีคลังคำถามที่ครอบคลุมและเหมือนจริง แบบทดสอบจำลองที่ปรับแต่งได้ และความสามารถในการการติดตามผลข้อสอบของน้องอย่างมีประสิทธิภาพเพื่อช่วยประหยัดเวลาและปรับปรุงให้น้องได้คะแนนและโอกาศในการผ่านที่สูงที่สุดเท่าที่จะเป็นไปได้ ที่ MedGrizz เป้าหมายของเราไม่ได้มีแค่ปรับปรุงคะแนนสอบแต่เรามุ่งมั่นที่จะสนับสนุนและเพิ่มความรู้ของน้องๆเพื่ออนาคตในการเป็นแพทย์ที่มีความรู้ความชำนาญพร้อมที่จะช่วยเหลือผู้ป่วยได้อย่างไร้ที่ติ เข้าร่วม MedGrizz วันนี้และก้าวสู่อนาคตที่สดใสในวงการแพทย์"
    }
  };
  const pricingPlan = {
    en: "Pricing and Plans",
    thai: "แผนราคาของ MedGrizz"
  };
  const keyFeatures = {
    en: "Our Key Features",
    thai: "คุณสมบัติสำคัญของแพลตฟอร์มนี้"
  };
  const getAllStripePlans = async () => {
    const { data } = await apiClient.get("/exams/");
    return data;
  };
  const allExams = useQuery(["exams"], getAllStripePlans, {
    retry: 2,
    onSuccess: (res) => {
      //console.log("response of exam on success in home page", res);
    },
    onError: (res) => {
      //console.log("response of exam on error", res);
    }
  });

  console.log("All Plans", allExams);
  return (
    <>
      <Toaster />
      <TopMenu languageHandler={languageHandler} />

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="swiper"
      >
        {language === "thai"
          ? sliderData.thai.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="card">
                    {index % 2 === 0 ? (
                      <div className="left">
                        <h1 className="card-heading-main">
                          {slide.heading_main}
                        </h1>
                        <br />
                        <h4 className="card-heading">{slide.text}</h4>
                        <br />
                        <YellowBtn text={slide.buttonText} />
                      </div>
                    ) : (
                      <>
                        <div className="left"></div>
                        <div className="right">
                          <h1 className="card-heading-main">
                            {slide.heading_main}
                          </h1>
                          <br />
                          <h4 className="card-heading">{slide.text}</h4>
                          <br />
                          <YellowBtn text={slide.buttonText} />
                        </div>
                      </>
                    )}
                  </div>
                  <img src={slide.url} alt="" />
                </SwiperSlide>
              );
            })
          : sliderData.en.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="card">
                    {index % 2 === 0 ? (
                      <div className="left">
                        <h1 className="card-heading-main">
                          {slide.heading_main}
                        </h1>
                        <br />
                        <h4 className="card-heading">{slide.text}</h4>
                        <br />
                        <YellowBtn text={slide.buttonText} />
                      </div>
                    ) : (
                      <>
                        <div className="left"></div>
                        <div className="right">
                          <h1 className="card-heading-main">
                            {slide.heading_main}
                          </h1>
                          <br />
                          <h4 className="card-heading">{slide.text}</h4>
                          <br />
                          <YellowBtn text={slide.buttonText} />
                        </div>
                      </>
                    )}
                  </div>
                  <img src={slide.url} alt="" />
                </SwiperSlide>
              );
            })}
      </Swiper>

      <div className="prepration">
        <div className="prepration__section">
          <div className="">
            <h1>
              {language === "thai"
                ? masterYourNLP.thai.heading
                : masterYourNLP.en.heading}
            </h1>
          </div>

          <p className="prepration__section-text">
            {language === "thai"
              ? masterYourNLP.thai.paragraph
              : masterYourNLP.en.paragraph}
          </p>
        </div>
      </div>
      <div className="our-line"></div>
      <div className="features__wrapper">
        <div className="features">
          <h1 className="features-text">
            {language === "thai" ? keyFeatures.thai : keyFeatures.en}
          </h1>
          {language === "thai"
            ? featuresData.thai.map((f, index) => {
                return (
                  <div className="features__section" key={index}>
                    <div className="features__section-left">
                      <img src={f.icon} alt="" />
                    </div>
                    <div className="features__section-right">
                      <h3>{f.heading}</h3>
                      <p>{f.paragraph_text}</p>
                    </div>
                  </div>
                );
              })
            : featuresData.en.map((f, index) => {
                return (
                  <div className="features__section" key={index}>
                    <div className="features__section-left">
                      <img src={f.icon} alt="" />
                    </div>
                    <div className="features__section-right">
                      <h3>{f.heading}</h3>
                      <p>{f.paragraph_text}</p>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="our-line"></div>

      <div className="pricingPlans__wrapper">
        <h1>{language === "thai" ? pricingPlan.thai : pricingPlan.en}</h1>
        {allExams.isSuccess
          ? allExams.data.data.exams?.map((exam, index) => {
              return (
                <>
                  <div className="pricing_plans" key={exam._id}>
                    {exam?.plan_ids?.length !== 0 ? (
                      <h2 className="plan_heading">{exam.name}</h2>
                    ) : (
                      ""
                    )}

                    <div className="plans" key={index}>
                      <Swiper
                        breakpoints={{
                          // when window width is <= 320px
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                          },
                          // when window width is <= 480px
                          480: {
                            slidesPerView: 2,
                            spaceBetween: 10
                          },

                          // when window width is <= 640px
                          640: {
                            slidesPerView: 2,
                            spaceBetween: 20
                          },
                          768: {
                            slidesPerView: 3,
                            spaceBetween: 20
                          },
                          920: {
                            slidesPerView: 3,
                            spaceBetween: 20
                          },
                          1200: {
                            slidesPerView: 4,
                            spaceBetween: 20
                          }
                        }}
                        slidesPerView={4}
                        spaceBetween={10}
                        autoplay={{
                          delay: 1000,
                          disableOnInteraction: false
                        }}
                        pagination={{
                          clickable: true
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                      >
                        {index % 2 === 0
                          ? exam.plan_ids?.map((plan, i) => {
                              return (
                                <SwiperSlide key={index}>
                                  <PricingPlanCard
                                    key={i}
                                    examName={exam.name}
                                    examId={exam._id}
                                    planName={plan.name}
                                    planId={plan.id}
                                    stripePriceId={plan.id}
                                    isLoggedIn={userStored}
                                    planPrice={
                                      userAlreadySubscribedPlans &&
                                      userAlreadySubscribedPlans.includes(
                                        plan.id
                                      )
                                        ? "Access Plan"
                                        : plan.unit_amount / 100
                                    }
                                    description={plan.description}
                                  />
                                </SwiperSlide>
                              );
                            })
                          : exam?.plan_ids?.map((plan, i) => {
                              return (
                                <SwiperSlide key={index}>
                                  <PricingPlanCard
                                    bgColorclass="grey-background"
                                    examName={exam.name}
                                    examId={exam._id}
                                    key={i}
                                    planName={plan.name}
                                    stripePriceId={plan.id}
                                    planId={plan.id}
                                    duration={plan.duration}
                                    isLoggedIn={userStored}
                                    plan_length={plan.plan_length}
                                    planPrice={
                                      userAlreadySubscribedPlans &&
                                      userAlreadySubscribedPlans.includes(
                                        plan.id
                                      )
                                        ? "Access Plan"
                                        : plan.unit_amount / 100
                                    }
                                    description={plan.description}
                                  />
                                </SwiperSlide>
                              );
                            })}
                      </Swiper>
                    </div>
                  </div>
                </>
              );
            })
          : null}
      </div>

      <div className="footer">
        <Footer />
      </div>
    </>
  );
};

export default Home;
