import Button from "../Components/Button";
import "../assets/scss/Login.scss";
import "../assets/scss/utils.scss";
import Logo from "../assets/images/Madgrizz_logo_blue.jpeg";
import { Link, useNavigate } from "react-router-dom";
import IconRowInput from "../Components/IconRowInput";
import { useParams } from "react-router-dom";
import LockSvg from "../Components/LockSvg";

import MedgrizzFinalLogo from "../assets/images/medgrizz_final.jpeg";
import usePostMutation from "../Utils/usePostMutation";
import { Formik, Form, useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
//import { useNavigation } from "react-router-dom";
const UpdatePassword = () => {
  // const navigate = useNavigation();
  const MyMutation = usePostMutation();
  const { email, token } = useParams();
  const navigate = useNavigate();
  console.log("email from parameter", email);
  console.log("token from parameter", token);
  // useEffect(() => {
  //   // window.location.reload(false);
  //   getUser();
  // }, []);
  const notify = (message) => toast(message);
  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      passwordConfirm: "",
    },
    onSubmit: (values, props) => {
      console.log(values);
      console.log("My mutation", MyMutation);
      try {
        MyMutation.mutate(
          {
            method: "patch",
            url: `users/resetPassword/${token}`,
            values: values,
          },
          {
            onSuccess: (res) => {
              console.log("props", props);
              toast.success("Password Updated successfully");

              props.setSubmitting(false);
              setTimeout(() => {
                navigate("/");
              }, 2000);
            },
            onError: (error) => {
              // alert("An error occured during submitting system");
              console.log("our response", error.response);
              toast.error("Invalid email or Password");
              props.setSubmitting(false);
              //toast.error(response.response.data.message);
            },
          }
        );
      } catch (error) {
        console.error(error.response);
      } finally {
        console.log("done");
      }
    },
  });
  return (
    <>
      <Toaster />

      <div className="login-form-design">
        <div className="login-form-design__form">
          <form onSubmit={formik.handleSubmit}>
            <div className="logo">
              <img src={MedgrizzFinalLogo} alt="this is logo" />
            </div>
            <h1 className="colors primary_heading mt-20">Update Password</h1>

            <IconRowInput
              icon={<LockSvg className="icon" />}
              placeholder="Password"
              type="password"
              id="password"
              name="password"
              value={formik.values.password}
              handleChange={formik.handleChange}
            />

            <IconRowInput
              icon={<LockSvg className="icon" />}
              placeholder="Confirm password"
              type="password"
              id="passwordConfirm"
              name="passwordConfirm"
              value={formik.values.passwordConfirm}
              handleChange={formik.handleChange}
            />

            <Button
              className="w-100"
              type="submit"
              text={formik.isSubmitting ? "Please wait ..." : "Update Password"}
              isDisable={formik.isSubmitting}
              handleSubmit={formik.handleSubmit}
              // disable={formik.isSubmitting}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
