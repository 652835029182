import { useTimer, useStopwatch } from "react-timer-hook";
import { MdAvTimer } from "react-icons/md";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import "../assets/scss/MyTimer.scss";
import { useEffect, useState } from "react";
const MyTimer = ({ expiryTimestamp, time, timerExpired }) => {
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
  } = useStopwatch({
    time,
    autoStart: true,
    onExpire: () => {
      console.warn("onExpire called");
      //timerExpired();
    },
  });

  // console.log("Expiry Time Stamp", expiryTimestamp);
  // console.log("time", time);

  console.log(`Hours : ${hours}, Minutes : ${minutes} , Seconds : ${seconds}`);

  const { givenHours, givenMinutes, givenSeconds } = time_convert(time);
  const [OurHours, setOurHours] = useState(givenHours - hours);
  const [OurMinutes, setOurMinutes] = useState(givenMinutes - minutes);
  const [OurSeconds, setOurSeconds] = useState(60 - seconds);
  console.log(
    `OurHours : ${OurHours}, OurMinutes : ${OurMinutes} , OurSeconds : ${OurSeconds}`
  );
  function time_convert(num = 90) {
    let hours = Math.floor(num / 60);
    let minutes = num % 60;
    let seconds = Math.floor(minutes / 60);
    // console.log("Hours", hours);
    // console.log("Minutes", minutes);
    // console.log("Seconds", seconds);
    if (hours >= 0 && minutes === 0) {
      // console.log("hours >= 0 && minutes === 0");
      hours = hours - 1;
      minutes = 60 - 1;
    }
    //if (hours >= 0 && minutes < 60) {
    else {
      // console.log("hours >= 0 && minutes < 60");
      minutes = minutes - 1;
    }
    // start();
    return {
      givenHours: hours,
      givenMinutes: minutes,
      givenSeconds: seconds,
    };
  }

  // useEffect(() => {
  //   // console.log(
  //   //   `ourHours : ${OurHours} , OurMinutes : ${OurMinutes} , OurSeconds : ${OurSeconds} `
  //   // );
  //   console.log(
  //     ` hours , ourHours : { ${hours}, ${OurHours} }, { minutes , OurMinutes }:{ ${minutes} ${OurMinutes} } ,{ seconds, OurSeconds : ${seconds} , ${OurSeconds} } `
  //   );
  //   if (OurHours >= 0) {
  //     // console.log("hours >= 0 && minutes >= 0 && seconds > 0");
  //     // console.log(
  //     //   `ourHours : ${OurHours} , OurMinutes : ${OurMinutes} , OurSeconds : ${OurSeconds} `
  //     // );
  //     // console.log("1st if");
  //     // if (OurHours >= 0 && OurMinutes < 0 && OurSeconds == 60) {
  //     //   console.log("1st if if");
  //     //   setOurHours(givenHours - 1);
  //     //   setOurMinutes(59);
  //     // } else {
  //     // console.log("1st if else");
  //     setOurHours(givenHours - hours);
  //     setOurMinutes(givenMinutes - minutes);
  //     setOurSeconds(60 - seconds);
  //     // }

  //     // console.log("Our Hours in 1st if ", OurHours);
  //   } else {
  //     console.log("Else case", OurHours, OurMinutes, OurSeconds);
  //     // console.log("hours === 0 && minutes === 0 && seconds === 0");
  //     // console.log(
  //     //   `ourHours : ${OurHours} , OurMinutes : ${OurMinutes} , OurSeconds : ${OurSeconds} `
  //     // );
  //     console.log(
  //       ` hours , ourHours : { ${hours}, ${OurHours} }, { minutes , OurMinutes }:{ ${minutes} ${OurMinutes} } ,{ seconds, OurSeconds : ${seconds} , ${OurSeconds} } `
  //     );
  //     if (OurHours === 1 && OurMinutes === -1 && seconds === 60) {
  //       console.log("1st else if");
  //       setOurHours(givenHours - 1);
  //       setOurMinutes(59);
  //       setOurSeconds(60 - 1);
  //       console.log(
  //         ` hours , ourHours : { ${hours}, ${OurHours} }, { minutes , OurMinutes }:{ ${minutes} ${OurMinutes} } ,{ seconds, OurSeconds : ${seconds} , ${OurSeconds} } `
  //       );
  //     } else {
  //       pause();
  //       timerExpired();
  //     }
  //   }
  // }, [hours, minutes, seconds]);

  return (
    <div className="timer-wrapper">
      <MdAvTimer size="3rem" />
      <div className="timer-wrapper-time">
        {/*<span>{days}</span>:<span>{hours}</span>:*/}
        {OurHours < 10 ? <span>0{OurHours}:</span> : <span>{OurHours}:</span>}
        {OurMinutes < 10 ? (
          <span>0{OurMinutes}:</span>
        ) : (
          <span>{OurMinutes}:</span>
        )}
        {OurSeconds < 10 ? (
          <span>0{OurSeconds}</span>
        ) : (
          <span>{OurSeconds}</span>
        )}
      </div>
      {isTimerPaused ? (
        <BsFillPlayFill
          // className={isTimerPaused ? "hide" : "show"}
          size="3rem"
          onClick={() => {
            setIsTimerPaused(!isTimerPaused);
            start();
          }}
        />
      ) : (
        <BsPauseFill
          // className={isTimerPaused ? "show" : "hide"}
          size="3rem"
          onClick={() => {
            setIsTimerPaused(!isTimerPaused);
            pause();
          }}
        />
      )}
    </div>
  );
};

export default MyTimer;
