import { useState } from "react";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import "../assets/scss/Wrapper.scss";
import Button from "../Components/Button";
import usePostMutation from "../Utils/usePostMutation";
import { Formik, Form, useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import apiClient from "../Utils/apiClient";
const CheckoutForm = ({ email, user_id, planObj, userPlans }) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [cardStateForBtn, setCardStateForBtn] = useState({
    cardNumberComplete: false,
    cardExpiryComplete: false,
    cardCvcComplete: false
  });
  const [isCardInformationFilled, setIsCardInformationFilled] = useState(false);
  const { mutate } = usePostMutation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const {
    planName,
    planId,
    examName,
    examId,
    examType,
    description,
    planPrice,
    duration,
    plan_length,
    priceId
  } = planObj;
  console.log("Duration on top", duration);
  console.log("Plan length on top", plan_length);

  // const getUserWithId = async () => {
  //   const { data } = apiClient.get(`/users/${user_id}`);
  // };

  const cardHandleChange = (event) => {
    console.log("Card element event", event);
    const { error, elementType, complete } = event;

    setError(error ? error.message : "");

    if (elementType === "cardNumber" && complete) {
      setCardStateForBtn({ ...cardStateForBtn, cardNumberComplete: true });
      //console.log("card State after completing card Number", cardStateForBtn);
    }
    if (elementType === "cardExpiry" && complete) {
      setCardStateForBtn({ ...cardStateForBtn, cardExpiryComplete: true });
      // console.log("card State after completing card Number", cardStateForBtn);
    }
    if (elementType === "cardCvc" && complete) {
      setCardStateForBtn({ ...cardStateForBtn, cardCvcComplete: true });
      // console.log("card State after completing card Number", cardStateForBtn);
    }
  };

  console.log("Is card filled", isCardInformationFilled);
  const handleCheckout = async (clientSecret) => {
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)
      }
    });
    if (payload.error) {
      setError(`Payment Failed: ${payload.error.message}`);
    } else {
      // navigate("/success");
      toast.success("Payment Successfully Collect");
      console.log("Duration", duration);
      console.log("Plan Length", plan_length);

      let values = {
        priceId
      };
      try {
        const res = await apiClient.get("/stripe/subscription-status");
        console.log("user data", res);
        toast.success("Plan purchased successfully");

        console.log("user plan response", res.data.data.user);
        const user = res.data.data.user;
        console.log("user", user);
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/");
      } catch (error) {
        console.error(error.response);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      receipt_email: email,
      description: description,
      cartItem: {
        amount: planPrice * 100
      }
    },
    onSubmit: (values, props) => {
      console.log(values);
      values = {
        priceId
      };
      console.log("create subscription values", values);
      try {
        mutate(
          {
            url: "/stripe/create-subscription",
            values: values
          },
          {
            onSuccess: (res) => {
              console.log("props", props);
              // toast.success("stripe");
              console.log("Stripe response", res);
              handleCheckout(res.data.clientSecret);
            },
            onError: (error) => {
              // alert("An error occured during submitting system");
              console.log("our response", error.response);
              toast.error("Wrong Credit card Information");
              props.setSubmitting(false);
              //toast.error(response.response.data.message);
            }
          }
        );
      } catch (error) {
        console.error(error.response);
      }
    }
  });
  const cardStyle = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000000",
        border: "2px solid black",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#87BBFD"
        }
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE"
      }
    }
  };

  return (
    <div className="stripe-card">
      <Toaster />
      <form>
        <div className="row-of-stripe-elements">
          <div className="single-card-element">
            <label>Card Number</label>
            <CardNumberElement
              className="card-element"
              options={cardStyle}
              onChange={cardHandleChange}
            />
          </div>
          <div className="single-card-element">
            <label>Card Expiry</label>
            <CardExpiryElement
              className="card-element"
              options={cardStyle}
              onChange={cardHandleChange}
              required
            />
          </div>
        </div>

        <div className="single-card-element">
          <label>Card Expiry</label>
          <CardCvcElement
            className="card-element"
            options={cardStyle}
            onChange={cardHandleChange}
            required
          />{" "}
        </div>
        {/** <h4>Address</h4>
        <div className="single-card-element">
          <AddressElement options={{ mode: "shipping" }} />
        </div> */}

        <div className="submit-btn">
          <Button
            className="w-100-margin-20-right"
            type="submit"
            text={formik.isSubmitting ? "Please wait ..." : "Buy"}
            isDisable={
              formik.isSubmitting ||
              (!cardStateForBtn.cardNumberComplete &&
                !cardStateForBtn.cardExpiryComplete &&
                !cardStateForBtn.cardCvcComplete)
            }
            handleSubmit={formik.handleSubmit}
          />
        </div>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default CheckoutForm;
