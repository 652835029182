import "../../assets/scss/AdminHeader.scss";
import user from "../../assets/images/user.jpg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoChevronBackOutline } from "react-icons/io5";
const AdminHeader = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();
  //console.log("user in local storage", user);

  return (
    <>
      <div className="admin-header">
        <div
          className="btn-section"
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoChevronBackOutline size="1.3rem" className="arrow-section" />
          <h3>BACK</h3>
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
