import { useState } from "react";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import AdminHeader from "../Components/Admin/AdminHeader";
import "../assets/scss/Wrapper.scss";
import Button from "../Components/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MockTests = () => {
  const navigate = useNavigate();
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const onFullLengthMockTest = () => {
    let values = {
      time: "360",
      no_of_questions: "300",
      exam
    };
    navigate("/start_mock_test", {
      state: values
    });
  };
  const onHalfLengthMockTest = () => {
    let values = {
      time: "180",
      no_of_questions: "150",
      exam
    };
    navigate("/start_mock_test", {
      state: values
    });
  };
  return (
    <>
      <div className="dashboard-wrapper">
        <Sidebar examName={exam.name} />
        <div className="admin-nested">
          {/* <AdminHeader /> */}
          <div className="mock_test">
            <div className="mock_test-heading">
              <h1 className="mock_test-heading">Exam name Mock Test</h1>
            </div>

            <div className="mock_test-sections">
              <div className="mock_test-sections-left">
                <div className="header">
                  <h2 className="mock_test-sections-heading">
                    Half-Length Mock Test
                  </h2>
                </div>
                <div className="body">
                  <p>Get a taste of the exam with 150 questions and a 3-hour</p>
                  <p>Ideal for those with limited time.</p>
                  <p>- 150 questions </p>
                  <p>- 3 hours time limit </p>

                  <br />
                  <br />
                  <br />

                  <Button text="Start" handleSubmit={onHalfLengthMockTest} />
                </div>
              </div>
              <div className="mock_test-sections-right">
                <div className="header">
                  <h2 className="mock_test-sections-heading">
                    Full-Length Mock Test
                  </h2>
                </div>
                <div className="body">
                  <p>
                    Experience the complete exam simulation with 300 questions
                    and a 6-hour time limit.
                  </p>
                  <p>
                    Perfect for those who want to test their endurance and
                    knowledge in a realistic testing environment.
                  </p>
                  <p>- 300 questions </p>
                  <p>- 6 hours time limit </p>

                  <Button text="Start" handleSubmit={onFullLengthMockTest} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MockTests;
