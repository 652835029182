import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import env from "react-dotenv";
//console.log("Stripe key", env.STRIPE_KEY);
import { ReactQueryDevtools } from "react-query/devtools";

// Sentry Error Tracking
import * as Sentry from "@sentry/react";

const stripePromise = await loadStripe(
  "pk_test_51O3hG6LhGHtS8XcQW9AcaxPDHtaSWE49AVm5087yN6YrZ3bbDEeiG7v7lJRLBIy5cfBu2GdEjk8y1OVsIcqEaOOt00iCe9RG8p"
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 60 * 1000,
      retry: 0
    }
  }
});


Sentry.init({
  dsn: "https://cfdb61a9b2f3c5558790823a13b2bdc0@o4507921929797632.ingest.us.sentry.io/4507923309395968",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient} contextSharing={true}>
    <Elements stripe={stripePromise}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </Elements>
  </QueryClientProvider>
);
