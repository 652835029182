import React from "react";

const CheckoutSuccess = () => {
  return (
    <div>
      <h1>CheckOut Success</h1>
    </div>
  );
};

export default CheckoutSuccess;
