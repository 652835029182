import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Countdown, {
  zeroPad,
  calcTimeDelta,
  formatTimeDelta,
} from "react-countdown";
import "../assets/scss/MyTimer.scss";
import { MdAvTimer } from "react-icons/md";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import { useRef, useId } from "react";
const CountDownTimer = ({ time, timerExpired }) => {
  // const { total } = props;

  const [isTimerStart, setIsTimerStart] = useState(true);
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const clockRef = useRef();
  const id = useId();

  const handleStart = () => clockRef.current.start();
  const handlePause = () => clockRef.current.pause();
  const mytime = React.useMemo(() => {
    return Date.now() + time;
  }, []);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    return (
      <div className="timer-wrapper">
        <MdAvTimer size="3rem" />
        <div className="timer-wrapper-time">
          {/*<span>{days}</span>:<span>{hours}</span>:*/}
          <span>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="timer-wrapper">
      <Countdown
        key={id}
        date={mytime}
        renderer={renderer}
        ref={clockRef}
        onComplete={() => {
          setIsTimerExpired(true);
          timerExpired();
        }}
      />
      {!isTimerExpired ? (
        isTimerStart ? (
          <BsPauseFill
            className={isTimerStart ? "show" : "hide"}
            size="3rem"
            onClick={() => {
              setIsTimerStart(!isTimerStart);
              handlePause();
            }}
          />
        ) : (
          <BsFillPlayFill
            className={isTimerStart ? "hide" : "show"}
            size="3rem"
            onClick={() => {
              setIsTimerStart(!isTimerStart);
              handleStart();
            }}
          />
        )
      ) : null}
    </div>
  );
};

export default CountDownTimer;
