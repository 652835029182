import Button from "../Components/Button";
import "../assets/scss/Login.scss";
import "../assets/scss/utils.scss";
import Logo from "../assets/images/Madgrizz_logo_blue.jpeg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import IconRowInput from "../Components/IconRowInput";
import UserSvg from "../Components/UserSvg";
import LockSvg from "../Components/LockSvg";
import CheckBox from "../Components/CheckBox";
import GoogleSvg from "../Components/GoogleSvg";
import MedgrizzFinalLogo from "../assets/images/medgrizz_final.jpeg";
import usePostMutation from "../Utils/usePostMutation";
import { Formik, Form, useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useState } from "react";
import {
  GoogleLogin,
  useGoogleLogin,
  hasGrantedAllScopesGoogle
} from "@react-oauth/google";
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("Location", location.state.isFromCheckout);
  console.log(
    "Our location isFormChecked",
    typeof location.state.isFromCheckout
  );
  const [user, setUser] = useState();
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);

  const { mutate } = usePostMutation();

  let googleLoginBtn = useGoogleLogin({
    onSuccess: async (response) => {
      console.log("Google response", response);
      let values = {
        token: response.access_token
      };
      try {
        const userInfo = await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: { Authorization: `Bearer ${response.access_token}` }
          })
          .then((res) => res.data);
        console.log(userInfo);
        mutate(
          {
            url: "users/login_with_google",
            values: userInfo
          },
          {
            onSuccess: (res) => {
              // console.log("props", props);
              //toast.success("Logged in successfully");
              console.log("response", res);
              const token = res.data.data.token;
              const user = res.data.data.user;
              console.log("user", user);
              console.log("token", token);
              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(user));
              navigate("/");
              // window.location.reload(false);
            },
            onError: (error) => {
              // alert("An error occured during submitting system");
              console.log("our response", error.response);
            }
          }
        );
      } catch (error) {
        console.error(error.response);
      }
    },

    onError: (error) => {
      console.log(error);
    },
    useOneTap: true
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      user_name: "",
      full_name: ""
    },
    onSubmit: (values, props) => {
      console.log(values);
      console.log("Register open", isRegisterOpen);

      const formData = new FormData();
      const url = isRegisterOpen ? "users/signup" : "users/signin";
      const user = isRegisterOpen ? formData : values;
      console.log("Url is ", url);
      if (isRegisterOpen) {
        const { user_name, full_name, email, password, profile_image } = values;
        formData.append("user_name", user_name);
        formData.append("full_name", full_name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("passwordConfirm", password);
        formData.append("profile_image", null);
      }
      console.log("User data", user);
      try {
        mutate(
          {
            url: url,
            values: user,
            head: {
              headers: {
                "Content-Type": isRegisterOpen
                  ? "multipart/form-data"
                  : "application/json"
              }
            }
          },
          {
            onSuccess: (res) => {
              console.log("props", props);
              toast.success("Logged in successfully");
              console.log("response", res);
              const token = res.data.data.token;
              const user = res.data.data.user;
              console.log("user", user);
              console.log("token", token);
              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(user));
              props.setSubmitting(false);
              if (user.role === "admin") {
                navigate("/dashboard/exam/all");
              } else {
                navigate("/");
              }
            },
            onError: (error) => {
              // alert("An error occured during submitting system");
              console.log("our response", error.response);
              toast.error("Invalid email or Password");
              props.setSubmitting(false);
              //toast.error(response.response.data.message);
            }
          }
        );
      } catch (error) {
        console.error(error.response);
      } finally {
        console.log("done");
      }
    }
  });
  return (
    <>
      <Toaster />

      <div className="login-form-design">
        <div className="login-form-design__form">
          <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className="logo">
              <img src={MedgrizzFinalLogo} alt="this is logo" />
            </div>
            <h1 className="colors primary_heading mt-20">
              {isRegisterOpen ? "Register" : "Login"}
            </h1>
            {isRegisterOpen ? (
              <>
                <IconRowInput
                  id="user_name"
                  name="user_name"
                  icon={<UserSvg className="icon" />}
                  placeholder="User name"
                  type="text"
                  value={formik.values.user_name}
                  handleChange={formik.handleChange}
                />
                <IconRowInput
                  id="full_name"
                  name="full_name"
                  icon={<UserSvg className="icon" />}
                  placeholder="Full name"
                  type="email"
                  value={formik.values.full_name}
                  handleChange={formik.handleChange}
                />
              </>
            ) : null}
            <IconRowInput
              id="email"
              name="email"
              icon={<UserSvg className="icon" />}
              placeholder="Email"
              type="email"
              value={formik.values.email}
              handleChange={formik.handleChange}
            />
            <IconRowInput
              icon={<LockSvg className="icon" />}
              placeholder="password"
              type="password"
              id="password"
              name="password"
              value={formik.values.password}
              handleChange={formik.handleChange}
            />
            {isRegisterOpen ? (
              <>
                <IconRowInput
                  icon={<LockSvg className="icon" />}
                  placeholder="Confirm password"
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  handleChange={formik.handleChange}
                />

                {/* <IconRowInput
                  icon={<LockSvg className="icon" />}
                  placeholder="Profile Image"
                  type="file"
                  id="profile_image"
                  name="profile_image"
                  value={formik.values.profile_image}
                  //handleChange={formik.handleChange}
                  handleChange={(event) => {
                    formik.setFieldValue(
                      "profile_image",
                      event.currentTarget.files[0]
                    );
                  }}
                /> */}
              </>
            ) : (
              <div className="form-row">
                <Link to="/reset_password">forgot password</Link>
                <div className="remember_me">
                  <p>Remember me</p>
                  <CheckBox />
                </div>
              </div>
            )}

            <Button
              className="w-100"
              type="submit"
              text={formik.isSubmitting ? "Please wait ..." : "Sign In"}
              isDisable={formik.isSubmitting}
              handleSubmit={formik.handleSubmit}
            />

            <div className="form-row">
              <h3>Or Sign Up With</h3>
            </div>
            <div className="logos_row">
              <GoogleSvg handleClick={() => googleLoginBtn()} />
              <img
                className="medgrizz"
                src={Logo}
                alt="This is medgrizz logo"
                onClick={() => {
                  setIsRegisterOpen(!isRegisterOpen);
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
