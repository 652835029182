import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import toast, { Toaster } from "react-hot-toast";
import "../assets/scss/Home.scss";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import moment from "moment";
import TopMenu from "../Components/TopMenu";
import DeletionModal from "../Components/DeletionModal";
import Footer from "../Components/Footer";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../Components/Loader";
import usePostMutation from "../Utils/usePostMutation";
import { AiOutlineDelete } from "react-icons/ai";

const SubscribedPlans = () => {
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isDeletedModalOpen, setISDeletedModalOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1000);
  const { mutate } = usePostMutation();
  const getAllUserSubscriptions = async () => {
    const { data } = await apiClient.get("/stripe/user-subscription");
    return data;
  };
  const allUserSubscriptions = useQuery(
    ["userSubscriptions"],
    getAllUserSubscriptions,
    {
      retry: 1,
      onSuccess: (res) => {
        console.log("response of exam on success in home page", res);
      },
      onError: (res) => {
        console.log("response of exam on error", res);
      }
    }
  );

  const cancelSubscription = (id) => {
    console.log("Subscription id", id);
    mutate(
      {
        url: `/stripe/delete-subscription/${id}`,
        method: "delete",
        values: null
      },
      {
        onSuccess: async (res) => {
          toast.success("Subscription cancelled successfully");
          //alert("Exam deleted successfully");
          const re = await apiClient.get("/stripe/subscription-status");
          console.log("user data", re);
          toast.success("Plan purchased successfully");

          console.log("user plan response", re.data.data.user);
          const user = re.data.data.user;
          console.log("user", user);
          localStorage.setItem("user", JSON.stringify(user));
          setOpenIndex(-99999);
          allUserSubscriptions.refetch();
        },
        onError: (err) => {
          toast.error("Error while cancelling subscription");
          // alert("Error");
          console.log(err);
        }
      }
    );
  };
  if (allUserSubscriptions.isError) {
    return <h1>No subscription found Please try again later</h1>;
  }
  if (allUserSubscriptions.isLoading) {
    return <Loader />;
  }
  if (allUserSubscriptions.isSuccess) {
    console.log("All Subscription plans", allUserSubscriptions);
    return (
      <div className="subscribed-page-design">
        <TopMenu />
        <div className="goToPlan">
          <div className="cancel-plan-heading-main">
            <h1>Cancel Plans</h1>
          </div>

          <div className="subscribed-plans">
            {allUserSubscriptions.data.data.subscriptions.length === 0 ? (
              <h1>Not registered exam</h1>
            ) : (
              allUserSubscriptions.data.data?.subscriptions.map(
                (sub, index) => {
                  console.log("sub", sub);
                  return (
                    <div className="single-subscription" key={index}>
                      <h5>{sub.items.data[0].plan.nickname}</h5>
                      <h5>
                        {sub.plan.amount / 100 + " $"} /{" "}
                        {sub.items.data[0].price.recurring.interval_count +
                          " " +
                          sub.items.data[0].price.recurring.interval}
                      </h5>
                      <h5>
                        {moment(sub.current_period_end * 1000)
                          .format("MMMM Do YYYY h:mm:ss a")
                          .toString()}
                      </h5>
                      {openIndex === index ? (
                        <DeletionModal
                          title={sub.plan.nickname}
                          del={() => cancelSubscription(sub.id)}
                          hide={() => setOpenIndex(-99999)}
                        />
                      ) : (
                        <AiOutlineDelete
                          size="1.5rem"
                          onClick={() => {
                            setOpenIndex(index);
                            setISDeletedModalOpen(!isDeletedModalOpen);
                          }}
                        />
                      )}
                    </div>
                  );
                }
              )
            )}
          </div>
        </div>
        <div className="footer">
          <Footer isSmall={true} />
        </div>
      </div>
    );
  }
};

export default SubscribedPlans;
