import { useState } from "react";
import TopMenu from "../Components/TopMenu";
import AboutUsImage from "../assets/images/aboutUs.jpeg";
import "../assets/scss/StaticPagesWrapper.scss";
import Footer from "../Components/Footer";
const AboutUs = () => {
  const len = localStorage.getItem("language");
  const [language, setLanguage] = useState(len);
  const aboutUsData = {
    en: [
      `At MedGrizz, we recognize the challenges students face while
            preparing for the NL exam, and we're dedicated to simplifying the
            process. Our platform is designed with one goal in mind: to optimize
            your study experience and ensure your success.`,
      `Research shows that active recall – actively retrieving information
            from memory – is a highly effective learning method. MedGrizz offers
            a comprehensive question bank that simulates the NL exam, enabling
            you to practice active recall and solidify your understanding. This
            approach is far more powerful than passive learning techniques, like
            reading or highlighting.`,
      `Our practice exams mirror the actual NL test in length, wording, and
            content, providing you with an accurate assessment of your readiness
            and helping you develop essential pacing strategies. By pinpointing
            your strengths and weaknesses, MedGrizz empowers you to customize
            your study plan and maximize your preparation efforts.`,
      `Beyond content mastery, MedGrizz focuses on refining your
            test-taking skills – a crucial, yet often neglected, aspect of exam
            success. Our platform equips you with techniques to confidently
            tackle the exam.`,
      `   At MedGrizz, we're committed to not only enhancing your NL exam
            performance but also fostering your growth as a future physician.
            With our guidance, resources, and innovative approach, you'll gain
            the edge you need to excel. Embark on your MedGrizz journey today
            and experience the transformative impact on your exam preparation.`
    ],
    thai: [
      `ที่ MedGrizz พี่ตระหนักดีถึงความท้าทายทีน้องนิสิตและนักศึกษาเผชิญขณะเตรียมตัวสอบ National License (NL) พวกพี่จึงมีความมุ่งมั่นที่จะทำให้กระบวนการมันง่ายขึ้น แพลตฟอร์มนี้จึงได้รับการออกแบบโดยคำนึงถึงเป้าหมายเดียว: เพื่อเพิ่มประสิทธิภาพในการศึกษาเนื้อหาและเพิ่มคะแนน NL ให้น้องๆทุกคนครับ`,
      `การวิจัยแสดงให้เห็นว่าการเรียกคืนโดยวิธี active recall – การดึงข้อมูลจากหน่วยความจำระหว่างทำข้อสอบ – เป็นวิธีการเรียนรู้ที่มีประสิทธิภาพสูง MedGrizz จึงถูกสร้างขึ้นมาเพื่อเป็นคลังคำถามที่ครอบคลุมซึ่งจำลองการสอบ NL ซึ่งจะช่วยให้น้องๆเพิ่มทั้งคะแนนและโอกาศในการผ่าน แนวทางนี้มีประสิทธิภาพมากกว่าเทคนิคการเรียนรู้แบบพาสซีฟ เช่น การอ่านหรือการไฮไลท์ข้อความ`,
      `ข้อสอบฝึกหัดของเราสะท้อนโจทย์ที่มีใน NL จริงทั้งในด้านความยาว การใช้คำ และเนื้อหา เพื่อให้น้องประเมินความพร้อมได้อย่างแม่นยำ และช่วยให้น้องในการพัฒนากลยุทธ์ในการทำข้อสอบ นอกจากนี้หลังจากทำข้อสอบเสร็จแล้ว MedGrizz ยังมีการระบุจุดแข็งและจุดอ่อนเพื่อช่วยให้น้องได้เข้าใจว่าตรงไหนควรจะต้องไปอ่านเพิ่มเติมเพื่อเพิ่มประสิทธิภาพในการทบทวนหนังสือ`,
      `นอกเหนือจากความเชี่ยวชาญด้านเนื้อหาแล้ว MedGrizz มีระบบ mock-exam (แบบครึ่งข้อสอบ 3 ชั่วโมง 150ข้อ และแบบเต็มข้อสอบ 6 ชั่วโมง 300 ข้อ) ที่จะช่วยขัดเกลาทักษะการทำข้อสอบของน้อง น้องๆจะได้เข้าใจว่าในการทำข้อสอบจริงนั้นน้องจะต้องทำข้อนึงเร็วขนาดไหนเพื่อให้มีเวลาได้กลับไปทวน ซึ่งเป็นแง่มุมที่สำคัญในการสอบที่มักถูกละเลยโดยที่ไม่รู้ตัว แพลตฟอร์มนี้ช่วยให้น้องมีเทคนิคในการทำข้อสอบอย่างมั่นใจ`,
      `พี่หวังว่าแพลตฟอร์ม MedGrizz จะเป็นตัวช่วยให้น้องที่มีความตั้งใจจริงสอบผ่าน NL ได้ฉลุยและจบไปเป็นแพทย์ที่ดีและได้ช่วยเหลือผู้ป่วยในอนาคตได้ครับ 
`
    ]
  };
  const aboutUsHeading = {
    en: "About Us",
    thai: "เกี่ยวกับ MedGrizz"
  };
  const languageHandler = (val) => {
    console.log("Language is ", val);
    setLanguage(val);
  };
  return (
    <>
      <TopMenu languageHandler={languageHandler} />
      <div className="imageWrapper">
        <div className="terms-image about_us_image"></div>
        <div className="main-heading">
          <h1>
            {language === "thai" ? aboutUsHeading.thai : aboutUsHeading.en}
          </h1>
        </div>
      </div>
      <div className="generic-list">
        <div className="list-item-card">
          <p className="card-text">
            {language === "thai"
              ? aboutUsData.thai.map((item, index) => {
                  return (
                    <>
                      <p key={index}>{item}</p>
                    </>
                  );
                })
              : aboutUsData.en.map((item, index) => {
                  return (
                    <>
                      <p key={index}>{item}</p>
                    </>
                  );
                })}

            <br />
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
