import React from "react";
import "../assets/scss/DeletionModal.scss";
import Button from "../Components/Button";
const DeletionModal = ({ title, del, hide, isExam = false }) => {
  return (
    <div className="medelMainWrapper">
      <div className="modelWrapper">
        <div className="modelHeader">
          <p>{isExam ? title : `Do you want to delete ${title}?`}</p>
        </div>
        <div className="modalBtns">
          <Button text="Yes" handleSubmit={del} />
          <Button text="No" handleSubmit={hide} />
        </div>
      </div>
    </div>
  );
};

export default DeletionModal;
