import MyCustomSidebar from "../../Components/Admin/MySidebar/MyCustomSidebar";
import AdminHeader from "../../Components/Admin/AdminHeader";
import "../../assets/scss/Wrapper.scss";
import ResolvedAction from "../../Components/ResolvedAction";
import TableActionLink from "../../Components/TableActionLink";
import FormRowSelect from "../../Components/FormRowSelect";
import { Formik, Form } from "formik";
import apiClient from "../../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader";
import { useEffect, useState } from "react";
import useReportData from "../../Helpers/useReportData";
import Button from "../../Components/Button";
import DetailAction from "../../Components/DetailAction";
const Report = () => {
  const [filters, setFilters] = useState({});
  const list_report_topic = [];
  const sorting_list = ["sort"];
  const resolved_list = ["resolved", "unresolved"];
  const reportList = [
    "Question Clarity",
    "Answer Inaccuracy",
    "Explanation Mistakes",
    "Bug",
    "Other"
  ];
  const getSystems = async () => {
    const { data } = await apiClient.get("/systems");
    return data;
  };
  const getSubjects = async () => {
    const { data } = await apiClient.get("/subjects");
    return data;
  };
  const getReport = async () => {
    const { data } = await apiClient.get("/reports");
    return data;
  };
  const getAllSystems = useQuery(["systems"], getSystems);
  const getAllSubjects = useQuery(["subjects"], getSubjects);
  const getAllDropdownReports = useQuery(["dropDownReports"], getReport, {
    refetchOnWindowFocus: false
  });

  const getAllReports = useReportData(filters);
  useEffect(() => {
    //setFilters({});
    getAllReports.refetch();
  }, [filters]);
  // useEffect(() => {
  //   getAllReports.refetch();
  // }, []);
  if (getAllReports.isFetching) {
    return <Loader />;
  }
  if (
    getAllReports.isError &&
    getAllSystems.isError &&
    getAllSubjects.isError
  ) {
    return (
      <div className="dashboard-wrapper">
        <MyCustomSidebar />
        <div className="admin-nested">
          <AdminHeader />

          {/** This is inner page */}
          <div className="form-wrapper">
            <div className="dashboard-table">
              <div className="sorting_wrapper">
                <h1>Report</h1>
                <div className="sorted_actions">
                  <Formik
                    initialValues={{
                      title: "",
                      system_id: "",
                      subject_id: "",
                      date: "",
                      resolved: ""
                    }}
                    onSubmit={(values) => {
                      console.log(values);
                      console.log("we are selecting the system", values);
                      setFilters(values);
                      console.log("filters", filters);
                    }}
                  >
                    {(formik) => (
                      <Form onSubmit={formik.handleSubmit}>
                        <h3>Sort By:</h3>
                        <FormRowSelect
                          name="title"
                          id="title"
                          label="Report Topic"
                          list={list_report_topic}
                        />
                        <FormRowSelect label="System" />
                        <FormRowSelect label="Subject" />
                        <FormRowSelect
                          label="Sorted By Date"
                          list={sorting_list}
                        />
                        <FormRowSelect label="Status" list={resolved_list} />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <br />
              <h1>We could not find any data</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (
    getAllReports.isSuccess &&
    getAllSystems.isSuccess &&
    getAllSubjects.isSuccess &&
    getAllDropdownReports.isSuccess
  ) {
    console.log("reports", getAllDropdownReports);
    return (
      <div className="dashboard-wrapper">
        <MyCustomSidebar />
        <div className="admin-nested">
          {/* <AdminHeader /> */}

          {/** This is inner page */}
          <div className="form-wrapper">
            <div className="dashboard-table">
              <div className="sorting_wrapper">
                <h1>Report</h1>
                <div className="sorted_actions_wrapper">
                  <h3>Sort By:</h3>
                  <Formik
                    initialValues={{
                      title: "",
                      system_id: "",
                      subject_id: "",
                      date: "",
                      resolved: ""
                    }}
                    onSubmit={(values) => {
                      console.log("we are selecting the system", values);
                      setFilters(values);
                      console.log("filters", filters);
                    }}
                  >
                    {(formik) => (
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="sorted_actions">
                          <FormRowSelect
                            name="title"
                            id="title"
                            label="Report Topic"
                            list={reportList}
                            // isApiData={true}
                          />
                          <FormRowSelect
                            id="system_id"
                            label="System"
                            list={getAllSystems.data.data.systems}
                            name="system_id"
                            isApiData="true"
                          />
                          <FormRowSelect
                            id="subject_id"
                            label="Subject"
                            list={getAllSubjects.data.data.subjects}
                            name="subject_id"
                            isApiData="true"
                          />
                          <FormRowSelect
                            id="date"
                            label="Sort By Date"
                            list={sorting_list}
                            name="date"
                          />
                          <FormRowSelect
                            id="resolved"
                            name="resolved"
                            label="Sort By Status"
                            list={resolved_list}
                          />
                          <div className="">
                            <Button type="submit" text="Search" />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <table id="customers">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Report Topic</th>
                    <th>Subject Area</th>
                    <th>System</th>
                    <th>Reported Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllReports.data.data.reports.map((r, index) => {
                    return (
                      <tr key={r._id}>
                        <td>{r?.question_id?.title}</td>
                        <td>{r.title}</td>
                        <td>
                          {r.question_id !== null
                            ? r.question_id.system_id !== null
                              ? r.question_id.system_id.name
                              : "no system found"
                            : null}
                        </td>
                        <td>
                          {r.question_id !== null
                            ? r.question_id.subject_id.name
                            : null}
                        </td>
                        <td>{r.createdAt}</td>

                        <td className="actions">
                          {r.resolved === true ? (
                            <TableActionLink
                              text=" Already Resolved"
                              icon={<ResolvedAction />}
                              redirect={`/dashboard/reports/report_details/${r._id}`}
                            />
                          ) : (
                            <TableActionLink
                              icon={<DetailAction />}
                              text="unresolve"
                              redirect={`/dashboard/reports/report_details/${r._id}`}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Report;
