import React, { useEffect } from "react";
import "../../../assets/scss/MyCustomSidebar.scss";
import { useState } from "react";
import { CiMemoPad } from "react-icons/ci";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { GiPriceTag } from "react-icons/gi";
import { FaHandsHelping } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/Madgrizz_logo_blue.jpeg";
import { SlUser } from "react-icons/sl";
const MyCustomSidebar = () => {
  const navigate = useNavigate();
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [isOpenLogoutMenu, setisOpenLogoutMenu] = useState(false);
  console.log("stored user is", userStored.profile_image);

  return (
    <div className="admin-main-sidebar">
      <nav className="admin-main-sidebar__nav">
        <div className="nav_logo_admin">
          <img
            src={logo}
            alt="our medgrizz logo"
            onClick={() => navigate("/")}
          />
        </div>
        <ul className="menu">
          <li>
            <Link to="/dashboard/exam/all">
              <CiMemoPad size="2rem" className="icon" />
              <span>Exam</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard/reports/report">
              <GiPriceTag size="2rem" className="icon" />
              <span>Report</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard/user/all">
              <BiUser size="2rem" className="icon" />
              <span>User</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard/help/help_center">
              <FaHandsHelping size="2rem" className="icon" />
              <span>Help Center</span>
            </Link>
          </li>

          <Outlet />
        </ul>
      </nav>
      <div
        className="user-section"
        onMouseLeave={() => setisOpenLogoutMenu(!isOpenLogoutMenu)}
      >
        {userStored ? (
          userStored?.profile_image ? (
            <img
              // src={`http://localhost:4000/userImages/${userStored.profile_image}`}
              src={`https://www.medgrizz.com/userImages/${userStored?.profile_image}`}
              alt="user"
            />
          ) : userStored?.google_image ? (
            <img
              src={`${userStored.google_image}`}
              // src={`https://www.medgrizz.com/userImages/${userStored.profile_image}`}
              alt="user"
            />
          ) : (
            <SlUser size="1.5em" />
          )
        ) : null}
        <h6>{userStored ? userStored?.user_name : "user"}</h6>
        <MdOutlineKeyboardArrowUp
          className="myIcon"
          size="2rem"
          onClick={() => setisOpenLogoutMenu(!isOpenLogoutMenu)}
        />
        <div
          className={isOpenLogoutMenu ? "arrow-section show" : "arrow-section"}
        >
          <ul>
            <li
              onClick={() => {
                navigate("/profile");
              }}
            >
              Profile
            </li>
            <li
              onClick={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("token");

                navigate("/");
              }}
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MyCustomSidebar;
