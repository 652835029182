import AdminHeader from "../Components/Admin/AdminHeader";
import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { BsBoxArrowInRight, BsSkipEndBtn } from "react-icons/bs";
import { useState } from "react";
const HelpAndSupportDetails = () => {
  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const navigate = useNavigate();
  return (
    <>
      <div className="dashboard-wrapper">
        <Sidebar examName={exam.name} />
        <div className="admin-nested">
          <div className="help-and-support-detail">
            <h1>Help and Support</h1>
            <div className="help-and-support-detail-message">
              <div className="help-and-support-detail-message-content">
                <h1>Thank You very much!</h1>
                <h2>
                  We have received the form and will br contacting you back
                  through email as soon as we possibly could !
                </h2>
                <h2>Medgrizz Team</h2>
                <div
                  className="back-btn-wrapper"
                  onClick={() => {
                    navigate("/help_and_support");
                  }}
                >
                  <BsBoxArrowInRight size="4rem" />
                  Go Back
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupportDetails;
