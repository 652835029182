// icon:flag-variant | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconFlagVariant(props) {
  return (
    <svg viewBox="0 0 24 24" fill="red" height="2.5em" width="2.5em" {...props}>
      <path d="M6 3a1 1 0 011 1v.88C8.06 4.44 9.5 4 11 4c3 0 3 2 5 2 3 0 4-2 4-2v8s-1 2-4 2-3-2-5-2c-3 0-4 2-4 2v7H5V4a1 1 0 011-1z" />
    </svg>
  );
}

export default IconFlagVariant;
