import "../../assets/scss/Wrapper.scss";
import FormHeader from "../../Components/FormHeader";
import FormRowInput from "../../Components/FormRowInput";
import Button from "../../Components/Button";
import usePostMutation from "../../Utils/usePostMutation";
import { useFormik, Formik, FieldArray, Form, FormikProvider } from "formik";
import toast, { Toaster } from "react-hot-toast";
import apiClient from "../../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader";
import PlanRowSelect from "../../Components/PlanRowSelect";
import { AiFillDelete } from "react-icons/ai";
import { useState } from "react";
const AddExam = () => {
  const { mutate } = usePostMutation();
  const [planCounter, setPlanCounter] = useState(0);

  const getAllStripePlans = async () => {
    const { data } = await apiClient.get("/stripe/all_plans");
    return data;
  };
  const allPlans = useQuery(["exams"], getAllStripePlans, {
    retry: 1,
    onSuccess: (res) => {
      //console.log("response of exam on success in home page", res);
    },
    onError: (res) => {
      //console.log("response of exam on error", res);
    }
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      plans: []
    },
    onSubmit: (values, props) => {
      console.log("values of add exam", values);
      mutate(
        {
          url: "/exams",
          values: values
        },
        {
          onSuccess: () => {
            //alert("Form submitted successfully");
            toast.success("Exam added successfully");
            props.setSubmitting(false);
          },
          onError: (response) => {
            // alert("An error occured while submitting exam");
            toast.error("An error occured while submitting exam");
            props.setSubmitting(false);
          }
        }
      );
    }
  });
  const selectHandler = (val) => {
    console.log("Selected Value", allPlans.data.data.subscriptions[val]);
    let ourSelectedPlan = allPlans.data.data.subscriptions[val];

    const value = formik.values.plans.findIndex(
      (p) => p.id === ourSelectedPlan.id
    );
    if (value < 0) {
      const { id, unit_amount } = ourSelectedPlan;
      const { name, description } = ourSelectedPlan.product;
      console.log("Product ", ourSelectedPlan.product);
      let modifiedObj = { id, name, description, unit_amount };
      formik.values.plans.push(modifiedObj);
      setPlanCounter((prev) => prev + 1);
    }
    console.log("If value exists in array or not", value);

    console.log("Plans", formik.values.plans);
  };
  const PlanDeleteHandler = (id) => {
    const value = formik.values.plans.findIndex((p) => p.id === id);
    if (value > -1) {
      //
      formik.values.plans.splice(value, 1);
      setPlanCounter((prev) => prev - 1);
    }
  };
  if (allPlans.isLoading) {
    return <Loader />;
  }
  if (allPlans.isError) {
    return <h1>Error while loading plans</h1>;
  }
  if (allPlans.isSuccess) {
    console.log("All Plans", allPlans);
    return (
      <div className="form-wrapper">
        <Toaster />
        <FormikProvider value={formik}>
          <form className="dashboard-form" onSubmit={formik.handleSubmit}>
            <FormHeader text="Exam" />
            <FormRowInput
              label="Exam Name"
              placeholder="Please enter exam name"
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <div className="selection_area">
              <PlanRowSelect
                id="type"
                label="Select Plan"
                list={allPlans.data.data.subscriptions}
                name="type"
                isApiData="true"
                value={formik.values.type}
                planSelectedHandler={selectHandler}
              />
            </div>

            <h5 className="mt-20">Pricing and Plans</h5>
            <div className="adding_plans_wrapper">
              {formik.values.type}
              {formik.values.plans.map((p) => {
                return (
                  <div className="plan_added" key={p.id}>
                    <h4>{p.name}</h4>
                    <div
                      className="delete-icon"
                      onClick={() => PlanDeleteHandler(p.id)}
                    >
                      <AiFillDelete />
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              text={formik.isSubmitting ? "Loading ..." : "Add Exam"}
              type="submit"
              isDisable={formik.isSubmitting}
              handleSubmit={formik.handleSubmit}
            />
          </form>
        </FormikProvider>
      </div>
    );
  }
};

export default AddExam;
