import React, { useEffect } from "react";
import FormRowInput from "../../Components/FormRowInput";
import MyCustomSidebar from "../../Components/Admin/MySidebar/MyCustomSidebar";
import AdminHeader from "../../Components/Admin/AdminHeader";
import "../../assets/scss/Wrapper.scss";
import TableActionLink from "../../Components/TableActionLink";
import ResolvedAction from "../../Components/ResolvedAction";
import { useParams } from "react-router-dom";
import Button from "../../Components/Button";
import apiClient from "../../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader";
import { Formik, Form } from "formik";
import usePostMutation from "../../Utils/usePostMutation";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const HelpCenterDetails = () => {
  const { id } = useParams();
  const { mutate } = usePostMutation();
  const navigate = useNavigate();
  const getHelp = async () => {
    const { data } = await apiClient(`/help/${id}`);
    return data;
  };
  const getHelpData = useQuery(["help"], getHelp);
  useEffect(() => {
    getHelpData.refetch();
  }, []);
  const handleUpdate = async (id, status) => {
    toast.success("resolving");
    toast.success(status);
    console.log("Current Status", status);
    mutate(
      {
        method: "patch",
        url: `/help/${id}`,
        values: {
          status: !status
        }
      },
      {
        onSuccess: (res) => {
          console.log("api response", res.data.message);
          //alert("Form submitted successfully");
          toast.success(res.data.message);

          navigate(-1);
        },
        onError: (response) => {
          //alert("An error occured while submiting the form");
          toast.success("An error occured while sending reply");
          console.log(response);
        }
      }
    );
  };
  if (getHelpData.isFetching) {
    return <Loader />;
  }

  if (getHelpData.isError) {
    return (
      <div className="dashboard-wrapper">
        <MyCustomSidebar />
        <div className="admin-nested">
          <AdminHeader label="Help Center Details" />
          {/** This is inner page */}
          <div className="form-wrapper">
            <div className="dashboard-Data">
              <h1>No report found </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (getHelpData.isSuccess) {
    console.log("help", getHelpData);
    return (
      <div className="dashboard-wrapper">
        <MyCustomSidebar />
        <div className="admin-nested">
          <AdminHeader label="Help Center Details" />
          {/** This is inner page */}
          <div className="form-wrapper">
            <div className="dashboard-Data">
              <div className="report_row">
                <h1>Help Center Details</h1>
                <TableActionLink
                  icon={<ResolvedAction />}
                  text={
                    getHelpData.data.data.helps?.resolved
                      ? "UnResolve"
                      : "Resolved"
                  }
                  handleSubmit={() =>
                    handleUpdate(
                      getHelpData.data.data.helps?._id,
                      getHelpData.data.data.helps?.resolved
                    )
                  }
                />
              </div>

              <div className="help-center-detail-item">
                <h3>Status: </h3>
                <h5>
                  {getHelpData.data.data.helps?.resolved
                    ? "Resolved"
                    : "Unresolved"}
                </h5>
              </div>
              <div className="help-center-detail-item">
                <h3>Full Name: </h3>
                <h5>{getHelpData.data.data.helps.user_id?.full_name}</h5>
              </div>
              <div className="help-center-detail-item">
                <h3>User Name: </h3>
                <h5>{getHelpData.data.data.helps.user_id?.user_name}</h5>
              </div>
              <div className="help-center-detail-item">
                <h3>Email: </h3>
                <h5>{getHelpData.data.data.helps.user_id?.email}</h5>
              </div>
              <div className="help-center-detail-item">
                <h3>Peport topic: </h3>
                <h5>{getHelpData.data.data.helps?.title}</h5>
              </div>

              <h3>Report:</h3>
              <p>{getHelpData.data.data.helps?.issue}</p>
              {getHelpData.data.data.helps?.resolved === false ? (
                <Formik
                  enableReinitialize
                  initialValues={{
                    email_body: "",
                    email: getHelpData.data.data.helps.user_id?.email,
                    status: getHelpData.data.data.helps?.resolved
                  }}
                  onSubmit={(values, props) => {
                    console.log("submitted values", values);
                    // console.log(id);
                    mutate(
                      { method: "patch", url: `/help/${id}`, values: values },
                      {
                        onSuccess: (res) => {
                          console.log("api response", res.data.message);
                          //alert("Form submitted successfully");
                          toast.success(res.data.message);
                          props.setSubmitting(false);
                          navigate(-1);
                        },
                        onError: (response) => {
                          //alert("An error occured while submiting the form");
                          toast.success("An error occured while sending reply");
                          console.log(response);
                          props.setSubmitting(false);
                        }
                      }
                    );
                  }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <FormRowInput
                        label="Send reply email back to user"
                        id="email_body"
                        name="email_body"
                        classes="full"
                        value={props.values.email_body}
                        onChange={props.handleChange}
                      />

                      <Button
                        text={props.isSubmitting ? "Sending ..." : "Send reply"}
                        type="submit"
                        isDisable={props.isSubmitting}
                        handleSubmit={props.handleSubmit}
                      />
                      <Toaster />
                    </form>
                  )}
                </Formik>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default HelpCenterDetails;
