import React, { useEffect } from "react";
import TopMenu from "../Components/TopMenu";
import Footer from "../Components/Footer";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../Components/Loader";
import "../assets/scss/Home.scss";
import { useNavigate } from "react-router-dom";
import Button from "../Components/Button";
const GoToExam = () => {
  const navigate = useNavigate();
  const goToPracticeQuestion = (examId, examName, examType) => {
    console.log("sending examId", examId);
    console.log("sending examName", examName);
    console.log("sending examType", examType);
    localStorage.setItem(
      "exam",
      JSON.stringify({
        _id: examId,
        name: examName
      })
    );
    navigate("/practice_questions", {
      state: {
        examId,
        examName,
        examType
      }
    });
  };
  const getAllStripePlans = async () => {
    const { data } = await apiClient.get("/exams/");
    return data;
  };
  const getAllUserSubscriptions = async () => {
    const { data } = await apiClient.get("/stripe/user-subscription");
    return data;
  };
  let userAlreadySubscribedPlans = [];

  const allUserSubscriptions = useQuery(
    ["userSubscriptionsforGoToExam"],
    getAllUserSubscriptions,
    {
      retry: 1,
      onSuccess: (res) => {
        console.log("response of exam on success in home page", res);
      },
      onError: (res) => {
        console.log("response of exam on error", res);
      }
    }
  );
  if (allUserSubscriptions.isSuccess) {
    allUserSubscriptions.data.data?.subscriptions.map((sub) => {
      return userAlreadySubscribedPlans.push(sub.plan.id);
    });
  }
  console.log("User already purchased", userAlreadySubscribedPlans);
  const allExams = useQuery(["examsinoursystem"], getAllStripePlans, {
    retry: 2,
    onSuccess: (res) => {
      //console.log("response of exam on success in home page", res);
    },
    onError: (res) => {
      //console.log("response of exam on error", res);
    }
  });
  useEffect(() => {
    allExams.refetch();
    allUserSubscriptions.refetch();
  }, []);
  if (
    allExams.isLoading &&
    allExams.isLoading &&
    allUserSubscriptions.isLoading
  ) {
    return <Loader />;
  }
  if (allExams.isError) {
    console.log("An error occured");
    return <h1>An error occured please try again later</h1>;
  }
  if (allExams.isSuccess && allUserSubscriptions.isSuccess) {
    console.log("All exams", allExams);
    let ourData = new Set();
    allExams.data.data?.exams.map((exam, index) => {
      exam.plan_ids?.map((plan, i) => {
        console.log("Plan", userAlreadySubscribedPlans.includes(plan.id));
        let isExisted = userAlreadySubscribedPlans.includes(plan.id);
        if (isExisted) {
          ourData.add(exam);
        }
      });
    });
    console.log("Our data", ourData);
    ourData = [...ourData];
    return (
      <div>
        <TopMenu />
        <div className="goToPlan">
          <div className="cancel-plan-button">
            <h1>Purchased Exam</h1>
            <Button
              text="Cancel Plan"
              className="w-fit"
              handleSubmit={() => navigate("/cancel_plans")}
            />
          </div>
          <div className="subscribed-plans">
            {allExams.data.data.exams.length === 0 ? (
              <h1>Not registered exam</h1>
            ) : (
              <div className="subscribed-plans">
                {ourData.map((exam, index) => {
                  console.log("exam", exam);
                  return (
                    <div className="single-purchsed" key={index}>
                      <h5>{exam.name}</h5>
                      <button
                        className="manage-btn"
                        onClick={() =>
                          goToPracticeQuestion(exam._id, exam.name, "exam")
                        }
                      >
                        Take Exam
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="footer" isSmall={true}>
          <Footer />
        </div>
      </div>
    );
  }
};

export default GoToExam;
