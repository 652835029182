import "../assets/scss/ButtonAction.scss";
import { Link } from "react-router-dom";
const ButtonAction = ({ text = "button", className, handleSubmit }) => {
  return (
    <Link className={`btn-donate ${className}`} onClick={handleSubmit}>
      {text}
    </Link>
  );
};

export default ButtonAction;
