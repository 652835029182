import { useState } from "react";
import "../assets/scss/FormRowSelect.scss";
import { Field } from "formik";
const PlanRowSelect = ({
  label = "Select label",
  list,
  name,
  isApiData = false,
  classesName,
  wrapperWidthToFitContent,
  isRequired = false,
  isError = false,
  isFromStripeSubscription = false,
  planSelectedHandler
}) => {
  const [selected, setSelected] = useState("");
  return (
    <div className={`selectWrapper ${wrapperWidthToFitContent}`}>
      <label htmlFor="standard-select" className="mySelectLabel">
        {label}
      </label>
      <div className={isError ? "select select-error" : "select"}>
        <Field
          as="select"
          name={name}
          id="standard-select"
          className={classesName}
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
            planSelectedHandler(e.target.value);
          }}
        >
          <option value="">{`select`}</option>

          {list?.map((item, i) => {
            return (
              <option
                value={i}
                key={isApiData ? item._id : i}
                required={isRequired}
              >
                {item.product.name}
              </option>
            );
          })}
        </Field>
        <span className="focus"></span>
      </div>
    </div>
  );
};

export default PlanRowSelect;
