import axios from "axios";
import { useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
//console.log("token from api client", token);

const windowLocation = window.location;
console.log("our location is", windowLocation);

if (windowLocation.hostname === "medgrizz.com") {
  windowLocation.hostname = "www.medgrizz.com";
}

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_baseURL}/medgrizz/api/v1/`,
  //baseURL: `https://${windowLocation.hostname}/medgrizz/api/v1/`,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
    //"Access-Control-Allow-Origin": true,
    "Access-Control-Allow-Origin": "*",
  },
});
apiClient.interceptors.request.use(
  (config) => {
    //console.log("Our config apis configration request interceptor", config);
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // console.log("request config", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
apiClient.interceptors.response.use(
  (response) => {
    // console.log("response in api client", response);
    if (response.config.parse) {
      //console.log("response in api client", response);
      //perform the manipulation here and change the response object
    }
    return response;
  },
  (error) => {
    return Promise.reject(error.message);
  }
);

export default apiClient;
