import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../assets/scss/Home.scss";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import TopMenu from "../Components/TopMenu";

import PricingPlanCard from "../Components/PricingPlanCard";
import Footer from "../Components/Footer";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../Components/Loader";
import usePostMutation from "../Utils/usePostMutation";

const PricingAndPlans = () => {
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  let userAlreadySubscribedPlans = [];
  // if (userStored) {
  //   userStored?.subscriptions?.map((sub) => {
  //     return userAlreadySubscribedPlans.push(sub.plan.id);
  //   });
  // }
  const getAllExams = async () => {
    const { data } = await apiClient.get("/exams");
    return data;
  };
  const allExams = useQuery(["allExamsPricing"], getAllExams, {
    retry: 2,
    onSuccess: (res) => {
      console.log("response of exam on success in home page", res);
    },
    onError: (res) => {
      console.log("response of exam on error", res);
    }
  });
  const getAllUserSubscriptions = async () => {
    const { data } = await apiClient.get("/stripe/user-subscription");
    return data;
  };
  const allUserSubscriptions = useQuery(
    ["userSubscriptions"],
    getAllUserSubscriptions,
    {
      retry: 1,
      onSuccess: (res) => {
        console.log("response of exam on success in home page", res);
      },
      onError: (res) => {
        console.log("response of exam on error", res);
      }
    }
  );
  if (allUserSubscriptions.isSuccess) {
    allUserSubscriptions.data.data?.subscriptions.map((sub) => {
      return userAlreadySubscribedPlans.push(sub.plan.id);
    });
  }

  console.log("Stripe Plans", allExams);
  if (allExams.isLoading) {
    return <Loader />;
  }
  if (allExams.isError) {
    return (
      <div>
        <TopMenu />

        <div className="pricingPlans__wrapper">
          <h1>Plans not found try again later</h1>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
  if (allExams.isSuccess) {
    console.log("All plans of stripe", allExams);
    return (
      <div>
        <TopMenu />

        <div className="pricingPlans__wrapper">
          <h1>Pricing and Plans</h1>
          {allExams.isSuccess
            ? allExams.data.data.exams?.map((exam, index) => {
                return (
                  <>
                    <div className="pricing_plans" key={exam._id}>
                      {exam?.plan_ids?.length !== 0 ? (
                        <h2 className="plan_heading">{exam.name}</h2>
                      ) : (
                        ""
                      )}

                      <div className="plans" key={index}>
                        <Swiper
                          breakpoints={{
                            // when window width is <= 320px
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 10
                            },
                            // when window width is <= 480px
                            480: {
                              slidesPerView: 2,
                              spaceBetween: 10
                            },

                            // when window width is <= 640px
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 20
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 20
                            },
                            920: {
                              slidesPerView: 3,
                              spaceBetween: 20
                            },
                            1200: {
                              slidesPerView: 4,
                              spaceBetween: 20
                            }
                          }}
                          slidesPerView={4}
                          spaceBetween={10}
                          autoplay={{
                            delay: 1000,
                            disableOnInteraction: false
                          }}
                          pagination={{
                            clickable: true
                          }}
                          modules={[Autoplay, Pagination, Navigation]}
                          className="mySwiper"
                        >
                          {index % 2 === 0
                            ? exam.plan_ids?.map((plan, i) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <PricingPlanCard
                                      key={i}
                                      examName={exam.name}
                                      examId={exam._id}
                                      planName={plan.name}
                                      planId={plan.id}
                                      stripePriceId={plan.id}
                                      isLoggedIn={userStored}
                                      planPrice={
                                        userAlreadySubscribedPlans &&
                                        userAlreadySubscribedPlans.includes(
                                          plan.id
                                        )
                                          ? "Access Plan"
                                          : plan.unit_amount / 100
                                      }
                                      description={plan.description}
                                    />
                                  </SwiperSlide>
                                );
                              })
                            : exam?.plan_ids?.map((plan, i) => {
                                return (
                                  <SwiperSlide key={index}>
                                    <PricingPlanCard
                                      bgColorclass="grey-background"
                                      examName={exam.name}
                                      examId={exam._id}
                                      key={i}
                                      planName={plan.name}
                                      stripePriceId={plan.id}
                                      planId={plan.id}
                                      duration={plan.duration}
                                      isLoggedIn={userStored}
                                      plan_length={plan.plan_length}
                                      planPrice={
                                        userAlreadySubscribedPlans &&
                                        userAlreadySubscribedPlans.includes(
                                          plan.id
                                        )
                                          ? "Access Plan"
                                          : plan.unit_amount / 100
                                      }
                                      description={plan.description}
                                    />
                                  </SwiperSlide>
                                );
                              })}
                        </Swiper>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
};

export default PricingAndPlans;
