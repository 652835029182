// icon:flag-variant-outline | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconFlagVariantOutline(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="2.5em"
      width="2.5em"
      {...props}
    >
      <path d="M6 3a1 1 0 011 1v.88C8.06 4.44 9.5 4 11 4c3 0 3 2 5 2 3 0 4-2 4-2v8s-1 2-4 2-3-2-5-2c-3 0-4 2-4 2v7H5V4a1 1 0 011-1m1 4.25v4.25S9 10 11 10s3 2 5 2 2-1 2-1V7.5S17 8 16 8c-2 0-3-2-5-2S7 7.25 7 7.25z" />
    </svg>
  );
}

export default IconFlagVariantOutline;
