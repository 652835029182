import Button from "../Components/Button";
import "../assets/scss/Login.scss";
import "../assets/scss/utils.scss";
import IconRowInput from "../Components/IconRowInput";
import UserSvg from "../Components/UserSvg";
import { useNavigate } from "react-router-dom";
import MedgrizzFinalLogo from "../assets/images/medgrizz_final.jpeg";
import usePostMutation from "../Utils/usePostMutation";
import { Formik, Form, useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";

const ResetPassword = () => {
  const MyMutation = usePostMutation();
  const notify = (message) => toast(message);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values, props) => {
      console.log(values);
      console.log("My mutation", MyMutation);
      try {
        MyMutation.mutate(
          {
            url: "users/forgotPassword",
            values: values,
          },
          {
            onSuccess: (res) => {
              console.log("reset password response", res);
              //console.log("props", props);
              toast.success(res.data.message);
              props.setSubmitting(false);
              setTimeout(() => {
                navigate("/");
              }, 2000);
            },
            onError: (error) => {
              // alert("An error occured during submitting system");
              console.log("our response", error.response);
              toast.error("Invalid email or Password");
              props.setSubmitting(false);
              //toast.error(response.response.data.message);
            },
          }
        );
      } catch (error) {
        console.error(error.response);
      }
    },
  });
  return (
    <>
      <Toaster />

      <div className="login-form-design">
        <div className="login-form-design__form">
          <form onSubmit={formik.handleSubmit}>
            <div className="logo">
              <img src={MedgrizzFinalLogo} alt="this is logo" />
            </div>
            <h1 className="colors primary_heading mt-20">Reset Password</h1>

            <IconRowInput
              id="email"
              name="email"
              icon={<UserSvg className="icon" />}
              placeholder="Email"
              type="email"
              value={formik.values.email}
              handleChange={formik.handleChange}
            />

            <Button
              className="w-100"
              type="submit"
              text={formik.isSubmitting ? "Please wait ..." : "Send"}
              isDisable={formik.isSubmitting}
              handleSubmit={formik.handleSubmit}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
