import Sidebar from "../Components/Admin/Sidebar/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import AdminHeader from "../Components/Admin/AdminHeader";
import NormalValue1 from "../assets/images/Normal_value_1.jpeg";
import NormalValue2 from "../assets/images/Normal_value_2.jpeg";
import NormalValue3 from "../assets/images/Normal_Value_3.jpeg";
import NormalValue4 from "../assets/images/Normal_Value_4.jpeg";
import "../assets/scss/Wrapper.scss";
import FormRowInput from "../Components/FormRowInput";
import usePostMutation from "../Utils/usePostMutation";
import { useFormik, Formik, FieldArray, Form, Field } from "formik";
import qs from "qs";
import Button from "../Components/Button";
import { SlBookOpen } from "react-icons/sl";
import { BiBomb } from "react-icons/bi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { BsFlag } from "react-icons/bs";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { GiGolfFlag } from "react-icons/gi";
import { TbFlag3Filled } from "react-icons/tb";
import MyTimer from "../Components/MyTimer";
import RowInputRadio from "../Components/RowInputRadio";
import { useEffect, useState } from "react";
import FormRowSelect from "../Components/FormRowSelect";
import FormRowTextarea from "../Components/FormRowTextarea";
import { BsBoxArrowInRight, BsSkipEndBtn } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import apiClient from "../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../Components/Loader";
import QuestionChoices from "../Components/QuestionChoices";
import DeletionModal from "../Components/DeletionModal";
import CountDownTimer from "../Components/CountDownTimer";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaFlag } from "react-icons/fa";
import { FiFlag } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useParams } from "react-router-dom";
const ReviewResults = () => {
  const { mutate } = usePostMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state;
  const { id } = useParams();
  console.log("Id of our review", id);

  const getReviewCenterDetailApi = async () => {
    const { data } = await apiClient.get(`/review/single/${id}`);

    return data;
  };

  const [exam, setExam] = useState(JSON.parse(localStorage.getItem("exam")));
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizQuestions, setQuizQuestions] = useState();
  const [isQuestionFlagged, setIsQuestionFlagged] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isAnswerShowing, setIsAnswerShowing] = useState(false);
  const [setTotalScores] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [isArrayEnded, setIsArrayEnded] = useState(false);
  const [isExamSubmitted, setIsExamSubmitted] = useState(false);
  const [examDataAfterSubmitting, setExamDataAfterSubmitting] = useState({});
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(undefined);
  const [ourTime, setOurTime] = useState(undefined);
  const [isDeletedModalOpen, setISDeletedModalOpen] = useState(false);
  const [userStored, setStoredUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isNormalValueShown, setIsNormalValue] = useState(false);
  const [choiceSelectedValue, setChoiceSelectedValue] = useState("");
  const [examName, setExamName] = useState("");
  const [examType, setExamType] = useState("");
  const [reviewStateAfterSubmittingExam, setReviewStateAfterSubmittingExam] =
    useState(false);
  const reviewExamData = useQuery(
    ["reviewResultForExams"],
    getReviewCenterDetailApi,
    {
      refetchInterval: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      onSuccess: (res) => {
        console.log("useQuery on success", res);

        setQuizQuestions(res.data.review.examData);
      }
    }
  );
  useEffect(() => {
    reviewExamData.refetch();
  }, []);
  const reportList = [
    "Question Clarity",
    "Answer Inaccuracy",
    "Explanation Mistakes",
    "Bug"
  ];
  const radioBtnOnChange = (e) => {
    // setRadioValue(e.target.value);
    console.log("our event", e);
    setChoiceSelectedValue(e.target.value);
    console.log("selected value", e.target.value);
    quizQuestions[currentQuestion].userAnswer = e.target.value;
    setIsCorrectAnswer(quizQuestions[currentQuestion].correct_answer);
    if (e.target.value === quizQuestions[currentQuestion].correct_answer) {
      setTotalScores((prevCounter) => {
        // console.log("Previous value", prevCounter);
        prevCounter = prevCounter + 1;
        //console.log("Updated counter", prevCounter);
        return prevCounter;
      });
    }
  };

  if (reviewExamData.isLoading || quizQuestions === undefined) {
    return <Loader />;
  }
  if (reviewExamData.isError) {
    return <h1>No Data found</h1>;
  }
  if (reviewExamData.isSuccess) {
    //
    console.log("All questions in state", reviewExamData);
    // console.log("Exam name is", examName);
    return (
      <>
        <Toaster />
        <div className="dashboard-wrapper">
          <Sidebar examName={exam.name} />
          <div className="admin-nested">
            <AdminHeader />

            <div className="start__question">
              <div className="start__question_left">
                <div className="start__question_left-header">
                  <h1 className="start__question_left-header-left">
                    Question {currentQuestion + 1}
                  </h1>
                  <div className="start__question_left-header-right">
                    <div className="btns">
                      <div
                        className={
                          isAnswerShowing ? "single-btn back" : "single-btn"
                        }
                        onClick={() => {
                          setIsAnswerShowing(!isAnswerShowing);
                        }}
                      >
                        <SlBookOpen size="1.5rem" />
                        <h6 className="single-btn-heading">Answer</h6>
                      </div>

                      <div
                        className={
                          isNormalValueShown ? "single-btn back" : "single-btn"
                        }
                        onClick={() => {
                          setIsNormalValue(!isNormalValueShown);
                        }}
                      >
                        <HiOutlineLightBulb size="1.5rem" />
                        <h6 className="single-btn-heading">Normal Value</h6>
                      </div>
                      <div
                        className={
                          isReportOpen ? "single-btn back" : "single-btn"
                        }
                        onClick={() => setIsReportOpen(!isReportOpen)}
                      >
                        <BsFlag size="1.5rem" />
                        <h6 className="single-btn-heading">Report</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="start__question_left-questionWrapper">
                  {isNormalValueShown ? (
                    <p className="question-text">
                      <img src={NormalValue1} alt="" />
                      <img src={NormalValue2} alt="" />
                      <img src={NormalValue3} alt="" />
                      <img src={NormalValue4} alt="" />
                    </p>
                  ) : isAnswerShowing ? (
                    <p className="question-text">
                      {
                        reviewExamData.data.data.review.examData[
                          currentQuestion
                        ].correct_answer
                      }
                    </p>
                  ) : (
                    <>
                      <p className="question-text">
                        {
                          reviewExamData.data.data.review.examData[
                            currentQuestion
                          ].title
                        }
                      </p>

                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className="radio-buttons-container"
                      >
                        {quizQuestions[currentQuestion].choices.map(
                          (choice, index) => {
                            return (
                              <div key={index}>
                                <RowInputRadio
                                  name="radio-group"
                                  id={choice}
                                  key={index}
                                  label={choice}
                                  //   handleChange={radioBtnOnChange}
                                  checkboxValue={choice}
                                  isCorrectAnswer={
                                    quizQuestions[currentQuestion]
                                      .correct_answer
                                  }
                                  radioValue={
                                    quizQuestions[currentQuestion]
                                      .userAnswer === ""
                                      ? radioValue
                                      : quizQuestions[currentQuestion]
                                          .userAnswer
                                  }
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}
                  <div className="question-pagination-wrapper">
                    <div
                      className="pagination-item"
                      onClick={() => {
                        if (currentQuestion > 0) {
                          setCurrentQuestion(currentQuestion - 1);
                        } else {
                        }
                      }}
                    >
                      <MdKeyboardArrowLeft size="3rem" />
                      Previous Question
                    </div>
                    <div
                      className="pagination-item"
                      onClick={() => {
                        setIsQuestionFlagged(!isQuestionFlagged);
                        quizQuestions[currentQuestion].isFlagged =
                          !quizQuestions[currentQuestion].isFlagged;
                      }}
                    >
                      {reviewExamData.data.data.review.examData[currentQuestion]
                        .isFlagged ? (
                        <FaFlag size="2.5rem" className="redFlagFilled" />
                      ) : (
                        <FiFlag size="2.5rem" />
                      )}
                      Play half Question
                    </div>

                    <div
                      className="pagination-item"
                      onClick={() => {
                        if (currentQuestion < quizQuestions.length - 1) {
                          setCurrentQuestion(currentQuestion + 1);
                        } else {
                          setIsArrayEnded(true);
                        }
                      }}
                    >
                      <MdKeyboardArrowRight size="3rem" />
                      Next Question
                    </div>
                  </div>
                </div>

                {isReportOpen ? (
                  <div className="start__question_left-reportWrapper">
                    <div className="report-topic">
                      <Formik
                        initialValues={{
                          report_title: "",
                          report_body: "",
                          question_id: quizQuestions[currentQuestion]._id,
                          user_id: userStored._id
                        }}
                        onSubmit={(values, props) => {
                          // console.log("values", values);
                          mutate(
                            {
                              url: "/reports",
                              values: values
                            },
                            {
                              onSuccess: () => {
                                //alert("Form submitted successfully");
                                toast.success("Question reported successfully");
                                props.setSubmitting(false);
                              },
                              onError: (response) => {
                                // alert("An error occured while submitting exam");
                                toast.error(
                                  "An error occured while submitting exam"
                                );
                                props.setSubmitting(false);
                              }
                            }
                          );
                        }}
                      >
                        {(formik) => (
                          <>
                            <div className="selectReportTopic">
                              <FormRowSelect
                                name="report_title"
                                id="report_title"
                                value={formik.values.report_title}
                                onChange={formik.handleChange}
                                list={reportList}
                                label="Report Topic"
                                classesName="width-fit-content"
                                wrapperWidthToFitContent="selectWrapper-width-fit-content"
                              />
                            </div>

                            <FormRowTextarea
                              rows={10}
                              label="Report Explanation"
                              id="report_body"
                              name="report_body"
                              onChange={formik.handleChange}
                              value={formik.values.report_body}
                            />
                            <Button
                              text={
                                formik.isSubmitting
                                  ? "Loading ..."
                                  : "Report Question"
                              }
                              type="submit"
                              isDisable={formik.isSubmitting}
                              handleSubmit={formik.handleSubmit}
                            />
                          </>
                        )}
                      </Formik>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="start__question_right">
                <div className="start__question_right-header">
                  {
                    <div className="scores_section">
                      <div className="left">
                        <h4>Scores</h4>
                        <div className="bottom">
                          <h3>
                            {
                              reviewExamData.data.data.review
                                .total_correct_answers
                            }
                          </h3>
                          /
                          <h3>
                            {reviewExamData.data.data.review.total_questions}
                          </h3>
                        </div>
                      </div>
                      <div className="right">
                        <h4>Percentage</h4>
                        <h3>
                          {Math.round(
                            (reviewExamData.data.data.review
                              .total_correct_answers /
                              reviewExamData.data.data.review.total_questions) *
                              100
                          )}
                          %
                        </h3>
                      </div>
                    </div>
                  }
                </div>
                <div className="start__question_right-body">
                  <div className="question_nos_wrapper">
                    {quizQuestions.map((q, index) => {
                      return (
                        <div
                          className={
                            q.userAnswer !== "" && q.isFlagged !== false
                              ? "question_no question_no_answered question_no_flagged"
                              : q.userAnswer !== ""
                              ? "question_no question_no_answered"
                              : q.isFlagged !== false
                              ? "question_no question_no_flagged "
                              : "question_no"
                          }
                          key={index}
                          onClick={() => setCurrentQuestion(index)}
                        >
                          {index + 1}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="start__question_right-footer">
                  <div className="footer-items">
                    {isExamSubmitted ? (
                      <div className="footer-item">
                        <BsSkipEndBtn size="3rem" />
                        <h3>Finished</h3>
                      </div>
                    ) : (
                      <div
                        className="footer-item"
                        onClick={() => {
                          setISDeletedModalOpen(true);
                        }}
                      >
                        <BsBoxArrowInRight size="3rem" />
                        <h3>Quit</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ReviewResults;
