import React, { useState } from "react";
import "../assets/scss/RowInputRadio.scss";
const RowInputRadio = ({
  name = "radio-group",
  id = "myId",
  label = "Radio btn",
  list,
  handleChange,
  value,
  checkboxValue,
  radioValue,
  handleMouseClick,
  isCorrectAnswer
}) => {
  return (
    <div className="radio-button">
      <input
        name={name}
        id={id}
        // className="radio-button__input"
        type="radio"
        onChange={handleChange}
        value={checkboxValue}
        checked={radioValue === checkboxValue}
        //onMouseDown={handleMouseClick}
        //checked={checkboxValue}
        //defaultChecked={false}
      />
      <label htmlFor={id} className="radio-button__label">
        {/*<span className="radio-button__custom"></span> */}
        {label}
        <span>
          {isCorrectAnswer !== undefined && isCorrectAnswer === label
            ? "correct"
            : ""}
        </span>
      </label>
    </div>
  );
};

export default RowInputRadio;
