import { useState } from "react";
import FormHeader from "../../Components/FormHeader";
import FormRowInput from "../../Components/FormRowInput";
import Button from "../../Components/Button";
import "../../assets/scss/utils.scss";
import { useFormik, Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import usePostMutation from "../../Utils/usePostMutation";
import apiClient from "../../Utils/apiClient";
import { useQuery } from "react-query";
import Loader from "../../Components/Loader";
import toast, { Toaster } from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import PlanRowSelect from "../../Components/PlanRowSelect";

const EditExam = () => {
  const { id } = useParams();

  const [planCounter, setPlanCounter] = useState(0);
  const { mutate } = usePostMutation();
  console.log("exam_id", id);
  let examValues = {};
  const getExam = async () => {
    const { data } = await apiClient(`/exams/${id}`);
    return data;
  };

  const getExamData = useQuery(["exam"], getExam, {
    refetchOnWindowFocus: false
  });
  const getAllStripePlans = async () => {
    const { data } = await apiClient.get("/stripe/all_plans");
    return data;
  };
  const allPlans = useQuery(["exams"], getAllStripePlans, {
    retry: 1,
    onSuccess: (res) => {
      //console.log("response of exam on success in home page", res);
    },
    onError: (res) => {
      //console.log("response of exam on error", res);
    }
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { examValues },
    onSubmit: (values, props) => {
      console.log("values", values);
      mutate(
        {
          method: "patch",
          url: `/exams/${id}`,
          values
        },
        {
          onSuccess: (res) => {
            toast.success("Exam updated successfully");
            props.setSubmitting(false);
          },
          onError: (err) => {
            toast.error("Error while updating exam");
            console.log(err);
            props.setSubmitting(false);
          }
        }
      );
    }
  });
  const selectHandler = (formik, val) => {
    console.log("Value ", val);
    console.log("Selected Value", allPlans.data.data.subscriptions[val]);
    let ourSelectedPlan = allPlans.data.data.subscriptions[val];

    const value = formik.values.plan_ids.findIndex(
      (p) => p.id === ourSelectedPlan.id
    );
    if (value < 0) {
      const { id, unit_amount } = ourSelectedPlan;
      const { name, description } = ourSelectedPlan.product;
      console.log("Product ", ourSelectedPlan.product);
      let modifiedObj = { id, name, description, unit_amount };
      formik.values.plan_ids.push(modifiedObj);
      setPlanCounter((prev) => prev + 1);
    }
    console.log("If value exists in array or not", value);

    console.log("Plans", formik.values.plans);
  };
  const PlanDeleteHandler = (formik, id) => {
    console.log("formik", formik);
    const value = formik.values.plan_ids.findIndex((p) => p.id === id);
    if (value > -1) {
      //
      formik.values.plan_ids.splice(value, 1);
      setPlanCounter((prev) => prev - 1);
    }
  };

  if (getExamData.isFetching) {
    return <Loader />;
  }
  if (getExamData.isError) {
    return <h1>Error while loading data</h1>;
  }
  if (getExamData.isSuccess && allPlans.isSuccess) {
    console.log("examData", getExamData);
    examValues = { ...getExamData.data.data.exam };
    console.log("exam values", examValues);

    return (
      <>
        <div className="form-wrapper">
          <Formik
            enableReinitialize
            initialValues={examValues}
            onSubmit={(values, props) => {
              console.log("values", values);
              mutate(
                {
                  method: "patch",
                  url: `/exams/${id}`,
                  values
                },
                {
                  onSuccess: (res) => {
                    toast.success("Exam updated successfully");
                    props.setSubmitting(false);
                  },
                  onError: (err) => {
                    toast.error("Error while updating exam");
                    console.log(err);
                    props.setSubmitting(false);
                  }
                }
              );
            }}
          >
            {(formik) => (
              <Form className="dashboard-form" onSubmit={formik.handleSubmit}>
                <FormHeader text="Edit Exam" />
                <FormRowInput
                  label="Exam Name"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />

                <h5 className="mt-20">Pricing and Plans</h5>
                <div className="selection_area">
                  <PlanRowSelect
                    id="type"
                    label="Select Plan"
                    list={allPlans.data.data.subscriptions}
                    name="type"
                    isApiData="true"
                    value={formik.values.type}
                    planSelectedHandler={(e) => selectHandler(formik, e)}
                  />
                </div>
                <div className="adding_plans_wrapper">
                  {formik.values.plan_ids.map((p) => {
                    return (
                      <div className="plan_added" key={p.id}>
                        <h4>{p.name}</h4>
                        <div
                          className="delete-icon"
                          onClick={() => PlanDeleteHandler(formik, p.id)}
                        >
                          <AiFillDelete />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Button
                  text={formik.isSubmitting ? "Loading ..." : "Update Exam"}
                  type="submit"
                  isDisable={formik.isSubmitting}
                  handleSubmit={formik.handleSubmit}
                />
              </Form>
            )}
          </Formik>
        </div>
        <Toaster />
      </>
    );
  }
};

export default EditExam;
